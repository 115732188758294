import axios from 'axios';
import {
  EVENT_FETCHED,
  EVENT_CREATED,
  SET_EVENTS,
  SET_CALENDAR,
  CALENDAR_EVENT_FETCHED,
} from '../types';
import { eventToClass, eventsToClassArray } from '../../classes/events/Event.class';

export function eventFetched(event) {
  return {
    type: EVENT_FETCHED,
    event: eventToClass(event),
  };
}

export function eventCreated(event) {
  return {
    type: EVENT_CREATED,
    event: eventToClass(event),
  };
}

export function calendarEventFetched(event) {
  return {
    type: CALENDAR_EVENT_FETCHED,
    event,
  };
}

export function setEvents(events) {
  return {
    type: SET_EVENTS,
    events: eventsToClassArray(events),
  };
}

export function setCalendar(events) {
  return {
    type: SET_CALENDAR,
    events,
  };
}

export const fetchEvent = (id) => async (dispatch) => {
  const res = await axios.get('/api/competitions/' + id);
  dispatch(eventFetched(res.data));
};

export const fetchEvents = () => async (dispatch) => {
  console.log('fetchEvents');
  const res = await axios.get('/api/competitions/');
  dispatch(setEvents(res.data));
};

export const createEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/competitions/', values);
  dispatch(eventCreated(res.data));
};

export const updateEvent = (values) => async (dispatch) => {
  const res = await axios.put('/api/competitions/' + values.id, values);
  dispatch(eventFetched(res.data));
};

// Currently Unused
export const deleteEvent = (id) => async (dispatch) => {
  const res = await axios.delete('/api/competitions/delete' + id);
  dispatch(setEvents(res.data));
};

export const fetchCalendar = () => async (dispatch) => {
  const res = await axios.get('/api/calendar/');
  dispatch(setCalendar(res.data));
};

export const fetchCalendarEvent = (id) => async (dispatch) => {
  const res = await axios.get('/api/calendar/' + id);
  dispatch(calendarEventFetched(res.data));
};

export const addDocToEvent = (values) => async (dispatch) => {
  const res = await axios.post('/api/competitions/docs/upload', values);
  dispatch(eventFetched(res.data));
};
