import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  okCancel,
  renderFormTitle,
  maxLength,
} from '../../../core/FinalCoreForms';
import {
  createXcCourse,
  updateXcCourse,
} from '../../../../redux/actions/Events/auxiliary/xcCourses.actions';
import { useDispatch } from 'react-redux';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Select from '@mui/material/Select';
import { levelChip } from '../../../core/levelsTools';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const XCCoursesForm = ({ xcCourse, onOkClick, eventData }) => {
  const dispatch = useDispatch();
  // const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedLevel, setSelectedLevel] = React.useState(
    xcCourse ? xcCourse.level : 0
  );

  function submitXcCourse(xcCourse) {
    if (xcCourse && xcCourse.id) {
      return dispatch(updateXcCourse(xcCourse)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createXcCourse(xcCourse)).then(() => {
        //   navigateBack();
      });
    }
  }

  const onSubmit = async (values) => {
    await sleep(300);
    if (xcCourse && submitXcCourse) {
      Object.assign(xcCourse, values);
      // xcCourse.eventId = eventId;
      xcCourse.level = selectedLevel;
      submitXcCourse(xcCourse);
    } else {
      let newData = {};
      Object.assign(newData, values);
      newData.eventId = eventData.id;
      newData.level = selectedLevel;
      submitXcCourse(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  function getLevelSelector() {
    return (
      <Box width="100%">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedLevel}
            label="Level"
            onChange={handleLevelChange}
          >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={2}>{levelChip(2)}</MenuItem>
            <MenuItem value={3}>{levelChip(3)}</MenuItem>
            <MenuItem value={4}>{levelChip(4)}</MenuItem>
            <MenuItem value={5}>{levelChip(5)}</MenuItem>
            <MenuItem value={6}>{levelChip(6)}</MenuItem>
            <MenuItem value={7}>{levelChip(7)}</MenuItem>
            <MenuItem value={8}>{levelChip(8)}</MenuItem>
            <MenuItem value={9}>{levelChip(9)}</MenuItem>
            {/* <MenuItem value={9}>{levelChip(9)}</MenuItem> //Multiple */}
          </Select>
        </FormControl>
      </Box>
    );
  }

  const initialValues = {
    name: xcCourse ? xcCourse.name : '',
    level: xcCourse ? xcCourse.level : 0,
    faultTime: xcCourse ? xcCourse.faultTime : '',
    faultSpeed: xcCourse ? xcCourse.faultSpeed : 0,
    optimumTime: xcCourse ? xcCourse.optimumTime : '',
    lengthMeters: xcCourse ? xcCourse.lengthMeters : 0,
    optimumSpeed: xcCourse ? xcCourse.optimumSpeed : 0,
    mapURL: xcCourse ? xcCourse.mapURL : '',
    dataStatus: xcCourse ? xcCourse.dataStatus : 0,
  };

  return (
    <div>
      {renderFormTitle(xcCourse, 'XcCourses')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Name', maxLength(100))}
            {getLevelSelector()}
            {finalTextField('faultTime', 'FaultTime')}
            {finalTextField('faultSpeed', 'FaultSpeed')}
            {finalTextField('lengthMeters', 'LengthMeters')}
            {finalTextField('optimumSpeed', 'OptimumSpeed')}
            {finalTextField('optimumTime', 'OptimumTime')}
            {finalTextField('mapURL', 'MapURL')}
            {okCancel(onOkClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default XCCoursesForm;
