import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';
import { datesToText } from '../../core/DateTools';

import {
  fetchUserEntries,
  //   updatePropertiesEventDivision,
} from '../../../redux/actions/Events/entry.actions';
import { useDispatch, useSelector } from 'react-redux';
// import EventEntryForm from '../forms/EventEntryForm';

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  // Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  deepOrange,
  // red,
  green,
  deepPurple,
  blue,
  indigo,
} from '@mui/material/colors';

const Card = styled(MuiCard)(spacing);

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const IndigoAvatar = styled(Avatar)`
  background-color: ${indigo[500]};
`;

const GreenAvatar = styled(Avatar)`
  background-color: ${green[500]};
`;

// const RedAvatar = styled(Avatar)`
//   background-color: ${red[500]};
// `;

const BlueAvatar = styled(Avatar)`
  background-color: ${blue[500]};
`;

const OrangeAvatar = styled(Avatar)`
  background-color: ${deepOrange[500]};
`;

const PurpleAvatar = styled(Avatar)`
  background-color: ${deepPurple[500]};
`;

// const Chip = styled(MuiChip)`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : 'primary'].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function EventEntries() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserEntries());
  }, [dispatch]);

  const userEntries = useSelector((state) => state.userEntries);
  // const selectedEntry = useSelector((state) =>
  //   state.userEntries.find((x) => x.id === selected)
  // );
  // const user = useSelector((state) => state.auth);

  function toolTip(tipText, child) {
    return (
      <Tooltip
        title={
          <span style={{ whiteSpace: 'pre-line', opacity: 1 }}>{tipText}</span>
        }
        placement="left"
        enterDelay={500}
        leaveDelay={200}
        arrow
      >
        {child}
      </Tooltip>
    );
  }

  function eventTypeToChip(type) {
    if (type === 0) {
      return toolTip('Unassigned', <IndigoAvatar>Unk</IndigoAvatar>);
    } else if (type === 1) {
      return toolTip('Recognized Horse Trials', <GreenAvatar>HT</GreenAvatar>);
    } else if (type === 2) {
      return toolTip('Combined Test', <OrangeAvatar>CT</OrangeAvatar>);
    } else if (type === 3) {
      return toolTip('Schooling Horse Trials', <BlueAvatar>Sch</BlueAvatar>);
    } else if (type === 4) {
      return toolTip('Dressage', <PurpleAvatar>Dr</PurpleAvatar>);
    } else if (type === 5) {
      return toolTip('Clinic', <BlueAvatar>Cl</BlueAvatar>);
    } else {
      return '';
    }
  }

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleClick = (event, id) => {
    // if (!addingTest) {
    setSelected(id);
    // }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  // const handleFormOkClick = (event) => {
  //   setCreating(false);
  //   setEditing(false);
  //   setSelected(-1);
  // };

  // const handleFormCancelClick = (event) => {
  //   setCreating(false);
  //   setEditing(false);
  // };

  const isSelected = (id) => selected === id;

  function mapBody(userEntries) {
    if (userEntries && userEntries.length > 0) {
      const tableBody = userEntries.map((entry) => {
        const isItemSelected = isSelected(entry.id);
        return (
          <Fragment key={entry.id}>
            <TableRow
              key={entry.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, entry.id)}
              selected={isItemSelected}
            >
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open && isItemSelected ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {entry.id}
              </TableCell>
              <TableCell>{entry.riderName}</TableCell>
              <TableCell>{entry.eventDivisionName}</TableCell>
              <TableCell>{eventTypeToChip(entry.eventType)}</TableCell>
              <TableCell>
                {datesToText(
                  entry.eventStartDate,
                  entry.eventEndDate,
                  entry.eventOneDay
                )}
              </TableCell>
              <TableCell>
                <Stack>
                  <Button
                    aria-label="addTest"
                    onClick={(event) => handleEditClick(event, entry.id)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse
                  in={open && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Event #</TableCell>
                          <TableCell>Event Name</TableCell>
                          <TableCell>Date(s)</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Zip</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={entry.id}>
                          <TableCell>{entry.eventId}</TableCell>
                          <TableCell>{entry.eventName}</TableCell>
                          <TableCell>{entry.city}</TableCell>
                          <TableCell>{entry.state}</TableCell>
                          <TableCell>{entry.zip}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showEventEntryForm() {
    // if (selected && selected > 0) {
    //   if (selectedPerson && editing) {
    //     return (
    //       <EventEntryForm
    //         user={user}
    //         onOkClick={handleFormOkClick}
    //         onCancelClick={handleFormCancelClick}
    //         entry={selectedEntry}
    //       />
    //     );
    //   }
    // } else if (creating) {
    //   return (
    //     <EventEntryForm
    //       user={user}
    //       onOkClick={handleFormOkClick}
    //       onCancelClick={handleFormCancelClick}
    //     />
    //   );
    // }
  }

  return (
    <>
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title="People"
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Entry #</TableCell>
                  <TableCell>Rider Name</TableCell>
                  <TableCell>Division</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Date(s)</TableCell>
                  <TableCell>Controls</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(userEntries)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showEventEntryForm()}
      </Card>
    </>
  );
}

export default EventEntries;
