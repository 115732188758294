/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { addDocToEvent } from '../../../../redux/actions/Events/event.actions';
import { addDocToCompetition } from '../../../../redux/slices/competitions/competitions.slice'; // ✅ Updated import
import { Form, Card, Button } from 'react-bootstrap';
import { textField, intSelector, photoUploader } from '../../../core/CoreForms';
import EventDocumentGroup from './EventDocumentGroup';
import { PlusCircle } from 'react-bootstrap-icons';

const DOCUMENT_CATEGORY_EVENTS = 2;

class EventDocumentManagementPanel extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleSubmitDocument = this.handleSubmitDocument.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.state = {
      id: this.props.eventSelected ? this.props.eventSelected.id : null,
      errors: '',
      docDescription: '',
      docTitle: '',
      docType: 0,
      loading: false,
      adding: false,
      file: null,
      fileURL: null,
    };
  }

  handleFileChange = (e) => {
    // console.log(e.target.files[0]);
    // console.log(URL.createObjectURL(e.target.files[0]));

    this.setState({
      file: e.target.files[0],
      fileURL: URL.createObjectURL(e.target.files[0]),
    });
  };

  componentDidUpdate = (nextProps) => {
    if (nextProps.eventSelected) {
      // this.setState({
      //   id: nextProps.eventSelected ? nextProps.eventSelected.id : null,
      // });
    }
  };

  handleClick = (e) => {
    this.setState({
      adding: !this.state.adding,
    });
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitDocument = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { id, docDescription, docTitle, docType } = this.state;
    if (!id) {
      if (!this.props.eventSelected) {
        return;
      }
      // id = this.props.eventSelected.id;
    }
    var title = docTitle;
    if (title === '') title = 'Info';
    const formData = new FormData();
    formData.append('description', docDescription);
    formData.append('title', title);
    formData.append('category', DOCUMENT_CATEGORY_EVENTS);
    formData.append('userId', this.props.auth.id);
    formData.append('competitionId', this.props.eventSelected.id);
    formData.append('type', docType);

    for (
      let i = 0, numFiles = e.target.photos.files.length;
      i < numFiles;
      i++
    ) {
      let file = e.target.photos.files[i];
      formData.append('docs', file, file.title);
    }

    this.setState({ docDescription: '', docTitle: '', loading: true });
    e.target.photos.value = null; // blanks the file

    // ✅ Dispatch Redux action instead of direct API call
    this.props.addDocToCompetition(formData);
  };

  /* eslint-disable max-len */
  /*
   *  1 - Event Documents
   *     TYPES:
   *         0 - General
   *         1 - Entry Form
   *         2 - Ride Times
   *         3 - Entry Status
   *         4 - Stabling
   *         5 - Results
   *         6 - Program
   * */
  docTypes = [
    'General',
    'Entry Form',
    'Ride Times',
    'Entry Status',
    'Stabling',
    'Results',
    'Program',
    'Info',
  ];

  getForm() {
    if (this.state.adding) {
      return (
        <div>
          <h3>Add Document</h3>
          <Form onSubmit={this.handleSubmitDocument}>
            {textField('docTitle', 'Title', this)}
            {textField('docDescription', 'Description', this)}
            {intSelector('docType', 'Doc Type', this, this.docTypes)}
            {photoUploader()}
            <Button type="submit" variant="dark">
              Submit
            </Button>
          </Form>
        </div>
      );
    }
  }

  render() {
    var docPanel;

    if (this.props.eventSelected) {
      docPanel = (
        <EventDocumentGroup eventSelected={this.props.eventSelected} />
      );
    } else {
      docPanel = <label>DocPanelLabel</label>;
    }

    return (
      <Card bg="info">
        <Card.Header>
          <h2>
            Documents&nbsp;&nbsp;
            <Button variant="info" onClick={this.handleClick}>
              <PlusCircle />
            </Button>
          </h2>
        </Card.Header>
        <Card.Body>{this.getForm()}</Card.Body>
        <Card.Footer>{docPanel}</Card.Footer>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { addDocToCompetition })(
  EventDocumentManagementPanel
);
