import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  addFavoriteToUser,
  saveFavoriteNotes,
  removeFavoriteFromUser,
} from '../../../../redux/actions/users/user.actions.js';
import { createOculusEvent } from '../../../../redux/actions/oculus/oculusEvent.actions.js';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import {
  Box,
  Button,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia,
  CardActionArea,
  Chip as MuiChip,
  InputAdornment,
  Typography as MuiTypography,
  Stack,
  TextField,
} from '@mui/material';
import { spacing } from '@mui/system';

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== 'dark' &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  // margin-bottom: ${(props) => props.theme.spacing(1)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

// const Chip = styled(MuiChip)`
//   top: 16px;
//   right: 16px;
//   height: 20px;
//   padding: 4px 0;
//   font-size: 85%;

//   span {
//     padding-left: ${(props) => props.theme.spacing(2)};
//     padding-right: ${(props) => props.theme.spacing(2)};
//   }
// `;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

function pedigreeEmptyOrPartial(horse) {
  return JSON.stringify(horse.pedigree).toLowerCase().includes('unknown');
}

function getPedigreeBadge(horse) {
  if (horse && horse.pedigree && !pedigreeEmptyOrPartial(horse)) {
    return <Chip label="Pedigree" color="info" />;
  }
  return '';
}

function getPhotoCount(horse) {
  if (horse && horse.photos) {
    return horse.photos.length;
  } else {
    return 0;
  }
}

function getPhotoLabel(horse) {
  return getPhotoCount(horse) + ' Photos';
}

function getVideoCount(horse) {
  if (horse && horse.videos) {
    return horse.videos.length;
  } else {
    return 0;
  }
}

function getVideoLabel(horse) {
  return getVideoCount(horse) + ' Videos';
}

function getS3VideoCount(horse) {
  return horse?.s3videos?.length || 0;
}

function getS3VideoLabel(horse) {
  return getS3VideoCount(horse) + ' S3 Videos';
}

function getPhotosVideosBadges(horse) {
  if (horse) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        {getPhotoCount(horse) ? (
          <Chip label={getPhotoLabel(horse)} color="error" />
        ) : (
          ''
        )}
        {getVideoCount(horse) ? (
          <Chip label={getVideoLabel(horse)} color="warning" />
        ) : (
          ''
        )}
        {getS3VideoCount(horse) ? (
          <Chip label={getS3VideoLabel(horse)} color="success" /> // ✅ New badge for S3 videos
        ) : (
          ''
        )}
        {getPedigreeBadge(horse)}
      </Stack>
    );
  }
  return '';
}

function getIllustration(horse) {
  if (horse) {
    return horse.getMainPhotoURLThumb();
  } else return null;
}

function formatAsDollars(dollarValue) {
  if (dollarValue) {
    let formatting_options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    };
    let dollarString = new Intl.NumberFormat('en-US', formatting_options);
    return dollarString.format(dollarValue);
  }
}

function getPrice(horse) {
  if (!horse.sold) {
    return <div>{formatAsDollars(horse.price)}</div>;
  } else {
    return <div>SOLD</div>;
  }
}

const HorseStatsDisplay = ({ horse, auth }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notes, setNotes] = React.useState('');

  function favorited() {
    return horse && horse.favoriteInfo;
  }
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    setNotes(horse && horse.favoriteInfo ? horse.favoriteInfo.notes : '');
  }, [horse]);

  function getFavoriteIcon() {
    if (favorited()) {
      return (
        <Stack>
          <FavoriteIcon onClick={handleFavoriteClick} />
        </Stack>
      );
    }
    return <FavoriteBorderOutlinedIcon onClick={handleFavoriteClick} />;
  }

  function getNotesTextBox() {
    if (favorited()) {
      return (
        <Stack direction="row">
          <TextField
            id="outlined-basic"
            variant="filled"
            multiline
            value={notes}
            fullWidth
            onChange={(event) => {
              setNotes(event.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">Notes</InputAdornment>
              ),
            }}
          />
          <Button variant="outlined" onClick={handleSaveNotesClick}>
            Save
          </Button>
        </Stack>
      );
    }
  }

  function oculusData() {
    return {
      dataType: 'horse',
      horseId: horse.id,
      name: horse.name,
    };
  }

  function oculusEvent(sender, type) {
    return {
      userId: auth ? auth.id : null,
      sender,
      session: window.sessionStorage.getItem('SID'),
      type,
      data: oculusData(),
      objectId: horse ? horse.id : null,
    };
  }

  // TYPE : 0: None, 1: Control Click, 2: Button Click, 3: Favorite Click, 4: AdClick

  function handleFavoriteClick(e) {
    e.stopPropagation();
    if (!auth) {
      dispatch(createOculusEvent(oculusEvent('HorseStatsDisplay.Favorite', 3)));
      navigate('/login/sales/' + horse.id);
    } else {
      if (favorited()) {
        dispatch(removeFavoriteFromUser({ horseId: horse.id }));
        dispatch(
          createOculusEvent(oculusEvent('HorseStatsDisplay.FavoriteAdd', 3))
        );
      } else {
        dispatch(addFavoriteToUser({ horseId: horse.id }));
        dispatch(
          createOculusEvent(oculusEvent('HorseStatsDisplay.FavoriteRemove', 3))
        );
      }
    }
  }

  function handleClick(e) {
    dispatch(createOculusEvent(oculusEvent('HorseStatsDisplay', 1)));
    navigate('/sales/horse/' + horse.id);
  }

  function handleSaveNotesClick() {
    var updateObject = { horseId: horse.id, notes };
    dispatch(saveFavoriteNotes(updateObject));
  }

  return (
    <>
      {horse ? (
        <Stack maxWidth="365px">
          <Card
            variant="outlined"
            orientation="horizontal"
            sx={{
              width: '100%',
              maxWidth: '365px',
              '&:hover': {
                boxShadow: 'md',
                borderColor: 'neutral.outlinedHoverBorder',
              },
              display: 'flex',
            }}
            illustration={getIllustration(horse)}
            onClick={handleClick}
          >
            <CardActionArea
              sx={{
                // width: 325,
                // width: '100%',
                '&:hover': {
                  boxShadow: 'md',
                  borderColor: 'neutral.outlinedHoverBorder',
                },
                display: 'flex',
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: '120px', height: 'fit-content', borderRadius: 2 }}
                image={getIllustration(horse)}
                alt="Live from space album cover"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h6" mb={4}>
                    {horse.name}
                  </Typography>
                  <Typography variant="h3" mb={3}>
                    <Box fontWeight="fontWeightRegular">{getPrice(horse)}</Box>
                  </Typography>
                  {getFavoriteIcon()}
                </CardContent>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'left', pl: 1, pb: 1 }}>
                {getPhotosVideosBadges(horse)}
              </Box>
            </CardActionArea>
          </Card>
          {getNotesTextBox()}
        </Stack>
      ) : (
        ''
      )}
    </>
  );
};

export default HorseStatsDisplay;
