import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import NavbarUserDropdown from "./NavbarUserDropdown";
import HorseStats from "../../../customers/horses/core/HorseStats";
import ThemeSelector from "../../../../contexts/components/ThemeSelector"; // ✅ Import ThemeSelector
import { Menu as MenuIcon, Palette as PaletteIcon } from "@mui/icons-material"; // ✅ Import Theme Icon

import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Menu,
} from "@mui/material";

/**
 * xs = iPhone
 * sm = iPad Mini
 * lg = iPad
 * xl = Computer
 */

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Navbar = ({ onDrawerToggle }) => {
  const auth = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function displayAd() {
    return <HorseStats auth={auth} />;
  }

  function checkDisplayAd() {
    if (!auth) {
      return displayAd();
    } else {
      if (auth && (auth.isAdmin() || auth.checkHasPermission("ADD_HORSES"))) {
        return "";
      } else {
        return displayAd();
      }
    }
  }

  // Handle Theme Menu for Small Screens
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs // fills space
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
              justifyContent="center"
              alignItems="center"
            >
              {checkDisplayAd()}
            </Grid>
            <Grid item xs sx={{ display: { xs: "block", sm: "none" } }} />

            {/* ✅ Theme Selector - Show Full Dropdown on Larger Screens */}
            <Grid item sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <ThemeSelector />
            </Grid>

            {/* ✅ Small Screen Theme Selector - Uses Icon */}
            <Grid item sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Change Theme"
                onClick={handleMenuOpen}
                size="large"
              >
                <PaletteIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <ThemeSelector />
              </Menu>
            </Grid>

            {/* User Dropdown */}
            <Grid item justifyContent="flex-end">
              <NavbarUserDropdown auth={auth} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
