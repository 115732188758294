export const EVENT_LEVELS = [
  { id: 0, name: 'N/A', type: 0 },
  { id: 1, name: 'Advanced', type: 1 },
  { id: 2, name: 'Intermediate', type: 1 },
  { id: 3, name: 'Preliminary', type: 1 },
  { id: 4, name: 'Training', type: 1 },
  { id: 5, name: 'Modified', type: 1 },
  { id: 6, name: 'Novice', type: 1 },
  { id: 7, name: 'Beginner Novice', type: 1 },
  { id: 8, name: 'Starter', type: 1 },
  { id: 9, name: 'Prestarter', type: 1 },
  { id: 10, name: 'None', type: 0 },
];
