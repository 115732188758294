import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../../redux/slices/posts/posts.slice';
import MediaItemGroup from '../customers/media/core/MediaItemGroup'; // Same UI component

export default function MediaFeed() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPosts());
  }, [dispatch]);

  const auth = useSelector((state) => state.auth);
  const postsFeed = useSelector((state) => state.posts.feed);

  return <MediaItemGroup mediaFeed={postsFeed} auth={auth} />;
}
