import React from 'react';
import { Button, Grid, Typography, Box } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material'; // ✅ MUI Icon
import MediaFeed from './media/MediaFeed';
import AddMedia from './media/AddMedia';
import HelmetTracker from './core/HelmetTracker';
import { useTheme } from '@mui/material/styles'; // ✅ Use MUI Theme
import { Link } from 'react-router-dom'; // ✅ Import at top if not already

export default function Landing() {
  const theme = useTheme(); // ✅ Get MUI Theme Colors

  function getSaleHorseButton() {
    return (
      <Button
        variant="contained"
        color="primary"
        startIcon={<ShoppingCart />} // ✅ MUI Icon
        component={Link}            // ✅ Convert to <Link>
        to="/sales"                // ✅ Navigate to correct route
        sx={{ mt: 2 }}
      >
        Shop All Sales Horses
      </Button>
    );
  }

  return (
    <>
      <HelmetTracker title="FGF Home" />
      <Grid container spacing={2}>
        {/* ✅ Improved Title Section */}
        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: 'center',
              bgcolor: theme.palette.primary.main, // ✅ Theme-adaptive background
              color: theme.palette.primary.contrastText, // ✅ Adaptive text color
              p: 3,
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0,0,0,0.2)', // ✅ Subtle shadow
              mb: 2, // ✅ Space below
            }}
          >
            <Typography variant="h3" fontWeight="bold">
              Welcome to Full Gallop Farm
            </Typography>
          </Box>
          {getSaleHorseButton()}
        </Grid>

        <Grid item xs={12}>
            <AddMedia />
            <MediaFeed />
        </Grid>
      </Grid>
    </>
  );
}
