import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Button,
  Stack,
  Badge,
  Typography,
} from '@mui/material';
import { PlusSquare } from 'react-feather';
import { useDispatch } from 'react-redux';
import ColorProperties from '../../../core/ColorProperties';
import { DIVISION_TYPES } from '../../../../../constants/divisionTypes';
import { EVENT_LEVELS } from '../../../../../constants/eventLevels';
import { updateEventDivision } from '../../../../../redux/slices/events/eventDivisions.slice';

const EventDivisionTableRow = ({
  eventDivision,
  isSelected,
  handleClick,
  handleAddTestClick,
  addTestForm,
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleBGColorChange = (color) => {
    console.log('handleBGColorChange');
    const updatedEventDivision = {
      ...eventDivision,
      properties: { ...(eventDivision.properties || {}), bgColor: color }, // ✅ Create a new properties object
    };

    dispatch(updateEventDivision(updatedEventDivision));
  };

  const handleFontColorChange = (color) => {
    console.log('handleFontColorChange');
    const updatedEventDivision = {
      ...eventDivision,
      properties: { ...(eventDivision.properties || {}), fontColor: color }, // ✅ Create a new properties object
    };

    dispatch(updateEventDivision(updatedEventDivision));
  };

  return (
    <TableRow
      key={eventDivision.id}
      aria-checked={isSelected}
      onClick={(event) => handleClick(event, eventDivision.id)}
      selected={isSelected}
    >
      <TableCell component="th" scope="row">
        {eventDivision.id}
      </TableCell>
      <TableCell>{eventDivision.name}</TableCell>
      <TableCell>
        {DIVISION_TYPES.find(
          (option) => option.value === eventDivision.divisionType
        )?.key || 'Unknown'}
      </TableCell>
      <TableCell
        sx={{
          backgroundColor: eventDivision.properties?.bgColor || '#FFFFFF',
          color: eventDivision.properties?.fontColor || '#000000',
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {EVENT_LEVELS.find(
          (option) => option.id === eventDivision.divisionLevelId
        )?.name || 'Unknown'}
      </TableCell>
      <TableCell>
        <Stack>
          <Button
            aria-label="addTest"
            onClick={(event) => handleAddTestClick(event, eventDivision.id)}
            endIcon={<PlusSquare />}
            variant="outlined"
            color="success"
          >
            Add Test
          </Button>
          {addTestForm(isSelected)}
          <Badge
            onClick={() => setOpen(!open)}
            badgeContent={eventDivision.eventDivisionTests?.length || 0}
            color={
              eventDivision.eventDivisionTests?.length ? 'primary' : 'error'
            }
            showZero
          />
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography>BG Color</Typography>
            <ColorProperties
              pickColor={eventDivision.properties.bgColor}
              onColorChange={handleBGColorChange}
            />
          </Stack>
          <Stack direction="column" spacing={1} alignItems="center">
            <Typography>Font Color</Typography>
            <ColorProperties
              pickColor={eventDivision.properties.fontColor}
              onColorChange={handleFontColorChange}
            />
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default EventDivisionTableRow;
