import React from 'react';
import { Form } from 'react-final-form';
import {
  finalObjSelector,
  renderFormTitle,
  okCancel,
  finalTextField,
  //   createOptionsList,
} from '../../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import { updatePermissionProperties } from '../../../../redux/actions/admin/permission.actions';
// import PermissionProperties from '../../../../redux/classes/PermissionProperties';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function PermissionPropertiesForm({ permission, onOkClick }) {
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     dispatch(fetchDivisions());
  //   }, [dispatch]);

  //   var properties = new PermissionProperties();
  const types = [
    { key: 'None', value: 0 },
    { key: 'Integer', value: 1 },
    { key: 'String', value: 2 },
    { key: 'Date', value: 3 },
    { key: 'Boolean', value: 4 },
    { key: 'JSON', value: 5 },
  ];
  // const events = useSelector((state) => state.events);
  // const selectedEventId = 115;

  function submitAddProperty(property) {
    if (property) {
      if (permission.properties) {
        permission.properties.push(property);
      } else {
        permission.properties = [property];
      }
      return dispatch(
        updatePermissionProperties({
          properties: permission.properties,
          id: permission.id,
        })
      ).then(() => {});
    }
  }

  const initialValues = {
    permissionId: permission && permission.id ? permission.id : null,
    propertyName: '',
    propertyTypeId: 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    submitAddProperty(values);
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      {renderFormTitle(null, 'Property')}
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('propertyName', 'Property Name')}
            {finalObjSelector('propertyTypeId', 'Property', types)}

            {okCancel(onOkClick, 'Add')}
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}
