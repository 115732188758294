import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle } from 'react-feather';
import HelmetTracker from '../../core/HelmetTracker';

import {
  fetchProductCategories,
  //   updatePropertiesProductCategory,
} from '../../../redux/actions/store/productCategories.actions';
import { useDispatch, useSelector } from 'react-redux';
import ProductCategoryForm from '../forms/ProductCategoryForm';
// import SelectTestForm from '../forms/SelectTestForm';
// import EventTest from '../core/EventTest';

// import ColorProperties from '../core/ColorProperties';
// import { PopoverPicker } from '../core/PopoverPicker';
// import { HexColorPicker } from "react-colorful";

import {
  // Button,
  Card as MuiCard,
  CardHeader,
  IconButton,
  // Chip as MuiChip,
  Paper as MuiPaper,
  // Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

// const Chip = styled(MuiChip)`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : 'primary'].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function ProductCategoriesPage() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  // const [editing, setEditing] = React.useState(false);
  const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductCategories());
  }, [dispatch]);

  const productCategories = useSelector((state) => state.productCategories);
  const productCategory = useSelector((state) =>
    state.productCategories.find((x) => x.id === selected)
  );

  const handleClick = (event, id) => {
    if (!addingTest) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    // setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setSelected(-1);
  };

  const isSelected = (id) => selected === id;

  // function eventTestsToUI(tests) {
  //   // if (tests) {
  //   //   return (
  //   //     <>
  //   //       {tests.map(function (test) {
  //   //         return <EventTest testData={test} key={test.eventTestId} />;
  //   //       })}
  //   //     </>
  //   //   );
  //   // }
  // }

  // const handleBGColorChange = (productCategory, color) => {
  //   productCategory.properties.bgColor = color;
  //   // dispatch(
  //   //   updatePropertiesProductCategory({
  //   //     properties: productCategory.properties,
  //   //     id: productCategory.id,
  //   //   })
  //   // );
  // };

  // const handleFontColorChange = (productCategory, color) => {
  //   productCategory.properties.fontColor = color;
  //   // dispatch(
  //   //   updatePropertiesProductCategory({
  //   //     properties: productCategory.properties,
  //   //     id: productCategory.id,
  //   //   })
  //   // );
  // };

  function mapBody(productCategories) {
    if (productCategories && productCategories.length > 0) {
      const tableBody = productCategories.map((productCategory) => {
        const isItemSelected = isSelected(productCategory.id);
        return (
          <TableRow
            key={productCategory.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, productCategory.id)}
            selected={isItemSelected}
          >
            <TableCell component="th" scope="row">
              {productCategory.id}
            </TableCell>
            <TableCell>{productCategory.categoryName}</TableCell>
            <TableCell>
              {productCategory.parentCategoryName
                ? productCategory.parentCategoryName
                : ''}
            </TableCell>
            <TableCell>
              {productCategory.parentCategoryId
                ? productCategory.parentCategoryId
                : ''}
            </TableCell>
            <TableCell>{productCategory.sku()}</TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function showProductCategoryForm() {
    if (selected && selected > 0) {
      if (productCategory && !addingTest) {
        return (
          <ProductCategoryForm
            onOkClick={handleFormOkClick}
            productCategory={productCategory}
            productCategories={productCategories}
          />
        );
      }
    } else if (creating) {
      return (
        <ProductCategoryForm
          onOkClick={handleFormOkClick}
          productCategories={productCategories}
        />
      );
    }
  }

  const title = 'Product Categories';

  return (
    <>
      <HelmetTracker title="Product Categories" />
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title={title}
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Parent Category</TableCell>
                  <TableCell>Parent Category Id</TableCell>
                  <TableCell>SKU Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(productCategories)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showProductCategoryForm()}
      </Card>
    </>
  );
}
