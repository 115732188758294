import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';

import { Chart as ChartJS, registerables } from 'chart.js';
// import { Chart } from 'react-chartjs-2'

import { Line } from 'react-chartjs-2';
import { MoreVertical } from 'react-feather';
import { last7Days } from '../../../core/DateTools';

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { spacing } from '@mui/system';

ChartJS.register(...registerables);

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function byDayForWeekArray(objIn) {
  var returnArray = [];
  if (objIn) {
    returnArray.push(objIn.dayMinusSixRolling);
    returnArray.push(objIn.dayMinusFiveRolling);
    returnArray.push(objIn.dayMinusFourRolling);
    returnArray.push(objIn.dayMinusThreeRolling);
    returnArray.push(objIn.dayMinusTwoRolling);
    returnArray.push(objIn.dayMinusOneRolling);
    returnArray.push(objIn.dayRolling);
  }
  return returnArray;
}

function DailyLineChart({ theme, title, dailyData, dataLabels }) {
  function firstDataset() {
    if (dailyData && dailyData[0]) {
      var returnObj = {
        label: dataLabels[0],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey[500],
        tension: 0.4,
        data: byDayForWeekArray(dailyData[0]),
      };
      if (dailyData.length > 1) {
        returnObj.borderDash = [4, 4];
      }
      return returnObj;
    }
  }

  function secondDataset() {
    if (dailyData && dailyData[1]) {
      return {
        label: dataLabels[1],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: theme.palette.grey[500],
        // borderDash: [4, 4],
        tension: 0.4,
        data: byDayForWeekArray(dailyData[1]),
      };
    }
  }

  function thirdDataset() {
    if (dailyData && dailyData[2]) {
      return {
        label: dataLabels[2],
        fill: true,
        backgroundColor: 'transparent',
        borderColor: theme.palette.secondary.main,
        borderDash: [],
        tension: 0.4,
        data: byDayForWeekArray(dailyData[2]),
      };
    }
  }

  function getDatasets() {
    var returnArray = [];
    if (dailyData && dailyData[2]) {
      returnArray.push(thirdDataset());
    }
    if (dailyData && dailyData[1]) {
      returnArray.push(secondDataset());
    }
    if (dailyData && dailyData[0]) {
      returnArray.push(firstDataset());
    }
    return returnArray;
  }

  const data = {
    labels: last7Days(),
    datasets: getDatasets(),
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(0,0,0,0.0)',
        },
      },
      y: {
        grid: {
          color: 'rgba(0,0,0,0.0375)',
          fontColor: '#fff',
        },
      },
    },
  };

  return (
    <Card mb={6} sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 2 }}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={title}
      />
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(DailyLineChart);
