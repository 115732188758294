import React from 'react';
import { Button } from 'react-bootstrap';

const DOC_TYPE_ENTRY_FORM = 1;
const DOC_TYPE_RIDE_TIMES = 2;
const DOC_TYPE_ENTRY_STATUS = 3;
const DOC_TYPE_STABLING = 4;
const DOC_TYPE_RESULTS = 5;
const DOC_TYPE_PROGRAM = 6;
   // here for info purpose
// Should refactor into an object. 

const margin = 'mx-1';

export function renderOmnibusButton(event) {
  if (event.useaNumber && event.useaNumber !== '') {
    let link =
      'http://useventing.com/events-competitions/calendar/event?event=' +
      event.useaNumber;
    return (
      <Button variant="danger" href={link} className={margin} >
        Omnibus
      </Button>
    );
  }
  return '';
}

function hasDocuments(event) {
  return event && event.docs && event.docs.length > 0;
}

function isEntryForm(doc) {
  return doc.type === DOC_TYPE_ENTRY_FORM;
}

function getEntryFormDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isEntryForm);
  }
}

export function renderEntryFormButton(event) {
  let doc = getEntryFormDoc(event);
  if (doc) {
    return (
      <Button variant="info" href={doc.amazons3URL} className={margin} >
        Entry Form
      </Button>
    );
  }
  return '';
}

function isRideTimes(doc) {
  return doc.type === DOC_TYPE_RIDE_TIMES;
}

function getRideTimesDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isRideTimes);
  }
}

export function renderRideTimesPdfButton(event) {
  let doc = getRideTimesDoc(event);
  if (doc) {
    return (
      <Button variant="danger" href={doc.amazons3URL} className={margin} >
        Ride Times (PDF)
      </Button>
    );
  }
  return '';
}


function getRideTimesPath(event) {
  return "/calendar/rideTimes/" + event.id;
}

export function renderRideTimesButton(event) {
  if (event.rideTimesData.length > 0) {
    return (
      <Button variant="warning" href={getRideTimesPath(event)} className={margin} >
        Ride Times
      </Button>
    );
  }
}

function isEntryStatus(doc) {
  return doc.type === DOC_TYPE_ENTRY_STATUS;
}

function getEntryStatusDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isEntryStatus);
  }
}

export function renderEntryStatusPdfButton(event) {
  let doc = getEntryStatusDoc(event);
  if (doc) {
    return (
      <Button variant="success" href={doc.amazons3URL} className={margin} >
        Entry Status (PDF)
      </Button>
    );
  }
  return '';
}

function isStabling(doc) {
  return doc.type === DOC_TYPE_STABLING;
}

function isResults(doc) {
  return doc.type === DOC_TYPE_RESULTS;
}

function isProgram(doc) {
  return doc.type === DOC_TYPE_PROGRAM;
}

function getStablingDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isStabling);
  }
}

function getProgramDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isProgram);
  }
}

function getResultsDoc(event) {
  if (hasDocuments(event)) {
    return event.docs.findLast(isResults);
  }
}

function getSjCoursesPath(event) {
  return "/calendar/sjCourseInfo/" + event.id;
}

export function sjCoursesButton(event) {
  if (event.sjCourses.length > 0) {
    return (
      <Button variant="success" href={getSjCoursesPath(event)} className={margin} >
        SJ Courses
      </Button>
    );
  }
  return '';
}

function getXcCoursesPath(event) {
  return "/calendar/xcCourseInfo/" + event.id;
}

export function xcCoursesButton(event) {
  if (event.xcCourses.length > 0) {
    return (
      <Button variant="primary" href={getXcCoursesPath(event)} className={margin} >
        XC Courses
      </Button>
    );
  }
  return '';
}

function getEntryStatusPath(event) {
  return "/calendar/entryStatus/" + event.id;
}

export function renderEntryStatusButton(event) {
  if (event.entryStatusData.length > 0) {
    return (
      <Button variant="success" href={getEntryStatusPath(event)} className={margin} >
        Entry Status
      </Button>
    );
  }
  return '';
}

export function renderProgramPdfButton(event) {
  let doc = getProgramDoc(event);
  if (doc) {
    return (
      <Button variant="info" href={doc.amazons3URL} className={margin} >
        Program (PDF)
      </Button>
    );
  }
  return '';
}

export function renderStablingButton(event) {
  let doc = getStablingDoc(event);
  if (doc) {
    return (
      <Button variant="primary" href={doc.amazons3URL} className={margin} >
        Stabling
      </Button>
    );
  }
  return '';
}

export function renderResultsButton(event) {
  let doc = getResultsDoc(event);
  if (doc) {
    return (
      <Button variant="secondary" href={doc.amazons3URL} className={margin} >
        Results
      </Button>
    );
  }
  return '';
}

export function renderEnterOnlineButton(event) {
  if (event.eeNumber && event.eeNumber.length > 0) {
    let link = 'https://www.evententries.com/#Show' + event.eeNumber;
    return (
      <Button variant="danger" href={link} className={margin} >
        Enter Online
      </Button>
    );
  }
  return '';
}