import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle, Edit } from 'react-feather';

// import { fetchSJCourses } from '../../../redux/actions/users/barn.actions';
// import { useDispatch, useSelector } from 'react-redux';
import SJCoursesForm from './SJCoursesForm';
import { levelChip } from '../../../core/levelsTools';

import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function SJCoursesPage({ eventData }) {
  const sjCourses =
    eventData && eventData.sjCoursesArray ? [...eventData.sjCoursesArray] : [];

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const handleClick = (event, id) => {
    if (selected !== id) {
      setSelected(id);
    }

    setCreating(false);
    setEditing(false);
  };

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);
    setCreating(false);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleAddCancelClick = (event) => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  // Single select
  const isSelected = (id) => selected === id;

  function mapBody() {
    if (sjCourses && sjCourses.length > 0) {
      const tableBody = sjCourses.map((sjCourse) => {
        const isItemSelected = isSelected(sjCourse.id);
        return (
          <Fragment key={sjCourse.id}>
            <TableRow
              key={sjCourse.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, sjCourse.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {sjCourse.id}
              </TableCell>
              <TableCell>{sjCourse.name}</TableCell>
              <TableCell>{levelChip(sjCourse.level)}</TableCell>
              <TableCell>{sjCourse.maximumTime}</TableCell>
              <TableCell>{sjCourse.optimumTime}</TableCell>
              <TableCell>{sjCourse.optimumSpeed}</TableCell>
              <TableCell>{sjCourse.lengthMeters}</TableCell>
              <TableCell>
                <Stack>
                  <Button
                    aria-label="editSjCourse"
                    onClick={(event) => handleEditClick(event, sjCourse.id)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit SjCourse
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={4}
              >
                <Collapse
                  in={isItemSelected && editing}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 5 }}>
                    <SJCoursesForm
                      onOkClick={handleFormOkClick}
                      onCancelClick={handleFormCancelClick}
                      sjCourse={sjCourse}
                      eventData={eventData}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showAddSJCourseForm() {
    if (creating) {
      return (
        <SJCoursesForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
          eventData={eventData}
        />
      );
    }
  }

  const title = eventData
    ? 'SJCourses for ' + eventData.title
    : 'SJCourses for (Loading)';

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddCancelClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title={title}
      />
      {showAddSJCourseForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>maximumTime</TableCell>
                <TableCell>optimumTime</TableCell>
                <TableCell>optimumSpeed</TableCell>
                <TableCell>lengthMeters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default SJCoursesPage;
