import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  TextField,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { PhotoCamera, VideoCall, Delete, Cancel } from '@mui/icons-material';
import { createPost } from '../../redux/slices/posts/posts.slice';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../UserProfile';

export default function AddMedia() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const [content, setContent] = useState('');
  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  function checkHasPermission(permission) {
    return auth?.perms?.some((p) => p.permission === permission);
  }

  const hasCreateMedia = checkHasPermission('CREATE_MEDIA');
  const isPhotoUploadDisabled = video !== null;
  const isVideoUploadDisabled = photos.length > 0;

  const handlePhotoChange = (event) => {
    if (video) {
      alert('You cannot upload photos when a video is selected.');
      return;
    }
    const files = Array.from(event.target.files).filter((file) =>
      file.type.startsWith('image/')
    );
    const uniqueFiles = files.filter(
      (file) => !photos.some((p) => p.name === file.name)
    );

    if (uniqueFiles.length + photos.length > 10) {
      alert('You can only upload up to 10 photos.');
      return;
    }
    setPhotos((prev) => [...prev, ...uniqueFiles]);
  };

  const handleVideoChange = (event) => {
    if (photos.length > 0) {
      alert('You cannot upload a video when photos are selected.');
      return;
    }
    const file = event.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideo(file);
      setVideoPreview(URL.createObjectURL(file));
    } else {
      alert('Please select a valid video file.');
    }
  };

  const removePhoto = (index) => {
    setPhotos((prev) => prev.filter((_, i) => i !== index));
  };

  const removeVideo = () => {
    if (videoPreview) {
      URL.revokeObjectURL(videoPreview);
    }
    setVideo(null);
    setVideoPreview(null);
    document.getElementById('video-upload').value = ''; // Reset input field
  };

  const cancelPost = () => {
    setContent('');
    setPhotos([]);
    setVideo(null);
    setVideoPreview(null);
  };

  const handleSubmit = () => {
    if (!content.trim() && photos.length === 0 && !video) return;

    const formData = new FormData();
    formData.append('userId', auth.id);
    formData.append('content', content);

    photos.forEach((file) => {
      formData.append('photos', file);
    });

    // Use 's3video' field name to match backend controller expectations.
    if (video) {
      formData.append('s3video', video);
      // Optionally append additional video metadata (width, height, aspectRatio, thumbS3URL) here.
    }

    dispatch(createPost(formData)).then(() => {
      cancelPost();
      // navigate('/media');
    });
  };

  return (
    hasCreateMedia && (
      <Card sx={{ p: 2, mt: 2 }}>
        <Typography variant="h6">Create a Post</Typography>
        {auth ? (
          <Typography variant="h6">
            <UserProfile firstName={auth.firstName} lastName={auth.lastName} />
          </Typography>
        ) : (
          ''
        )}
        <TextField
          fullWidth
          multiline
          rows={3}
          placeholder="What's on your mind?"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Typography variant="body2" sx={{ mb: 1 }}>
          {photos.length} / 10 photos selected
        </Typography>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="photo-upload"
          type="file"
          multiple
          onChange={handlePhotoChange}
          disabled={isPhotoUploadDisabled}
        />
        <label htmlFor="photo-upload">
          <Button
            variant="outlined"
            component="span"
            startIcon={<PhotoCamera />}
            sx={{ mb: 2 }}
            disabled={isPhotoUploadDisabled}
          >
            Add Photos
          </Button>
        </label>

        <input
          accept="video/*"
          style={{ display: 'none' }}
          id="video-upload"
          type="file"
          onChange={handleVideoChange}
          disabled={isVideoUploadDisabled}
        />
        <label htmlFor="video-upload">
          <Button
            variant="outlined"
            component="span"
            startIcon={<VideoCall />}
            sx={{ mb: 2, ml: 2 }}
            disabled={isVideoUploadDisabled}
          >
            Add Video
          </Button>
        </label>

        {/* Photo Previews */}
        {photos.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
            {photos.map((photo, index) => (
              <Box key={index} sx={{ position: 'relative', m: 1 }}>
                <img
                  src={URL.createObjectURL(photo)}
                  alt="preview"
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 8,
                    objectFit: 'cover',
                  }}
                />
                <IconButton
                  onClick={() => removePhoto(index)}
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: -5,
                    right: -5,
                    background: 'rgba(0,0,0,0.5)',
                    color: 'white',
                  }}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        {/* Video Preview */}
        {videoPreview && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2">Selected Video:</Typography>
            <video
              width="100%"
              height="auto"
              controls
              style={{ maxWidth: '450px' }}
            >
              <source src={videoPreview} type={video.type} />
              Your browser does not support the video tag.
            </video>
            <Button variant="text" color="error" onClick={removeVideo}>
              Remove Video
            </Button>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, mr: 2 }}
          onClick={handleSubmit}
          disabled={photos.length === 0 && !video && content.trim() === ''}
        >
          Post
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<Cancel />}
          sx={{ mt: 2 }}
          onClick={cancelPost}
        >
          Cancel
        </Button>
      </Card>
    )
  );
}
