import React from 'react';
import styled from '@emotion/styled';
import { Button, Chip as MuiChip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { spacing } from '@mui/system';

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const fontThickness = 600;

const colorScheme = {
  advanced: {
    backgroundColor: 'darkblue',
    color: 'white',
    fontWeight: fontThickness,
  },
  intermediate: {
    backgroundColor: 'red',
    color: 'white',
    fontWeight: fontThickness,
  },
  prelim: {
    backgroundColor: 'darkgreen',
    color: 'white',
    fontWeight: fontThickness,
  },
  modified: {
    backgroundColor: 'darkblue',
    color: 'orange',
    fontWeight: fontThickness,
  },
  training: {
    backgroundColor: 'black',
    color: 'white',
    fontWeight: fontThickness,
  },
  novice: {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: fontThickness,
  },
  beginnerNovice: {
    backgroundColor: 'yellow',
    color: 'black',
    fontWeight: fontThickness,
  },
  starter: {
    backgroundColor: '#0099ff',
    color: 'white',
    fontWeight: fontThickness,
  },
  preStarter: {
    backgroundColor: 'purple',
    color: 'white',
    fontWeight: fontThickness,
  },
};

export  function textChip(level, text) {
    var color = 'starter';
    if (level === 'Intermediate') {
      color = 'intermediate';
    }
    if (level === 'Preliminary') {
      color = 'preliminary';
    }
    if (level === 'Modified') {
      color = 'modified';
    }
    if (level === 'Training') {
      color = 'training';
    }
    if (level === 'Novice') {
      color = 'novice';
    }
    if (level === 'Beginner Novice') {
      color = 'beginnerNovice';
    }
    return (
      <ThemeProvider theme={theme}>
        <Chip label={text} color={color} />
      </ThemeProvider>
    );
  }
  
   export function levelChip(jumpLevel) {
      var color = 'amoeba';
      var levelLabel = 'Amoeba';
      if (jumpLevel === 2) {
        color = 'intermediate';
        levelLabel = 'Intermediate';
      }
      if (jumpLevel === 3) {
        color = 'preliminary';
        levelLabel = 'Preliminary';
      }
      if (jumpLevel === 4) {
        color = 'modified';
        levelLabel = 'Modified';
      }
      if (jumpLevel === 5 ) {
        color = 'training';
        levelLabel = 'Training';
      }
      if (jumpLevel === 6) {
        color = 'novice';
        levelLabel = 'Novice';
      }
      if (jumpLevel === 7) {
        color = 'beginnerNovice';
        levelLabel = 'Beginner Novice';
      }
      if (jumpLevel === 8) {
        color = 'starter';
        levelLabel = 'Starter';
      }
    //   levelLabel = levelLabel + ' (' + getJumpCountForLevel(jumpLevel) + ')';
      return (
        <ThemeProvider theme={theme}>
          <Chip label={levelLabel} color={color} />
        </ThemeProvider>
      );
    }

function getColorSchemeFromLevelId(id) {
  switch (id) {
    case 0:
      return colorScheme.novice; // None
    case 1:
      return colorScheme.advanced;
    case 2:
      return colorScheme.intermediate;
    case 3:
      return colorScheme.prelim;
    case 4:
      return colorScheme.modified;
    case 5:
      return colorScheme.training;
    case 6:
      return colorScheme.novice;
    case 7:
      return colorScheme.beginnerNovice;
    case 8:
      return colorScheme.starter;
    case 9:
      return colorScheme.preStarter;
    case 10:
      return colorScheme.novice; //Unknown
    default:
      return colorScheme.novice;
  }
}

function getNameByLevelId(id) {
  switch (id) {
    case 0:
      return 'None';
    case 1:
      return 'Advanced';
    case 2:
      return 'Intermediate';
    case 3:
      return 'Prelim';
    case 4:
      return 'Modified';
    case 5:
      return 'Training';
    case 6:
      return 'Novice';
    case 7:
      return 'BegNovice';
    case 8:
      return 'Starter';
    case 9:
      return 'PreStarter';
    case 10:
      return 'Unkown'; //Unknown
    default:
      return 'Unk';
  }
}

const theme = createTheme({
  palette: {
    intermediate: {
      main: '#ff0000', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    preliminary: {
      main: '#248f24', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    modified: {
      main: '#020082', // Your custom color
      contrastText: '#ff4d00', // Text color for contrast
    },
    training: {
      main: '#000000', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    novice: {
      main: '#f8f8f8', // Your custom color
      contrastText: '#000000', // Text color for contrast
    },
    beginnerNovice: {
      main: '#ffff00', // Your custom color
      contrastText: '#000000', // Text color for contrast
    },
    starter: {
      main: '#0099ff', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
    amoeba: {
      main: '#710193', // Your custom color
      contrastText: '#f8f8f8', // Text color for contrast
    },
  },
});

// const levelOptionsList = [
//   { key: 'None', value: '0' },
//   { key: 'Advanced', value: '1' },
//   { key: 'Intermediate', value: '2' },
//   { key: 'Prelim', value: '3' },
//   { key: 'Modified', value: '4' },
//   { key: 'Training', value: '5' },
//   { key: 'Novice', value: '6' },
//   { key: 'Beg Novice', value: '7' },
//   { key: 'Starter', value: '8' },
//   { key: 'PreStarter', value: '9' },
//   { key: 'Unknown', value: '10' },
// ];

export function buttonByLevel(levelId, caption, onClick) {
  return (
    <Button style={getColorSchemeFromLevelId(levelId)} onClick={onClick}>
      {caption}
    </Button>
  );
}

export function chipByLevel(levelId, onClick) {
  return (
    <Chip
      label={getNameByLevelId(levelId)}
      style={getColorSchemeFromLevelId(levelId)}
      onClick={onClick}
    />
  );
}
