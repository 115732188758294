import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  saveBarn,
  fetchBarn,
  updateBarn,
} from '../../../redux/actions/users/barn.actions';
import { redirect } from 'react-router';
import BarnForm from './core/BarnForm';
import HelmetTracker from '../../core/HelmetTracker';

class BarnFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchBarn(this.props.match.params.id);
    }
  };

  saveBarn = ({ id, name }) => {
    if (id) {
      return this.props
        .updateBarn({
          id,
          name,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    } else {
      return this.props
        .saveBarn({
          name,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    const userId = this.props.auth ? this.props.auth.id : null;
    const title = this.props.barn ? 'Edit Barn' : 'Add Barn';
    const objId = this.props.barn ? this.props.barn.id : null;

    return (
      <div>
        {this.state.redirect ? (
          redirect('/barns')
        ) : (
          <>
            <HelmetTracker title={title} objId={objId} userId={userId} />
            <BarnForm
              barn={this.props.barn}
              saveBarn={this.saveBarn}
              auth={this.props.auth}
              barnUsers={this.props.barnUsers}
            />
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      barn: state.barns[props.match.params.id],
      auth: state.auth,
      barnUsers: state.barnUsers,
    };
  }

  return { barn: null, auth: state.auth };
}

export default connect(mapStateToProps, { saveBarn, fetchBarn, updateBarn })(
  BarnFormPage
);
