// /store/competitionsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  competitionToClass,
  competitionsToClassArray,
} from '../../classes/competitions/Competition.class';
import axios from 'axios';

const API_URL = '/api/competitions';
const CALENDAR_API_URL = '/api/calendar';

// Async Thunks
export const fetchCompetitions = createAsyncThunk(
  'competitions/fetchAll',
  async () => {
    const response = await axios.get(API_URL);
    return response.data;
  }
);

export const fetchCompetitionById = createAsyncThunk(
  'competitions/fetchById',
  async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  }
);

export const createCompetition = createAsyncThunk(
  'competitions/create',
  async (values) => {
    const response = await axios.post(API_URL, values);
    return response.data;
  }
);

export const updateCompetition = createAsyncThunk(
  'competitions/update',
  async (values) => {
    const response = await axios.put(`${API_URL}/${values.id}`, values);
    return response.data;
  }
);

export const deleteCompetition = createAsyncThunk(
  'competitions/delete',
  async (id) => {
    await axios.delete(`${API_URL}/${id}`);
    return id;
  }
);

export const fetchCalendar = createAsyncThunk(
  'competitions/fetchCalendar',
  async () => {
    const response = await axios.get(CALENDAR_API_URL);
    return response.data;
  }
);

export const fetchCalendarEvent = createAsyncThunk(
  'competitions/fetchCalendarEvent',
  async (id) => {
    const response = await axios.get(`${CALENDAR_API_URL}/${id}`);
    return response.data;
  }
);

// export const addDocToCompetition = createAsyncThunk(
//   'competitions/addDoc',
//   async (values) => {
//     const response = await axios.post(`${API_URL}/docs/upload`, values);
//     return response.data;
//   }
// );

export const addDocToCompetition = createAsyncThunk(
  'competitions/addDoc',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/competitions/docs/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data; // Returns updated competition object
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to upload document');
    }
  }
);

const competitionsSlice = createSlice({
  name: 'competitions',
  initialState: {
    list: [],
    calendar: [],
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompetitions.fulfilled, (state, action) => {
        state.list = competitionsToClassArray(action.payload);
      })
      .addCase(fetchCompetitionById.fulfilled, (state, action) => {
        const updatedData = state.list.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        );
        state.list = competitionsToClassArray(updatedData);
      })
      .addCase(createCompetition.fulfilled, (state, action) => {
        const newCompetition = competitionToClass(action.payload);
        state.list = [...state.list, newCompetition].sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
      })
      .addCase(updateCompetition.fulfilled, (state, action) => {
        const index = state.list.findIndex(
          (item) => item.id === action.payload.id
        );
        if (index !== -1)
          state.list[index] = competitionToClass(action.payload);
      })
      .addCase(deleteCompetition.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => item.id !== action.payload);
      })
      .addCase(fetchCalendar.fulfilled, (state, action) => {
        state.calendar = competitionsToClassArray(action.payload);
      })
      .addCase(fetchCalendarEvent.fulfilled, (state, action) => {
        const index = state.calendar.findIndex(
          (event) => event.id === action.payload.id
        );
        if (index !== -1) {
          state.calendar[index] = {
            ...state.calendar[index],
            ...action.payload,
          };
        } else {
          state.calendar.push(action.payload); // ✅ Add if event does not exist
        }
      })
      .addCase(addDocToCompetition.fulfilled, (state, action) => {
        const updatedData = state.list.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        );
        state.list = competitionsToClassArray(updatedData);
      });
  },
});

export default competitionsSlice.reducer;
