import React from 'react';
import {
  Calendar,
  CheckSquare,
  Clock,
  CreditCard,
  Home,
  Layout,
  List,
  Map,
  Navigation,
  PhoneCall,
  Settings, 
  ShoppingCart,
  Star, 
  User,
  Users,
} from 'react-feather';
import { FaBootstrap, FaHorse, FaHorseHead } from 'react-icons/fa6';
import { RiGraduationCapLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { GiBarn } from 'react-icons/gi';
import { LuClipboardSignature } from 'react-icons/lu';
import { FiShield } from 'react-icons/fi';
import _ from 'lodash';

// Needs to be a component to use a hook call.
const HorsesBadge = () => {
  const horses = useSelector((state) => state.horses.list);
  let unarchived = _.filter(horses, function (o) {
    return o.dataStatus < 3;
  });
  if (horses) {
    return <>{_.size(unarchived)}</>;
  } else {
    return <>0</>;
  }
};

const pagesSection = [
  {
    href: '/',
    icon: Home,
    title: 'FGF Home',
    badge: process.env.REACT_APP_VERSION,
  },
  {
    href: '/calendar',
    icon: Calendar,
    title: 'Calendar',
  },
  {
    href: '/sales',
    icon: FaHorse,
    title: 'Sales',
  },
  {
    href: '/boarding',
    icon: FaBootstrap,
    title: 'Boarding',
  },
  {
    href: '/schooling',
    icon: RiGraduationCapLine,
    title: 'Schooling',
  },
  {
    href: '/jumps',
    icon: Star,
    title: 'Jumps',
  },
  {
    href: '/directions',
    icon: Navigation,
    title: 'Directions',
  },
  {
    href: '/maps',
    icon: Map,
    title: 'Maps',
  },
  {
    href: '/contactus',
    icon: PhoneCall,
    title: 'Contact Us',
  },
];

const horsesSection = [
  {
    href: '/tasks',
    icon: CheckSquare,
    title: 'Tasks',
  },
  {
    href: '/horses',
    icon: FaHorseHead,
    title: 'Horses',
    badge: <HorsesBadge />,
  },
  {
    href: '/barns',
    icon: GiBarn,
    title: 'Barns',
  },
];

const reportingSection = [
  {
    href: '/reporting',
    icon: CheckSquare,
    title: 'Reporting',
  },
];

const adminSection = [
  {
    href: '/admin/home',
    icon: Home,
    title: 'Home',
  },
  {
    href: '/events',
    icon: List,
    title: 'Events',
  },
  {
    href: '/release',
    icon: LuClipboardSignature,
    title: 'Release',
  },
  {
    href: '/admin',
    icon: FiShield,
    title: 'Users',
    children: [
      {
        href: '/admin/permissions',
        title: 'Permissions',
      },
    ],
  },
  {
    href: '/scheduling',
    icon: Clock,
    title: 'Scheduling',
    children: [
      {
        href: '/scheduling/home',
        title: 'Home',
      },
      {
        icon: Settings, 
        href: '/scheduling/settings',
        title: 'Settings',
      },
      {
        href: '/scheduling/divisions',
        title: 'Divisions',
      },
      {
        href: '/scheduling/entries',
        title: 'Entries',
      },
      {
        href: '/scheduling/event/divisions',
        title: 'Event Divisions',
      },
      {
        href: '/scheduling/times',
        title: 'Times',
      },
    ],
  },
  {
    href: '/store',
    icon: ShoppingCart,
    title: 'Store',
    children: [
      {
        href: '/store/home',
        title: 'Store Home',
      },
      {
        href: '/store/products',
        title: 'Products',
      },
      {
        href: '/store/productcategories',
        title: 'Product Categories',
      },
      {
        href: '/store/productitems',
        title: 'Product Items',
      },
      {
        href: '/store/event/divisions',
        title: 'Event Divisions',
      },
      {
        href: '/store/times',
        title: 'Times',
      },
    ],
  },
];

const accountSection = [
  {
    href: '/user',
    icon: User,
    title: 'User Account',
    children: [
      {
        href: '/user/home',
        icon: Layout,
        title: 'My Home',
      },
      {
        href: '/user/horses',
        icon: FaHorseHead,
        title: 'My Horses',
      },
      {
        href: '/user/people',
        icon: Users,
        title: 'My People',
      },
      {
        href: '/user/entries',
        icon: CheckSquare,
        title: 'My Entries',
      },
    ],
  },
  {
    href: '/orders',
    icon: ShoppingCart,
    title: 'Orders',
  },
  {
    href: '/checkout',
    icon: ShoppingCart,
    title: 'Checkout',
  },
  {
    href: '/invoices',
    icon: CreditCard,
    title: 'Invoices',
    children: [
      {
        href: '/invoices',
        title: 'List',
      },
      {
        href: '/invoices/detail',
        title: 'Detail',
      },
    ],
  },
];

const navItems = [
  {
    title: 'Pages',
    pages: pagesSection,
  },
  {
    title: 'Horses',
    pages: horsesSection,
    requireLogin: true,
    addHorses: true,
  },
  {
    title: 'Reporting',
    pages: reportingSection,
    requireLogin: true,
    addHorses: true,
  },
  {
    title: 'Admin',
    pages: adminSection,
    admin: true,
    requireLogin: true,
  },
  {
    title: 'Account',
    pages: accountSection,
    requireLogin: true,
  },
];

export default navItems;
