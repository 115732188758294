import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCalendarEvent } from '../../../redux/slices/competitions/competitions.slice';
import _ from 'lodash';
import OrderedTimesTable from './OrderedTimesTable';
import PhaseChip from './PhaseChip';
import SelectedTimesTable from './SelectedTimesTable';
import moment from 'moment';
import HelmetTracker from '../../core/HelmetTracker';

import {
  prelimMapButton,
  modifiedMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
  preStarterMapButton,
  prelimOptimumButton,
  modifiedOptimumButton,
  trainingOptimumButton,
  noviceOptimumButton,
  begNoviceOptimumButton,
  starterOptimumButton,
  preStarterOptimumButton,
} from '../../core/MapTools';

import styled from '@emotion/styled';
import {
  Card as MuiCard,
  CardHeader,
  Checkbox,
  Paper as MuiPaper,
  Stack,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function CalendarRideTimesTable() {
  const theme = useTheme(); // ✅ Get current MUI theme
  const { id } = useParams();
  const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchCalendarEvent(id));
    }
  }, [dispatch, id]);

  const eventSelected = useSelector(
    (state) =>
      state.competitions.calendar.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function getOrderedArray(arrayIn) {
    var tmpArrayToBuild = Array(0);

    arrayIn.forEach(function (rideTime) {
      if (rideTime.dressageTime) {
        var dress = {
          rider: rideTime.rider,
          horse: rideTime.horse,
          division: rideTime.division,
          time: rideTime.dressageTime,
          date: new moment(
            rideTime.dressageTime.replace('Sun ', ''),
            'hh:mm A'
          ),
          eventId: rideTime.eventId,
          phase: 1,
        };

        tmpArrayToBuild.push(dress);
      }
      if (rideTime.stadiumTime) {
        var stadium = {
          rider: rideTime.rider,
          horse: rideTime.horse,
          division: rideTime.division,
          time: rideTime.stadiumTime,
          date: new moment(rideTime.stadiumTime.replace('Sun ', ''), 'hh:mm A'),
          eventId: rideTime.eventId,
          phase: 2,
        };
        tmpArrayToBuild.push(stadium);
      }
      if (rideTime.xcTime) {
        var cross = {
          rider: rideTime.rider,
          horse: rideTime.horse,
          division: rideTime.division,
          time: rideTime.xcTime,
          date: new moment(rideTime.xcTime.replace('Sun ', ''), 'hh:mm A'),
          eventId: rideTime.eventId,
          phase: 3,
        };
        tmpArrayToBuild.push(cross);
      }
    });
    tmpArrayToBuild = tmpArrayToBuild.sort((a, b) =>
      a.date > b.date ? 1 : -1
    );
    var lastTime = null;
    tmpArrayToBuild.forEach(function (rideTime) {
      if (lastTime) {
        rideTime.diff = rideTime.date.diff(lastTime, 'minutes') + ' min.';
        rideTime.diffValue = rideTime.date.diff(lastTime, 'minutes');
      } else {
        rideTime.diff = '';
      }
      lastTime = rideTime.date;
    });
    return tmpArrayToBuild;
  }

  // For multi row select
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Single select
  // const isSelected = (id) => selected === id;

  const handleClick = (event, rideTime) => {
    const selectedIndex = selected.indexOf(rideTime.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rideTime.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function getSelectedRideTimes() {
    if (eventSelected) {
      var returnArray = [];
      selected.forEach((idSelected) => {
        var rideTime = _.find(eventSelected.rideTimesData, { id: idSelected });
        returnArray.push(rideTime);
      });
      return returnArray;
    }
  }

  function getCourseMapButton(rideTimes) {
    if (rideTimes.division === 'P') return prelimMapButton();
    if (rideTimes.division === 'M') return modifiedMapButton();
    if (rideTimes.division === 'T' || rideTimes.division === 'PT')
      return trainingMapButton();
    if (rideTimes.division === 'N' || rideTimes.division === 'TN')
      return noviceMapButton();
    if (rideTimes.division === 'BN') return begNoviceMapButton();
    if (rideTimes.division === 'ST' || rideTimes.division === 'START')
      return starterMapButton();
    if (rideTimes.division === 'PST' || rideTimes.division === 'PSTART')
      return preStarterMapButton();
    return '';
  }

  function getOptTime(levelIndex) {
    if (eventSelected.xcCourses) {
      var result = eventSelected.xcCourses.find((obj) => {
        return obj.level === levelIndex;
      });
      if (result) return result.optimumTime.replaceAll('"', '');
    }
  }

  function getHref() {
    return '/calendar/xcCourseInfo/' + eventSelected.id;
  }

  function getOptimumTimeXC(rideTimes) {
    if (rideTimes.division === 'P') {
      return prelimOptimumButton(getOptTime(0), getHref());
    }
    if (rideTimes.division === 'M') {
      return modifiedOptimumButton(getOptTime(5), getHref());
    }
    if (rideTimes.division === 'T' || rideTimes.division === 'PT')
      return trainingOptimumButton(getOptTime(1), getHref());
    if (rideTimes.division === 'N' || rideTimes.division === 'TN')
      return noviceOptimumButton(getOptTime(2), getHref());
    if (rideTimes.division === 'BN')
      return begNoviceOptimumButton(getOptTime(3), getHref());
    if (rideTimes.division === 'ST' || rideTimes.division === 'START')
      return starterOptimumButton(getOptTime(4), getHref());
    if (rideTimes.division === 'PST' || rideTimes.division === 'PSTART')
      return preStarterOptimumButton(getHref());
    return '';
  }

  function getDivisionText(rideTimes) {
    if (rideTimes.division === 'P') {
      return prelimMapButton();
    }
    if (rideTimes.division === 'M') {
      return modifiedMapButton();
    }
    if (rideTimes.division === 'T' || rideTimes.division === 'PT')
      return trainingMapButton();
    if (rideTimes.division === 'N' || rideTimes.division === 'TN')
      return noviceMapButton();
    if (rideTimes.division === 'BN') return begNoviceMapButton();
    if (rideTimes.division === 'ST' || rideTimes.division === 'START')
      return starterMapButton();
    if (rideTimes.division === 'PST' || rideTimes.division === 'PSTART')
      return preStarterMapButton();
    return '';
  }

  const handleClearSelected = (event) => {
    setSelected([]);
  };

  function tableEvents(eventSelected) {
    if (eventSelected && eventSelected.rideTimesData.length > 0) {
      const tableBody = eventSelected.rideTimesData.map((rideTime) => {
        const isItemSelected = isSelected(rideTime.id);
        const labelId = `enhanced-table-checkbox-${rideTime.id}`;
        return (
          <TableRow
            key={rideTime.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, rideTime, isItemSelected)}
            selected={isItemSelected}
            sx={{
              bgcolor: theme.palette.background.default, // ✅ Ensures consistent background theme
              color: theme.palette.text.primary, // ✅ Ensures text contrasts well
            }}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCell><h5>{rideTime.rider}</h5></TableCell>
            <TableCell><h5>{rideTime.horse}</h5></TableCell>
            <TableCell>
              <Stack spacing={2}>{getDivisionText(rideTime)}</Stack>
            </TableCell>
            <TableCell>
              <Stack spacing={1}>
                <Stack direction="row">
                  D:&nbsp;&nbsp;
                  <PhaseChip label={rideTime.dressageTime} phase="D" />
                </Stack>
                <Stack direction="row">
                  S:&nbsp;&nbsp;
                  <PhaseChip label={rideTime.stadiumTime} phase="S" />
                </Stack>
                <Stack direction="row">
                  X:&nbsp;&nbsp;
                  <PhaseChip label={rideTime.xcTime} phase="X" />
                </Stack>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack spacing={2}>
                XC Map: {getCourseMapButton(rideTime)}
                XC Opt: {getOptimumTimeXC(rideTime)}
              </Stack>
            </TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function getSelectedTable() {
    if (selected.length > 0) {
      var selectedArray = getSelectedRideTimes();
      return (
        <SelectedTimesTable
          rideTimes={selectedArray}
          selectedEvent={eventSelected}
        />
      );
    }
  }

  function getOrderedTable() {
    if (selected.length > 0) {
      var orderedArray = getOrderedArray(getSelectedRideTimes());
      return <OrderedTimesTable rideTimes={orderedArray} />;
    }
  }

  const objId = id ? id : null;

  return (
    <div>
      <HelmetTracker title="Ride Times" objId={objId} />
      <div>{getOrderedTable()}</div>
      <div>{getSelectedTable()}</div>
      <div>
        {eventSelected && eventSelected.rideTimesData ? (
          <Card mb={6}>
            <CardHeader
              title="All Ride Times"
              sx={{
                bgcolor: theme.palette.primary.main, // ✅ Set background to primary
                color: theme.palette.primary.contrastText, // ✅ Ensure readable text
              }}
            />
            <Paper>
              <TableWrapper>
                <MuiTable size="small">
                  <TableHead>
                    <TableRow
                      sx={{
                        bgcolor: theme.palette.primary.main, // ✅ Set background to primary
                        color: theme.palette.primary.contrastText, // ✅ Ensure readable text
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < eventSelected.rideTimesData.length
                          }
                          checked={
                            eventSelected.rideTimesData.length > 0 &&
                            selected.length ===
                              eventSelected.rideTimesData.length
                          }
                          onChange={handleClearSelected}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                      <TableCell>Rider</TableCell>
                      <TableCell>Horse</TableCell>
                      <TableCell>Division</TableCell>
                      <TableCell>Times</TableCell>
                      <TableCell>XC Info</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{tableEvents(eventSelected)}</TableBody>
                </MuiTable>
              </TableWrapper>
            </Paper>
          </Card>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
