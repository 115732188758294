/* AUTO GEN */
/* XcCourse.js */
export class XcCourse {
  constructor(
    id,
    eventId,
    name,
    level,
    faultTime,
    faultSpeed,
    optimumTime,
    optimumSpeed,
    lengethMeters,
    mapURL,
    dataStatus
  ) {
    this.id = id;
    this.eventId = eventId;
    this.name = name;
    this.level = level;
    this.faultTime = faultTime;
    this.faultSpeed = faultSpeed;
    this.optimumTime = optimumTime;
    this.optimumSpeed = optimumSpeed;
    this.lengethMeters = lengethMeters;
    this.mapURL = mapURL;
    this.dataStatus = dataStatus;
  }
}

export function xcCourseToClass(xcCourse) {
  if (xcCourse) {
    return new XcCourse(
      xcCourse.id,
      xcCourse.eventId,
      xcCourse.name,
      xcCourse.level,
      xcCourse.faultTime,
      xcCourse.faultSpeed,
      xcCourse.optimumTime,
      xcCourse.optimumSpeed,
      xcCourse.lengethMeters,
      xcCourse.mapURL,
      xcCourse.dataStatus
    );
  } else {
    return null;
  }
}

export function xcCoursesToClassArray(xcCourses) {
  if (xcCourses) {
    const xcCoursesArray = xcCourses.map((item) => {
      return xcCourseToClass(item);
    });
    return xcCoursesArray;
  }
}
