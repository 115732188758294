import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Paper } from '@mui/material';
import { Clock } from 'react-bootstrap-icons';
import XCCourseInfoTable from '../core/XCCourseInfoTable';

export default function XCCourseInfo() {
  const theme = useTheme();

  return (
    <Box>
      <Paper
        sx={{
          p: 3,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          boxShadow: 3,
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          <Clock /> XC Course Info
        </Typography>
      </Paper>
      <XCCourseInfoTable />
    </Box>
  );
}
