import React from 'react';
import {
  TableRow,
  TableCell,
  Typography,
  Button,
  Stack,
  Box,
  useTheme,
} from '@mui/material';
import { eventImage } from '../../core/EventTools';
import { datesToText } from '../../core/DateTools';
import {
  renderOmnibusButton,
  renderEntryStatusPdfButton,
  renderEntryFormButton,
  renderRideTimesButton,
  renderRideTimesPdfButton,
  renderResultsButton,
  renderStablingButton,
  renderEntryStatusButton,
  renderProgramPdfButton,
  renderEnterOnlineButton,
  sjCoursesButton,
  xcCoursesButton,
} from '../../core/CalendarTools';

export default function CalendarTableRow({ event }) {
  const theme = useTheme(); // ✅ Get current MUI theme
  const DOC_TYPE_INFO = 7;

  function showDocs(event) {
    return event?.docs
      ?.filter((document) => document.type === DOC_TYPE_INFO)
      .map((filteredDoc) => (
        <Button
          variant="outlined"
          color="info"
          href={filteredDoc.amazons3URL}
          key={filteredDoc.id}
          sx={{ mx: 1 }}
        >
          {filteredDoc.title}
        </Button>
      ));
  }

  return (
    <TableRow hover sx={{
      bgcolor: theme.palette.background.default, // ✅ Ensures consistent background theme
      color: theme.palette.text.primary, // ✅ Ensures text contrasts well
    }}>
      <TableCell>
        <Typography variant="h3">
          {datesToText(event.startDate, event.endDate, event.oneDay)}
        </Typography>
      </TableCell>
      {/* ✅ Image on the Left, Title & Buttons on Right */}
      <TableCell>
        <Box display="flex" alignItems="center">
          {/* ✅ Event Image on Left */}
          <Box
            component="img"
            src={eventImage(event)}
            sx={{ width: 120, height: 80, borderRadius: 1, mr: 2 }}
          />

          {/* ✅ Title and Buttons on Right */}
          <Box>
            <Typography variant="h3">{event.title}</Typography>

            {/* ✅ Buttons Below Title */}
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              {sjCoursesButton(event)}
              {xcCoursesButton(event)}
            </Stack>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="h4">{event.description}</Typography>
        <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
          {renderOmnibusButton(event)}
          {renderResultsButton(event)}
          {renderEntryFormButton(event)}
          {renderRideTimesButton(event)}
          {renderRideTimesPdfButton(event)}
          {renderStablingButton(event)}
          {renderEntryStatusButton(event)}
          {renderEntryStatusPdfButton(event)}
          {renderProgramPdfButton(event)}
          {renderEnterOnlineButton(event)}
          {showDocs(event)}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
