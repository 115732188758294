import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTests,
  addTest,
  updateTest,
  deleteTest,
} from '../../../../redux/slices/events/tests.slice';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogActions,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const divisionTypeOptionsList = [
  { key: 'None', value: 0 },
  { key: 'Horse Trials', value: 1 },
  { key: 'Combined Test', value: 2 },
  { key: 'Dressage Only', value: 3 },
  { key: 'Clinic', value: 4 },
  { key: 'In Hand', value: 5 },
  { key: 'Other', value: 6 },
];

const testTypeOptionsList = [
  { key: 'None', value: 0 },
  { key: 'Dressage', value: 1 },
  { key: 'Stadium', value: 2 },
  { key: 'XC', value: 3 },
  { key: 'Jumping', value: 4 },
  { key: 'In Hand', value: 5 },
  { key: 'XC Schooling', value: 6 },
  { key: 'Other', value: 7 },
];

const eventLevels = [
  { id: 0, name: 'N/A', type: 0 },
  { id: 1, name: 'Advanced', type: 1 },
  { id: 2, name: 'Intermediate', type: 1 },
  { id: 3, name: 'Preliminary', type: 1 },
  { id: 4, name: 'Training', type: 1 },
  { id: 5, name: 'Modified', type: 1 },
  { id: 6, name: 'Novice', type: 1 },
  { id: 7, name: 'Beginner Novice', type: 1 },
  { id: 8, name: 'Starter', type: 1 },
  { id: 9, name: 'Prestarter', type: 1 },
  { id: 10, name: 'None', type: 0 },
];

const TestAdmin = () => {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.tests.data);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [newTest, setNewTest] = useState({
    name: '',
    testType: '0',
    eventLevelId: 0,
    minutes: 0,
  });

  useEffect(() => {
    dispatch(fetchTests());
  }, [dispatch]);

  const handleEdit = (test) => {
    setSelectedTest(test);
    setEditDialogOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteTest(id));
  };

  const handleEditSubmit = () => {
    dispatch(updateTest(selectedTest));
    setEditDialogOpen(false);
  };

  const handleAddSubmit = () => {
    dispatch(addTest(newTest));
    setNewTest({ name: '', testType: '0', eventLevelId: 0, minutes: 0 });
    setAddDialogOpen(false);
  };

  return (
    <div>
      <h2>Manage Tests</h2>

      {/* Table of Tests */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Test Type</TableCell>
              <TableCell>Event Level</TableCell>
              <TableCell>Division Type</TableCell>
              <TableCell>Minutes</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests.map((test) => (
              <TableRow key={test.id}>
                <TableCell>{test.name}</TableCell>
                <TableCell>
                  {testTypeOptionsList.find((option) => option.value === test.testType)
                    ?.key || 'Unknown'}
                </TableCell>
                <TableCell>
                  {eventLevels.find((level) => level.id === test.eventLevelId)
                    ?.name || 'Unknown'}
                </TableCell>
                <TableCell>
                  {divisionTypeOptionsList.find((option) => option.value === test.divisionType)
                    ?.key || 'Unknown'}
                </TableCell>
                <TableCell>{test.minutes}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(test)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(test.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add Test Button */}
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={() => setAddDialogOpen(true)}
      >
        Add Test
      </Button>

      {/* Edit Test Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edit Test</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            value={selectedTest?.name || ''}
            onChange={(e) =>
              setSelectedTest({ ...selectedTest, name: e.target.value })
            }
            style={{ marginBottom: '16px' }}
          />

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel>Test Type</InputLabel>
            <Select
              value={selectedTest?.testType || '0'}
              onChange={(e) =>
                setSelectedTest({ ...selectedTest, testType: e.target.value })
              }
            >
              {testTypeOptionsList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel>Event Level</InputLabel>
            <Select
              value={selectedTest?.eventLevelId || 0}
              onChange={(e) =>
                setSelectedTest({
                  ...selectedTest,
                  eventLevelId: parseInt(e.target.value, 10),
                })
              }
            >
              {eventLevels.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Minutes"
            type="number"
            fullWidth
            value={selectedTest?.minutes || 0}
            onChange={(e) =>
              setSelectedTest({
                ...selectedTest,
                minutes: parseInt(e.target.value, 10),
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleEditSubmit}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Test Dialog */}
      <Dialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)}>
        <DialogTitle>Add Test</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            value={newTest.name}
            onChange={(e) => setNewTest({ ...newTest, name: e.target.value })}
            style={{ marginBottom: '16px' }}
          />

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel>Test Type</InputLabel>
            <Select
              value={newTest.testType}
              onChange={(e) => setNewTest({ ...newTest, testType: e.target.value })}
            >
              {testTypeOptionsList.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.key}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginBottom: '16px' }}>
            <InputLabel>Event Level</InputLabel>
            <Select
              value={newTest.eventLevelId}
              onChange={(e) =>
                setNewTest({
                  ...newTest,
                  eventLevelId: parseInt(e.target.value, 10),
                })
              }
            >
              {eventLevels.map((level) => (
                <MenuItem key={level.id} value={level.id}>
                  {level.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Minutes"
            type="number"
            fullWidth
            value={newTest.minutes}
            onChange={(e) =>
              setNewTest({ ...newTest, minutes: parseInt(e.target.value, 10) })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddSubmit} variant="contained" color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TestAdmin;
