import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompetitions } from '../../../redux/slices/competitions/competitions.slice';
import EventsTable from './core/EventsTable';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import {
  Button,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { PlusCircle, ListCheck } from 'react-bootstrap-icons';

export default function EventsPage() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showAll, setShowAll] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchCompetitions());
  }, [dispatch]);

  const events = useSelector((state) => state.competitions.list);

  const handleDisplayChange = () => {
    setShowAll((prev) => !prev);
  };

  const filteredEvents = showAll ? events : events.filter((e) => !e.dataStatus);

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 3, bgcolor: '#000', color: '#fff', minHeight: '100vh' }}
    >
      <Helmet title="Events" />

      {/* Control Panel */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          mb: 3,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          borderRadius: 2,
        }}
      >
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Event Controls
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Button
            component={Link}
            to="/events/new"
            variant="contained"
            color="secondary"
            startIcon={<PlusCircle />}
          >
            New Event
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleDisplayChange}
            startIcon={<ListCheck />}
          >
            {showAll ? 'Hide Some Events' : 'Show All Events'}
          </Button>
        </Stack>
      </Paper>

      {/* Events Table */}
      <Paper
        elevation={3}
        sx={{
          p: 3,
          bgcolor: theme.palette.background.default, // ✅ Ensures consistent background theme
          color: theme.palette.text.primary, // ✅ Ensures text contrasts well
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          gutterBottom
          sx={{
            p: 3,
            mb: 3,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderRadius: 2,
          }}
        >
          Events
        </Typography>
        <EventsTable events={filteredEvents} />
      </Paper>
    </Container>
  );
}
