import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCalendarEvent } from '../../../redux/slices/competitions/competitions.slice';
import XCCourseInfoTableRow from './XCCourseInfoTableRow';
import HelmetTracker from '../../core/HelmetTracker';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from '@emotion/styled';

export default function XCCourseInfoTable() {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(fetchCalendarEvent(id));
    }
  }, [dispatch, id]);

  const eventSelected = useSelector(
    (state) =>
      state.competitions.calendar.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function tableEvents(eventSelected) {
    var i = 0;
    if (eventSelected) {
      return eventSelected.xcCourses.map((xcCourse) => {
        i++;
        return <XCCourseInfoTableRow key={i} xcCourse={xcCourse} />;
      });
    }
  }

  const objId = id && Number.isInteger(id) ? id : null;
  const TableWrapper = styled.div`
    overflow-y: auto;
    max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
  `;

  return (
    <>
      <HelmetTracker title="XC Info" objId={objId} />
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Level (Map)</TableCell>
              <TableCell>Meters</TableCell>
              <TableCell>Opt Speed</TableCell>
              <TableCell>Opt Time</TableCell>
              <TableCell>Fault Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableEvents(eventSelected)}</TableBody>
        </Table>
      </TableWrapper>
    </>
  );
}
