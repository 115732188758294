import React from 'react';
import HorseDocumentCardMUI from './HorseDocumentCardMUI';
import { Box, Grid } from '@mui/material';

export default function HorseDocumentGroupMUI({ horse }) {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {horse?.documents?.map((horseDocument) => (
          <Grid item key={horseDocument.id} xs={12} sm={6} md={4}>
            <HorseDocumentCardMUI horseDocument={horseDocument} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
