import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addVideoToHorse,
} from '../../../../../redux/slices/horses/horses.slice';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from '@mui/material';
import HorseVideosGroupMUI from './HorseVideosGroupMUI';

export default function HorseVideoManagementPanelMUI({ horse }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [adding, setAdding] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [videoURL, setVideoURL] = useState('');

  const handleSubmitVideo = (e) => {
    e.preventDefault();
    if (!horse?.id) return;

    dispatch(
      addVideoToHorse({
        horseId: horse.id,
        title: videoTitle,
        description: videoDescription,
        URL: videoURL,
        userId: auth.id,
      })
    );

    setVideoTitle('');
    setVideoDescription('');
    setVideoURL('');
    setAdding(false);
  };

  return (
    <Card elevation={3}>
      <CardHeader
        title="Videos"
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdding(!adding)}
          >
            Add Video
          </Button>
        }
      />
      <CardContent>
        {adding && (
          <Box
            component="form"
            onSubmit={handleSubmitVideo}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <TextField
              label="Video Title"
              value={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
              fullWidth
            />
            <TextField
              label="Video Description"
              value={videoDescription}
              onChange={(e) => setVideoDescription(e.target.value)}
              fullWidth
            />
            <TextField
              label="Video URL"
              value={videoURL}
              onChange={(e) => setVideoURL(e.target.value)}
              fullWidth
            />
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
          </Box>
        )}
        <HorseVideosGroupMUI
          horse={horse}
        />
      </CardContent>
    </Card>
  );
}
