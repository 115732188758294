import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCalendarEvent } from '../../../redux/slices/competitions/competitions.slice';
import HelmetTracker from '../../core/HelmetTracker';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  useTheme,
} from '@mui/material';

export default function CalendarEntryStatusTable() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme(); // ✅ Get current MUI theme

  useEffect(() => {
    if (id) {
      dispatch(fetchCalendarEvent(id));
    }
  }, [dispatch, id]);

  const eventSelected = useSelector(
    (state) => state.competitions.calendar.find((m) => m.id === parseInt(id)),
    shallowEqual
  );

  const objId = id ? id : null;

  function renderRows() {
    if (!eventSelected || !eventSelected.entryStatusData) return null;

    return eventSelected.entryStatusData.map((entry, idx) => (
      <TableRow key={idx}>
        <TableCell>{entry.rider}</TableCell>
        <TableCell>{entry.horse}</TableCell>
        <TableCell>{entry.division}</TableCell>
        <TableCell>{entry.status}</TableCell>
        <TableCell>{entry.balanceDue}</TableCell>
        <TableCell>{entry.depositBalance}</TableCell>
        <TableCell>{entry.missingIds}</TableCell>
        <TableCell>{entry.incompleteForms}</TableCell>
        <TableCell>{entry.stabling}</TableCell>
        <TableCell>{entry.group}</TableCell>
      </TableRow>
    ));
  }

  return (
    <>
      <HelmetTracker title="Entry Status" objId={objId} />
      <Paper
        sx={{
          bgcolor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow: 3,
          borderRadius: 2,
          p: 2,
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
              <TableCell>Rider</TableCell>
              <TableCell>Horse</TableCell>
              <TableCell>Division</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Balance Due</TableCell>
              <TableCell>Deposit Balance</TableCell>
              <TableCell>Missing IDs</TableCell>
              <TableCell>Incomplete Forms</TableCell>
              <TableCell>Stabling</TableCell>
              <TableCell>Group</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </Table>
      </Paper>
    </>
  );
}
