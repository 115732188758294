import React, { useContext } from "react";
import { ThemeContext } from "../ThemeContext"; // Import ThemeContext
import { THEMES } from "../../components/constants";
import { MenuItem, Select } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const ThemeSelector = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const muiTheme = useTheme(); // Get current Material-UI theme

  // Map themes to colors based on `variants.js`
  const themeColors = {
    [THEMES.DARK_RED]: { bg: "#B71C1C", text: "#FFFFFF" },
    [THEMES.DARK]: { bg: "#1B2635", text: "#FFFFFF" },
    [THEMES.VIOLA]: { bg: "#000000", text: "#FFFFFF" },
    [THEMES.LIGHT]: { bg: "#F7F9FC", text: "#000000" },
    [THEMES.BLUE]: { bg: "#376fd0", text: "#FFFFFF" },
    [THEMES.GREEN]: { bg: "#2E7D32", text: "#FFFFFF" },
    [THEMES.INDIGO]: { bg: "#3F51B5", text: "#FFFFFF" },
  };

  return (
    <div style={{ padding: "3px", textAlign: "center" }}>
      <Select
        value={theme}
        onChange={(e) => setTheme(e.target.value)}
        style={{
          padding: "3px",
          backgroundColor: themeColors[theme]?.bg || muiTheme.palette.primary.main,
          color: themeColors[theme]?.text || muiTheme.palette.primary.contrastText,
          borderRadius: "5px",
        }}
      >
        {Object.keys(themeColors).map((themeKey) => (
          <MenuItem
            key={themeKey}
            value={themeKey}
            style={{
              backgroundColor: themeColors[themeKey].bg,
              color: themeColors[themeKey].text,
            }}
          >
            {themeKey.replace("_", " ")}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ThemeSelector;
