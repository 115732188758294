import axios from 'axios';
import {
  BARN_FETCHED,
  SET_BARNS,
  ADMIN_BARN_FETCHED,
  ADMIN_BARN_UPDATED,
  SET_ADMIN_BARNS,
  SET_BARN_USERS,
  BARN_USER_FETCHED,
} from '../types';
import { barnToClass, barnsToClassArray } from '../../classes/Barn.class';

export function barnFetched(barn) {
  return {
    type: BARN_FETCHED,
    barn: barnToClass(barn),
  };
}

export function setBarns(barns) {
  return {
    type: SET_BARNS,
    barns: barnsToClassArray(barns),
  };
}

export function barnUserFetched(barnUser) {
  return {
    type: BARN_USER_FETCHED,
    barnUser,
  };
}

export function setBarnUsers(barnUsers) {
  return {
    type: SET_BARN_USERS,
    barnUsers,
  };
}

export function adminBarnFetched(barn) {
  return {
    type: ADMIN_BARN_FETCHED,
    barn,
  };
}

export function adminBarnUpdated(barn) {
  return {
    type: ADMIN_BARN_UPDATED,
    barn,
  };
}

export function setAdminBarns(barns) {
  return {
    type: SET_ADMIN_BARNS,
    barns,
  };
}

export const createBarn = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/barns/create', values);
  dispatch(barnFetched(res.data));
};

export const fetchBarns = () => async (dispatch) => {
  const res = await axios.get('/api/barns/read');
  dispatch(setBarns(res.data));
};

export const fetchBarn = (id) => async (dispatch) => {
  const res = await axios.get('/api/barns/read/' + id);
  dispatch(barnFetched(res.data));
};

export const saveBarn = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/barns/create', values);
  dispatch(barnFetched(res.data));
};

export const updateBarn = (values) => async (dispatch) => {
  const res = await axios.post('/api/barns/update', values);
  dispatch(barnFetched(res.data));
};

export const fetchBarnUsers = () => async (dispatch) => {
  const res = await axios.get('/api/barnusers/read');
  dispatch(setBarnUsers(res.data));
};

export const addUserToBarn = (values) => async (dispatch) => {
  const res = await axios.post('/api/barnusers/create', values);
  dispatch(barnUserFetched(res.data));
};
