import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle, Edit } from 'react-feather';

// import { fetchXCCourses } from '../../../redux/actions/users/barn.actions';
// import { useDispatch, useSelector } from 'react-redux';
import XCCoursesForm from './XCCoursesForm';
import { levelChip } from '../../../core/levelsTools';

import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function XCCoursesPage({ eventData }) {
  const xcCourses =
    eventData && eventData.xcCoursesArray ? [...eventData.xcCoursesArray] : [];

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const handleClick = (event, id) => {
    if (selected !== id) {
      setSelected(id);
    }

    setCreating(false);
    setEditing(false);
  };

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);
    setCreating(false);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleAddCancelClick = (event) => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  // Single select
  const isSelected = (id) => selected === id;

  function mapBody() {
    if (xcCourses && xcCourses.length > 0) {
      const tableBody = xcCourses.map((xcCourse) => {
        const isItemSelected = isSelected(xcCourse.id);
        return (
          <Fragment key={xcCourse.id}>
            <TableRow
              key={xcCourse.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, xcCourse.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {xcCourse.id}
              </TableCell>
              <TableCell>{xcCourse.name}</TableCell>
              <TableCell>{levelChip(xcCourse.level)}</TableCell>
              <TableCell>{xcCourse.faultTime}</TableCell>
              <TableCell>{xcCourse.faultSpeed}</TableCell>
              <TableCell>{xcCourse.optimumTime}</TableCell>
              <TableCell>{xcCourse.optimumSpeed}</TableCell>
              <TableCell>{xcCourse.lengthMeters}</TableCell>
              <TableCell>
                <Stack>
                  <Button
                    aria-label="editXcCourse"
                    onClick={(event) => handleEditClick(event, xcCourse.id)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit XcCourse
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={4}
              >
                <Collapse
                  in={isItemSelected && editing}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 5 }}>
                    <XCCoursesForm
                      onOkClick={handleFormOkClick}
                      onCancelClick={handleFormCancelClick}
                      xcCourse={xcCourse}
                      eventData={eventData}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showAddXCCourseForm() {
    if (creating) {
      return (
        <XCCoursesForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
          eventData={eventData}
        />
      );
    }
  }

  const title = eventData
    ? 'XCCourses for ' + eventData.title
    : 'XCCourses for (Loading)';

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddCancelClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title={title}
      />
      {showAddXCCourseForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>faultTime</TableCell>
                <TableCell>faultSpeed</TableCell>
                <TableCell>optimumTime</TableCell>
                <TableCell>optimumSpeed</TableCell>
                <TableCell>lengthMeters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody()}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default XCCoursesPage;
