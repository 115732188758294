import React from 'react';
import {
  prelimMapButton,
  modifiedMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
  preStarterMapButton,
} from '../../core/MapTools';
import { Stack, TableCell, TableRow } from '@mui/material';

export default function SJCourseInfoTableRow(props) {
  function getCourseMapButton(levelIndex) {
    if (levelIndex === 0) return prelimMapButton();
    if (levelIndex === 1) return modifiedMapButton();
    if (levelIndex === 2) return trainingMapButton();
    if (levelIndex === 3) return noviceMapButton();
    if (levelIndex === 4) return begNoviceMapButton();
    if (levelIndex === 5) return starterMapButton();
    return preStarterMapButton();
  }

  return (
    <TableRow key={props.key}>
      <TableCell>
        <Stack spacing={2}>{getCourseMapButton(props.sjCourse.level)}</Stack>
      </TableCell>
      <TableCell>{props.sjCourse.lengthMeters}</TableCell>
      <TableCell>{props.sjCourse.optimumSpeed}</TableCell>
      <TableCell>{props.sjCourse.optimumTime.replaceAll('"', '')}</TableCell>
      <TableCell>{props.sjCourse.maximumTime.replaceAll('"', '')}</TableCell>
    </TableRow>
  );
}
