/* (( *** AUTO GEN *** )) */
/* xcCourseActions.js */
import axios from 'axios';
import { SET_XC_COURSES, XC_COURSE_FETCHED } from '../../types';
import { fetchEvent } from '../event.actions';

export function xcCourseFetched(xcCourse) {
  return {
    type: XC_COURSE_FETCHED,
    xcCourse,
  };
}

export function setXcCourses(xcCourses) {
  return {
    type: SET_XC_COURSES,
    xcCourses,
  };
}

export const fetchXcCourses = () => async (dispatch) => {
  const res = await axios.get('/api/xccourses/');
  dispatch(xcCourseFetched(res.data));
};
export const fetchXcCourse = (id) => async (dispatch) => {
  const res = await axios.get('/api/xccourses/' + id);
  dispatch(setXcCourses(res.data));
};

// export const fetchXcCoursesByEventId = (eventId) => async (dispatch) => {
//   const res = await axios.get('/api/xccourses/read/eventId/' + eventId);
//   dispatch(setXcCourses(res.data));
// };

export const createXcCourse = (values) => async (dispatch) => {
  await axios.post('/api/xccourses/', values);
  dispatch(fetchEvent(values.eventId));
};

export const updateXcCourse = (values) => async (dispatch) => {
  await axios.put('/api/xccourses/' + values.id, values);
  dispatch(fetchEvent(values.eventId));
};

export const deleteXcCourse = (values) => async (dispatch) => {
  await axios.delete('/api/xccourses/' + values.id, values);
  dispatch(fetchEvent(values.eventId));
};
