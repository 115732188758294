import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// **Async Thunks**
export const fetchEventDivisions = createAsyncThunk(
  'eventDivisions/fetchAll',
  async () => {
    const response = await axios.get('/api/eventDivisions');
    return response.data;
  }
);

export const fetchEventDivisionsByEventId = createAsyncThunk(
  'eventDivisions/fetchByEventId',
  async (eventId) => {
    const response = await axios.get(`/api/eventDivisions/event/${eventId}`);
    return { eventId, divisions: response.data };
  }
);

export const addEventDivision = createAsyncThunk(
  'eventDivisions/add',
  async (division) => {
    const response = await axios.post('/api/eventDivisions', division);
    return response.data;
  }
);

export const updateEventDivision = createAsyncThunk(
  'eventDivisions/update',
  async (division) => {
    const response = await axios.put(
      `/api/eventDivisions/${division.id}`,
      division
    );
    return response.data;
  }
);

export const deleteEventDivision = createAsyncThunk(
  'eventDivisions/delete',
  async (id) => {
    await axios.delete(`/api/eventDivisions/${id}`);
    return id;
  }
);

export const createEventDivisionFromDivisionObject = createAsyncThunk(
  'eventDivisions/createFromDivisionObject',
  async ({ eventId, division }) => {
    console.log('eventId2', eventId);
    console.log('division2', division);
    const response = await axios.post('/api/eventDivisions/fromDivision', {
      eventId,
      division,
    });
    return response.data;
  }
);

// **Slice**
const eventDivisionsSlice = createSlice({
  name: 'eventDivisions',
  initialState: { data: [], eventData: {}, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEventDivisions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventDivisions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchEventDivisions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchEventDivisionsByEventId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEventDivisionsByEventId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.eventData[action.payload.eventId] = action.payload.divisions;
      })
      .addCase(fetchEventDivisionsByEventId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addEventDivision.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(updateEventDivision.fulfilled, (state, action) => {
        const updatedDivision = action.payload;

        // ✅ Update in `state.data`
        const index = state.data.findIndex(
          (div) => div.id === updatedDivision.id
        );
        if (index !== -1) state.data[index] = updatedDivision;

        // ✅ Update in `state.eventData`
        Object.keys(state.eventData).forEach((eventId) => {
          const eventDivisions = state.eventData[eventId];
          const divisionIndex = eventDivisions.findIndex(
            (div) => div.id === updatedDivision.id
          );

          if (divisionIndex !== -1) {
            state.eventData[eventId][divisionIndex] = updatedDivision;
          }
        });
      })
      .addCase(deleteEventDivision.fulfilled, (state, action) => {
        state.data = state.data.filter((div) => div.id !== action.payload);
      })
      .addCase(
        createEventDivisionFromDivisionObject.fulfilled,
        (state, action) => {
          const newEventDivision = action.payload;
          state.data.push(newEventDivision);

          // ✅ Update eventData if event exists in state
          if (state.eventData[newEventDivision.eventId]) {
            state.eventData[newEventDivision.eventId].push(newEventDivision);
          }
        }
      );
  },
});

export default eventDivisionsSlice.reducer;
