import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle, Edit } from 'react-feather';

import { fetchBarns } from '../../../redux/actions/users/barn.actions';
import { useDispatch, useSelector } from 'react-redux';
import BarnsForm from './core/BarnsForm';
import HorsesTable from '../../users/core/HorsesTable';
import HelmetTracker from '../../core/HelmetTracker';
import BarnUsersEditor from '../../admin/core/BarnUsersEditor';

import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';
import _ from 'lodash';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function BarnsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBarns());
  }, [dispatch]);

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [showHorses, setShowHorses] = React.useState(false);

  const auth = useSelector((state) => state.auth);
  const barns = useSelector((state) => state.barns);
  const selectedBarn = useSelector((state) =>
    state.barns.find((x) => x.id === selected)
  );
  const horses = useSelector((state) => state.horses);
  const selectedHorses = useSelector((state) =>
    state.horses.filter(
      (x) => selectedBarn && x.barnId === selectedBarn.id && x.dataStatus < 3
    )
  );

  function horseCountChip(count) {
    return <Chip label={count} color="info" onClick={handleShowHorsesClick} />;
  }

  function barnOwnerChip(name, userId) {
    if (auth && auth.id === userId) {
      return <Chip label={name} color="info" />;
    } else {
      return <Chip label={name} color="error" />;
    }
  }

  const handleClick = (event, id) => {
    if (selected !== id) {
      setSelected(id);
      if (!showHorses) {
        setShowHorses(true);
      }
    } else {
      setShowHorses(!showHorses);
    }

    setCreating(false);
    setEditing(false);
  };

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);
    setCreating(false);
    setShowHorses(false);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleShowHorsesClick = (event, id) => {
    setEditing(false);
    setCreating(false);
    setShowHorses(!showHorses);
    // event.stopPropagation();  /// commenting this sets id
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleAddCancelClick = (event) => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  function horsesInBarn(barnId) {
    if (horses) {
      var barnHorses = _.filter(horses, function (o) {
        return o.barnId === barnId && o.dataStatus < 3;
      });
      return barnHorses.length;
    } else {
      return 0;
    }
  }

  // For multi row select
  //   const isSelected = (id) => selected.indexOf(id) !== -1;

  // Single select
  const isSelected = (id) => selected === id;

  function mapBody(barns) {
    if (barns && barns.length > 0) {
      const tableBody = barns.map((barn) => {
        const isItemSelected = isSelected(barn.id);
        return (
          <Fragment key={barn.id}>
            <TableRow
              key={barn.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, barn.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {barn.id}
              </TableCell>
              <TableCell>
                {barnOwnerChip(barn.barnOwnerName, barn.userId)}
              </TableCell>
              <TableCell>{barn.name}</TableCell>
              <TableCell>{horseCountChip(horsesInBarn(barn.id))}</TableCell>

              <TableCell>
                <Stack>
                  <Button
                    aria-label="editBarn"
                    onClick={(event) => handleEditClick(event, barn.id)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit Barn
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={4}
              >
                <Collapse
                  in={isItemSelected && showHorses}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 5 }}>
                    {selectedHorses && selectedHorses.length > 0 ? (
                      <HorsesTable horses={selectedHorses} barnId={barn.id} />
                    ) : (
                      <HorsesTable barnId={barn.id} />
                    )}
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={4}
              >
                <Collapse
                  in={isItemSelected && editing}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 5 }}>
                    <BarnsForm
                      onOkClick={handleFormOkClick}
                      onCancelClick={handleFormCancelClick}
                      barn={barn}
                      auth={auth}
                    />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showBarnsForm() {
    if (creating) {
      return (
        <BarnsForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
          auth={auth}
        />
      );
    }
  }

  const title = auth
    ? 'Barns for ' + auth.displayPerson()
    : 'Barns for (Loading)';

  return (
    <Card mb={6}>
      <HelmetTracker title="Barns" />
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddCancelClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title={title}
      />
      {showBarnsForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Barn Id</TableCell>
                <TableCell>Barn Owner</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Horses</TableCell>
                <TableCell>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(barns)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      <BarnUsersEditor/>
    </Card>
  );
}

export default BarnsPage;
