import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCalendar } from '../../../redux/slices/competitions/competitions.slice';
import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import CalendarTableRow from './CalendarTableRow';
import { useTheme } from '@mui/material/styles';

export default function CalendarTable({ showPrior }) {
  const [dateOrder, setDateOrder] = useState(true);
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(fetchCalendar());
  }, [dispatch]);

  const calendar = useSelector((state) => state.competitions.calendar);

  function changeDateSort() {
    if (dateOrder != null) {
      setDateOrder(!dateOrder);
    }
  }

  function dateOrderToText(order) {
    return order ? 'Sort Old to New' : 'Sort New to Old';
  }

  function tableEvents() {
    let index = 0;
    let date = new Date();
    date.setDate(date.getDate() - 7);

    return _.orderBy(calendar, ['startDate'], [dateOrder ? 'asc' : 'desc']).map(
      (event) => {
        let newDate = new Date(event.startDate);
        if (showPrior ? newDate < date : newDate > date) {
          return <CalendarTableRow event={event} key={index++} />;
        }
        return null;
      }
    );
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: 3, borderRadius: 2, mt: 3 }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ bgcolor: theme.palette.primary.main }}>
            <TableCell
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: '1.5rem',
                fontWeight: 'bold',
                borderRight: '2px solid lightgray', // ✅ Adds column separator
              }}
            >
              Date
              <Button
                variant="contained"
                color="secondary"
                onClick={() => changeDateSort()}
                sx={{ ml: 2 }}
              >
                {dateOrderToText(dateOrder)}
              </Button>
            </TableCell>
            <TableCell
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: '1.5rem',
                fontWeight: 'bold',
                borderRight: '2px solid lightgray', // ✅ Adds column separator
              }}
            >
              Description
            </TableCell>
            <TableCell
              sx={{
                color: theme.palette.primary.contrastText,
                fontSize: '1.5rem',
                fontWeight: 'bold',
              }}
            >
              Info
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{tableEvents()}</TableBody>
      </Table>
    </TableContainer>
  );
}
