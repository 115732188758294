import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

const AddTestToDivisionDialog = ({
  open,
  onClose,
  onSubmit,
  division,
  tests,
}) => {
  const [selectedTestId, setSelectedTestId] = useState('');

  useEffect(() => {
    setSelectedTestId('');
  }, [open]);

  // Filter tests by eventLevelId and exclude already assigned tests
  const availableTests = tests.filter(
    (test) =>
      test.eventLevelId === division.eventLevelId &&
      !(
        division.divisionTests?.some(
          (assignedTest) => assignedTest.testId === test.id
        ) || false
      )
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Test to {division.name}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Select Test</InputLabel>
          <Select
            value={selectedTestId}
            onChange={(e) => setSelectedTestId(e.target.value)}
          >
            {availableTests.map((test) => (
              <MenuItem key={test.id} value={test.id}>
                {test.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSubmit(selectedTestId)}
          variant="contained"
          color="primary"
          disabled={!selectedTestId}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTestToDivisionDialog;
