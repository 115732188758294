import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancelButtons,
  renderFormTitle,
  finalTextField,
  finalTextArea,
  finalDateField,
} from '../../../../core/FinalCoreForms';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function MediaInnerForm(props) {
  const initialValues = {
    id: props.media && props.media.id ? props.media.id : null,
    content: props.media && props.media.content ? props.media.content : '',
    type: props.media && props.media.type ? props.media.type : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.media && props.submitMedia) {
      Object.assign(props.media, values);
      props.submitMedia(props.media);
    } else {
      let newData = {};
      Object.assign(newData, values);
      props.submitMedia(newData);
    }
  };

  function navigateBackPath() {
    if (props.media && props.media.id)
      return '/medias/manage/' + props.media.id;
    else
      return '/medias';
  }


  return (
    <div>
      {renderFormTitle(props.media, 'medias')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {okCancelButtons(navigateBackPath())}

            {finalTextField('userId', 'User Id')}
            {finalTextArea('content', 'Content')}
            {finalTextField('type', 'Type')}
            {finalTextField('dataStatus', 'Data Status')}
            {finalDateField('createdAt', 'Created At')}
            {finalDateField('updatedAt', 'Updated At')}

            {okCancelButtons('/medias')}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
}