import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { dateToTimeNoUtc } from '../core/DateTools';

export default function MinuteBox({ minuteId, time, children }) {
  const [contextMenu, setContextMenu] = React.useState(null);

  const height = children ? children.props.durationMinutes * 15 + 'px' : '15px';

  let currentTime = new Date(time);

  const fill = 'white';
  const stroke = 'black';

  const defaultFill = minuteId + ' : ' + dateToTimeNoUtc(currentTime);

  const fillInside = children ? children : defaultFill;

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <div
      style={{
        backgroundColor: fill,
        color: stroke,
        border: '1px solid black',
        height: height,
        fontSize: '0.650em',
      }}
      onContextMenu={handleContextMenu}
    >
      {fillInside}
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleClose}>Copy</MenuItem>
        <MenuItem onClick={handleClose}>Print</MenuItem>
        <MenuItem onClick={handleClose}>Highlight</MenuItem>
        <MenuItem onClick={handleClose}>Email</MenuItem>
        <MenuItem onClick={handleClose}>{defaultFill}</MenuItem>
      </Menu>
    </div>
  );
}
