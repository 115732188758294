import React from 'react';
import { useDispatch } from 'react-redux';

import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';

import {
  addUserPermission,
  removeUserPermission,
} from '../../../redux/actions/admin/search.actions';

import Switch from '@mui/material/Switch';

import { red, green } from '@mui/material/colors';

function SummarizedPermission({
  permission,
  onClick,
  selected,
  userPermission,
  userId,
}) {
  const dispatch = useDispatch();
  function getAvatarColor() {
    if (userPermission) {
      return { bgcolor: green[500] };
    }
    return { bgcolor: red[500] };
  }

  function getPermissionNotes() {
    if (permission && permission.notes) {
      return permission.notes;
    }
    return '';
  }

  const handleToggle = () => {
    if (userPermission) {
      dispatch(removeUserPermission(userId, userPermission.userPermissionId));
    } else {
      dispatch(addUserPermission(userId, permission.id));
    }
  };

  return (
    <ListItemButton onClick={onClick} selected={selected}>
      <ListItemIcon>
        <Avatar sx={getAvatarColor()}>{permission.id}</Avatar>
      </ListItemIcon>
      <ListItemText
        primary={permission.permission}
        secondary={getPermissionNotes()}
      />
      <Switch
        edge="end"
        onChange={() => handleToggle()}
        checked={userPermission != null}
        inputProps={{
          'aria-labelledby': 'switch-list-label-wifi',
        }}
      />
    </ListItemButton>
  );
}

export default SummarizedPermission;
