import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from '../Constants';
import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/ArrowCircleDown';
import SubtractIcon from '@mui/icons-material/ArrowCircleUp';
import { dateToTime } from '../../core/DateTools';

export default function DressageBox({
  entry,
  ringId,
  durationMinutes,
  onChangeTime,
  onClick,
}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.DRESSAGE_ENTRY,
    item: {
      entry: entry,
      ringId: ringId,
      duration: durationMinutes,
      type: ItemTypes.DRESSAGE_ENTRY,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [drop] = useDrop(
    () => ({
      accept: [
        ItemTypes.SCHEDULE_BOX,
        ItemTypes.ENTRY_LIST,
        ItemTypes.DRESSAGE_ENTRY,
      ],
      drop: (item) =>
        moveScheduleBox(item, entry.rideTimes.dressage.time, ringId),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [entry]
  );

  function moveScheduleBox(item, toTime, ringId) {
    console.log('DROPPED');
    // if (item && item.type === ItemTypes.ENTRY_LIST) {
    //   setDressageTimes(item, toTime, ringId);
    // } else if (item && item.type === ItemTypes.DRESSAGE_ENTRY) {
    //   setDressageTime(item, toTime, ringId);
    //   onEntryTimeChanged(item.entry);
    // }
  }

  function getHeight() {
    return durationMinutes * 15;
  }

  function getBGColor() {
    if (entry.eventDivisionProperties && entry.eventDivisionProperties.bgColor) {
      return entry.eventDivisionProperties.bgColor;
    } else {
      return '#ff0000';
    }
  }

  function fontColor() {
    if (entry.eventDivisionProperties && entry.eventDivisionProperties.fontColor) {
      return entry.eventDivisionProperties.fontColor;
    } else {
      return '#000000';
    }
  }

  function getWidth() {
    if (entry.selected) {
      return '178px';
    } else {
      return '100%';
      // return '197px';
    }
  }

  const style = {
    backgroundColor: getBGColor(),
    color: fontColor(),
    height: getHeight() + 'px',
    width: getWidth(),
    opacity: isDragging ? 0.95 : 1,
    fontSize: 10,
    fontWeight: 'bold',
    cursor: 'move',
    borderRadius: '8px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
    padding: '5px',
  };

  const typeArray = ['Dressage', 'Show Jumping', 'XC'];

  function tipText(entry) {
    return (
      'Time: ' +
      dateToTime(entry.rideTimes.dressage.time) +
      '\r\n' +
      'Rider: ' +
      entry.riderName +
      '\r\n' +
      'Horse: ' +
      entry.horseName +
      '\r\n' +
      'Type: ' +
      typeArray[entry.rideTimes.dressage.testType] +
      '\r\n' +
      'Division: ' +
      entry.eventDivisionName +
      '\r\n' +
      'Minutes: ' +
      entry.rideTimes.dressage.minutes +
      '\r\n' +
      'Test: ' +
      entry.rideTimes.dressage.testName
    );
  }

  function handleDeleteClick() {
    delete entry.rideTimes.dressage;
    onChangeTime(entry);
  }

  function handleOnClick() {
    // delete entry.rideTimes.dressage;
    onClick(entry);
  }

  function handleAddClick() {
    let newDate = new Date(entry.rideTimes.dressage.time);
    newDate = newDate.getTime() + 60000;
    onChangeTime(entry, true, newDate);
  }

  function handleSubtractClick() {
    let newDate = new Date(entry.rideTimes.dressage.time);
    newDate = newDate.getTime() - 60000;
    onChangeTime(entry, true, newDate);
  }

  return (
    <Tooltip
      title={
        <span style={{ whiteSpace: 'pre-line', opacity: 1 }}>
          {tipText(entry)}
        </span>
      }
      placement="left"
      enterDelay={500}
      leaveDelay={200}
      arrow
    >
      <div ref={drop}>
        <div ref={drag} style={style} onClick={handleOnClick}>
          Rider: {entry.riderName} ({entry.id})<br></br>
          Horse: {entry.horseName}
          <br></br>
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
          <IconButton aria-label="add" color="primary" onClick={handleAddClick}>
            <AddIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="subtract"
            color="primary"
            onClick={handleSubtractClick}
          >
            <SubtractIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </Tooltip>
  );
}
