import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchDivisions,
  addDivision,
  updateDivision,
  deleteDivision,
} from '../../../../redux/slices/events/divisions.slice';
import {
  fetchTests,
  // addTest,
  // updateTest,
  // deleteTest,
} from '../../../../redux/slices/events/tests.slice';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from '@mui/material';
import AddDivisionPanel from './components/AddDivisionPanel';
import EditDivisionPanel from './components/EditDivisionPanel';
import DivisionTableRow from './components/DivisionTableRow';
// import { fetchEvents } from '../../../../redux/actions/Events/event.actions';
// import { createEventDivisionFromDivisionObject } from '../../../../redux/slices/events/eventDivisions.slice';
import EventSelect from './components/EventSelect'; // Create this component for event selection

const DivisionAdmin = () => {
  const [selectedEventId, setSelectedEventId] = useState('');

  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.divisions.data);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [newDivision, setNewDivision] = useState({
    name: '',
    divisionType: '0',
    eventLevelId: 0,
  });

  useEffect(() => {
    dispatch(fetchDivisions());
    dispatch(fetchTests());
    // dispatch(fetchEvents());
  }, [dispatch]);

  const handleEdit = (division) => {
    setSelectedDivision(division);
    setEditDialogOpen(true);
  };

  const handleDelete = (id) => {
    dispatch(deleteDivision(id));
  };

  const handleEditSubmit = () => {
    dispatch(updateDivision(selectedDivision));
    setEditDialogOpen(false);
  };

  const handleAddSubmit = () => {
    dispatch(addDivision(newDivision));
    setNewDivision({ name: '', divisionType: '0', eventLevelId: 0 });
    setAddDialogOpen(false);
  };

  // const handleAddToEventSubmit = (divisionObject) => {
  //   if (!selectedEventId) {
  //     alert('Please select an event before adding a division.');
  //     return;
  //   }

  //   dispatch(
  //     createEventDivisionFromDivisionObject({
  //       eventId: selectedEventId,
  //       divisionObject,
  //     })
  //   );
  // };

  return (
    <div>
      <h2>Manage Divisions</h2>
      <EventSelect value={selectedEventId} onChange={(e) => setSelectedEventId(e.target.value)} />

      <Button
        variant="contained"
        color="primary"
        onClick={() => setAddDialogOpen(true)}
      >
        Add Division
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Division Type</TableCell>
              <TableCell>Event Level</TableCell>
              <TableCell>Tests</TableCell>
              <TableCell>Properties</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {divisions.map((division) => (
              <DivisionTableRow
                key={division.id}
                division={division}
                onEdit={handleEdit}
                onDelete={handleDelete}
                eventId={selectedEventId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddDivisionPanel
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        onSubmit={handleAddSubmit}
        newDivision={newDivision}
        setNewDivision={setNewDivision}
      />
      <EditDivisionPanel
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSubmit={handleEditSubmit}
        selectedDivision={selectedDivision}
        setSelectedDivision={setSelectedDivision}
      />
    </div>
  );
};

export default DivisionAdmin;
