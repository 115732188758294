// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import sentryMiddleware from './sentryMiddleware';

import authReducer from './reducers/auth.reducer';
import barnsReducer from './reducers/barns/barns.reducer';
import barnUsersReducer from './reducers/barns/barnUsers.reducer';
// import calendarReducer from './reducers/calendar.reducer';
import communityInvitesReducer from './reducers/community/communityInvites.reducer';
import coInvitesToMeReducer from './reducers/community/coInvitesToMe.reducer';
import communityUsersReducer from './reducers/community/communityUsers.reducer';
import eventsReducer from './reducers/events/events.reducer';
import eventLevelsReducer from './reducers/events/eventLevels.reducer';
// import horsesReducer from './reducers/horses/horses.reducer';
import jumpsReducer from './reducers/jumps.reducer';
import mediasReducer from './reducers/medias.reducer';
// import mediaFeedReducer from './reducers/mediaFeed.reducer';
// import saleHorsesReducer from './reducers/saleHorses.reducer';
import usersSearchReducer from './reducers/usersSearch.reducer';

import scheduleBlocksReducer from './reducers/scheduler/scheduleBlocks.reducer';
import ringsReducer from './reducers/scheduler/rings.reducer';
// import divisionsReducer from './reducers/events/divisions.reducer';
import entriesReducer from './reducers/events/entries.reducer';
// import eventDivisionsReducer from './reducers/events/eventDivisions.reducer';
import eventHorsesReducer from './reducers/events/eventHorses.reducer';
import eventTestsReducer from './reducers/events/eventTests.reducer';
import eventSelectablesReducer from './reducers/events/eventSelectables.reducer';
import peopleReducer from './reducers/events/people.reducer';
import userEntriesReducer from './reducers/events/userEntries.reducer';

import taskReducer from './reducers/tasks/task.reducer';

// STORE
import productsReducer from './reducers/store/products.reducer';
import productCategoriesReducer from './reducers/store/productCategories.reducer';
import productItemsReducer from './reducers/store/productItems.reducer';

// ADMIN
import permissionsReducer from './reducers/admin/permissions.reducer';
import searchedUsersReducer from './reducers/admin/searchedUsers.reducer';

// OCULUS
import reportingReducer from './reducers/oculus/reporting.reducer';

// SHOPPING
import cartReducer from './slices/cartSlice';

// New Event Entry System
import divisionsReducer from './slices/events/divisions.slice';
import eventDivisionsReducer from './slices/events/eventDivisions.slice';
import testsReducer from './slices/events/tests.slice';

// DDD Slices
import competitionsReducer from './slices/competitions/competitions.slice';
import horsesReducer from './slices/horses/horses.slice';
import mediaReducer from './slices/media/media.slice';
import postsReducer from './slices/posts/posts.slice';

const reducer = {
  auth: authReducer,
  horses: horsesReducer,
  posts: postsReducer,
  returnedUsers: searchedUsersReducer,
  reporting: reportingReducer,
  cart: cartReducer,
  barns: barnsReducer,
  competitions: competitionsReducer,
  barnUsers: barnUsersReducer,
  // calendar: calendarReducer,
  communityInvites: communityInvitesReducer,
  communityInvitesToMe: coInvitesToMeReducer,
  communityUsers: communityUsersReducer,
  divisions: divisionsReducer,
  tests: testsReducer,
  entries: entriesReducer,
  eventDivisions: eventDivisionsReducer,
  eventHorses: eventHorsesReducer,
  events: eventsReducer,
  eventSelectables: eventSelectablesReducer,
  eventLevels: eventLevelsReducer,
  eventTests: eventTestsReducer,
  jumps: jumpsReducer,
  medias: mediasReducer,
  media: mediaReducer,
  people: peopleReducer,
  permissions: permissionsReducer,
  productCategories: productCategoriesReducer,
  productItems: productItemsReducer,
  products: productsReducer,
  rings: ringsReducer,
  // saleshorses: saleHorsesReducer,
  scheduleBlocks: scheduleBlocksReducer,
  searchedUsers: usersSearchReducer,
  tasks: taskReducer,
  userEntries: userEntriesReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryMiddleware);
  },
  devTools: process.env.NODE_ENV !== 'amazonProduction',
});
