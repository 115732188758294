import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from '@mui/material';
import moment from 'moment';

export default function HorseDocumentCardMUI({ horseDocument }) {
  const getUploadDate = () =>
    horseDocument?.createdAt
      ? moment(horseDocument.createdAt).format('M/D/YY - hh:mm a')
      : 'Unknown Date';

  return (
    <Card elevation={3} sx={{ maxWidth: 400, p: 2, mb: 2 }}>
      <CardContent>
        <Typography variant="h6">
          {horseDocument?.title ||
            horseDocument?.description ||
            'Untitled Document'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Uploaded: {getUploadDate()}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          href={horseDocument?.amazons3URL}
          target="_blank"
        >
          View Document
        </Button>
      </CardActions>
    </Card>
  );
}
