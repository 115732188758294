import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchHorseById } from '../../../../redux/slices/horses/horses.slice';
import { fetchHorseSalesViewsByDayForWeek } from '../../../../redux/actions/oculus/reporting/reporting.actions';
import {
  Box,
  Button,
  Typography,
  Paper,
  Tabs,
  Tab,
  Chip,
  Stack,
  useTheme,
  Grid,
} from '@mui/material';
import HorseDetailsPanelMUI from './core/HorseDetailsPanelMUI';
import HorsePhotoManagementPanelMUI from './core/HorsePhotoManagementPanelMUI';
import HorseVideoManagementPanelMUI from './core/HorseVideoManagementPanelMUI';
import AddVideo from '../core/AddVideo';
import HorseS3Videos from '../core/HorseS3Videos';
import HorseDocumentManagementPanelMUI from './core/HorseDocumentManagementPanelMUI';
import HorseLogsMUI from './core/HorseLogsMUI';
import HorsePedigreeMUI from './core/HorsePedigreeMUI';
import HelmetTracker from '../../../core/HelmetTracker';
import { useMediaQuery } from '@mui/material';

export default function HorseManagementMUI() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme(); // ✅ For responsive styling
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // ✅ Correct way to check mobile
  const [tabIndex, setTabIndex] = useState(0);

  const horse = useSelector(
    (state) => state.horses.list.find((m) => m.id === parseInt(id)),
    shallowEqual
  );
  const horseViews = useSelector(
    (state) => state.reporting.singleHorseSalesViews
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchHorseById(id));
      dispatch(fetchHorseSalesViewsByDayForWeek(id));
    }
  }, [dispatch, id]);

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Box sx={{ p: { xs: 1, sm: 3 } }}>
      {' '}
      <HelmetTracker title="Horse Management" />
      <Paper elevation={3} sx={{ p: { xs: 2, sm: 3 }, mb: { xs: 2, sm: 3 } }}>
        <Typography
          variant="h5"
          sx={{ fontSize: { xs: '1.4rem', sm: '2rem' } }}
        >
          Horse Management Console
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontSize: { xs: '1.2rem', sm: '1.6rem' } }}
        >
          {horse?.name} (ID: {horse?.id})
        </Typography>

        {/* ✅ Stack buttons vertically on mobile */}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          sx={{ mt: 2, alignItems: { xs: 'stretch', sm: 'center' } }}
        >
          <Button
            variant="contained"
            color="info"
            onClick={() => navigate('/horses')}
          >
            Back to Horses
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate('/barns')}
          >
            Return to Barns
          </Button>
          {horse?.authorized && horse?.isSaleHorse && (
            <Button
              variant="contained"
              color="warning"
              onClick={() => navigate(`/sales/horse/${horse.id}`)}
            >
              Preview Sale
            </Button>
          )}
        </Stack>
      </Paper>
      {isMobile ? (
        // ✅ Two rows of buttons for iPhone
        <Grid container spacing={1} sx={{ mt: 1 }}>
          {[
            { label: 'Details', count: null },
            {
              label: 'Photos',
              count: horse?.photos?.length || 0,
              color: 'error',
            },
            {
              label: 'Videos',
              count: horse?.videos?.length || 0,
              color: 'success',
            },
            {
              label: 'S3 Videos',
              count: horse?.s3videos?.length || 0,
              color: 'error',
            },
            {
              label: 'Documents',
              count: horse?.documents?.length || 0,
              color: 'info',
            },
            {
              label: 'Logs',
              count: horse?.horseLogs?.length || 0,
              color: 'warning',
            },
          ].map((tab, index) => (
            <Grid item xs={4} key={index}>
              <Button
                fullWidth
                variant={tabIndex === index ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => setTabIndex(index)}
                sx={{ textTransform: 'none' }}
              >
                {tab.label}{' '}
                {tab.count !== null && (
                  <Chip
                    label={tab.count}
                    color={tab.color}
                    size="small"
                    sx={{ ml: 1 }}
                  />
                )}
              </Button>
            </Grid>
          ))}
        </Grid>
      ) : (
        // ✅ Traditional tabs for desktop
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Details" />
          <Tab
            label={
              <>
                Photos{' '}
                <Chip
                  label={horse?.photos?.length || 0}
                  color="error"
                  size="small"
                />
              </>
            }
          />
          <Tab
            label={
              <>
                Videos{' '}
                <Chip
                  label={horse?.videos?.length || 0}
                  color="success"
                  size="small"
                />
              </>
            }
          />
          <Tab
            label={
              <>
                S3 Videos{' '}
                <Chip
                  label={horse?.s3videos?.length || 0}
                  color="error"
                  size="small"
                />
              </>
            }
          />
          <Tab
            label={
              <>
                Documents{' '}
                <Chip
                  label={horse?.documents?.length || 0}
                  color="info"
                  size="small"
                />
              </>
            }
          />
          <Tab
            label={
              <>
                Logs{' '}
                <Chip
                  label={horse?.horseLogs?.length || 0}
                  color="warning"
                  size="small"
                />
              </>
            }
          />
        </Tabs>
      )}
      <Box sx={{ mt: { xs: 2, sm: 3 } }}>
        {tabIndex === 0 && (
          <HorseDetailsPanelMUI horse={horse} horseViews={horseViews} />
        )}
        {tabIndex === 1 && <HorsePhotoManagementPanelMUI horse={horse} />}
        {tabIndex === 2 && <HorseVideoManagementPanelMUI horse={horse} />}
        {tabIndex === 3 && horse?.id && (
          <>
            <AddVideo horseId={horse.id} />
            <HorseS3Videos horse={horse} />
          </>
        )}
        {tabIndex === 4 && <HorseDocumentManagementPanelMUI horse={horse} />}
        {tabIndex === 5 && <HorseLogsMUI horse={horse} />}
      </Box>
      <Paper elevation={2} sx={{ mt: { xs: 2, sm: 3 }, p: { xs: 2, sm: 3 } }}>
        <Typography
          variant="h5"
          sx={{ fontSize: { xs: '1.3rem', sm: '1.7rem' } }}
        >
          Pedigree
        </Typography>
        <HorsePedigreeMUI horse={horse} />
      </Paper>
    </Box>
  );
}
