/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import classnames from 'classnames';
import {
  Row,
  Col,
  Alert,
  Card,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  ButtonToolbar,
  Form,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import { searchUsers, clearSearchUser } from '../../../redux/actions/users/user.actions';
import { createCommunityInvite } from '../../../redux/actions/users/communityInvite.actions';
import CommunityInvitesTable from './CommunityInvitesTable';
// import CommunityUsersTable from './CommunityUsersTable';
/* eslint-enable no-unused-vars */

class CommunityAddPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.inviteUserClick = this.inviteUserClick.bind(this);
    this.state = {
      searchString: '',
      errors: '',
      loading: false,
    };
  }

  inviteUserClick(searchedUser) {
    this.setState({ loading: true });
    if (
      searchedUser &&
      this.props.auth &&
      this.props.auth.id !== searchedUser.id
    ) {
      this.props
        .createCommunityInvite({
          invitedUserId: searchedUser.id,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
      this.props.clearSearchUser(searchedUser);
    }
  }

  searchedUsersToListGroup(searchedUsers) {
    return _.values(searchedUsers).map((searchedUser) => {
      return (
        <ListGroupItem key={searchedUser.id}>
          {searchedUser.displayName}{' '}
          <Button
            variant="primary"
            onClick={this.inviteUserClick.bind(this, searchedUser)}
          >
            Invite
          </Button>
        </ListGroupItem>
      );
    });
  }

  searchToolBar() {
    return (
      <ButtonToolbar>
        <Button type="submit" variant="primary">
          Search
        </Button>
      </ButtonToolbar>
    );
  }

  usersToListGroup(communityUsers) {
    return _.values(communityUsers).map((communityUser) => {
      return (
        <ListGroupItem key={communityUser.id}>
          {communityUser.displayNameCommunityUser}
        </ListGroupItem>
      );
    });
  }

  handleSubmitSearch = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { searchString } = this.state;
    this.setState({ loading: true });
    this.props
      .searchUsers(searchString)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  render() {
    const searchForm = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmitSearch}
      >
        <div>
          <h1>Search Users</h1>
        </div>
        <Form.Group>
          <Form.Label>User Search</Form.Label>
          <Form.Control
            id="searchString"
            type="text"
            value={this.state.searchString}
            onChange={this.handleChange}
            name="searchString"
          />
          <Form.Control.Feedback />
          <Form.Text>Search by name or email (partial is ok).</Form.Text>
        </Form.Group>
        <div>{this.searchToolBar()}</div>
      </Form>
    );
    const usersPanel = (
      <Card variant="danger">
        <Card.Body>
          <Card variant="danger">
            <Card.Header>
              <h2>People Already Invited, Awaiting their Response</h2>
            </Card.Header>
            <Card.Body>
              <CommunityInvitesTable />
            </Card.Body>
          </Card>
          <Card variant="danger">
            <Card.Header>
              <h3>Add Users</h3>
            </Card.Header>
            <Card.Body>{searchForm}</Card.Body>
            <Card.Footer>
              <ListGroup>
                {this.searchedUsersToListGroup(this.props.searchedUsers)}
              </ListGroup>
            </Card.Footer>
          </Card>
        </Card.Body>
      </Card>
    );
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Alert>
              <Easel />
              &nbsp;&nbsp;Add People
              <small> Add People to your community Here</small>
            </Alert>
            <ButtonToolbar>
              <Button glyph="globe" href="/account" variant="primary">
                Return To Account
              </Button>
              <Button glyph="globe" href="/account/community" variant="warning">
                Return To Community Page
              </Button>
            </ButtonToolbar>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {usersPanel}
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps({ auth, searchedUsers, communityUsers }) {
  return {
    auth,
    searchedUsers,
    communityUsers,
  };
}

export default connect(mapStateToProps, {
  searchUsers,
  createCommunityInvite,
  clearSearchUser,
})(CommunityAddPage);
