/* (--- AUTO GEN ---) */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCommunityInvites } from '../../../redux/actions/users/communityInvite.actions';
import _ from 'lodash';
import CommunityInvitesTableRow from './CommunityInvitesTableRow';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class CommunityInvitesTable extends Component {
  componentDidMount() {
    this.props.fetchCommunityInvites();
  }

  renderCommunityInvites() {
    return _.values(this.props.communityInvites).map((communityInvite) => {
      return (
        <CommunityInvitesTableRow
          communityInvite={communityInvite}
          key={communityInvite.id}
          auth={this.props.auth}
        />
      );
    });
  }

  render() {
    return (
      <Table striped bordered    hover>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>{this.renderCommunityInvites()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ communityInvites, auth }) {
  return { communityInvites, auth };
}

export default connect(mapStateToProps, { fetchCommunityInvites })(
  CommunityInvitesTable
);
