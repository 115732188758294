import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Box, Drawer as MuiDrawer, ListItemButton, Typography } from "@mui/material";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h4.fontSize}; /* ✅ Made "Full Gallop" larger */
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 64px;
  padding-left: ${(props) => props.theme.spacing(4)};
  padding-right: ${(props) => props.theme.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px; /* ✅ Adds spacing between logo & text */
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        <Box
          component="img"
          src="/logo.png" /* ✅ Adjusted path (use /public for easy access) */
          alt="Brand Logo"
          sx={{ height: 35, width: "auto", mr: 2 }} /* ✅ Slightly smaller logo with spacing */
        />
        <Typography variant="h4" fontWeight="bold">
          Full Gallop
        </Typography>
      </Brand>
      <SidebarNav items={items} />
    </Drawer>
  );
};

export default Sidebar;
