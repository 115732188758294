import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchJump } from '../../../../redux/actions/jump.actions';
import {
  Col,
  Card,
  Button,
  Container,
  Alert,
  Row,
  Tab,
  Tabs,
  Badge,
} from 'react-bootstrap';
import { Stack } from '@mui/material';
import { Easel } from 'react-bootstrap-icons';
import JumpPhotoManagementPanel from '../core/JumpPhotoManagementPanel';
import JumpDetailsPanel from '../core/JumpDetailsPanel';
// import JumpLogs from './core/JumpLogs';
import HelmetTracker from '../../../core/HelmetTracker';

export function JumpManagement() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (id) {
      dispatch(fetchJump(id));
    }
  }, [dispatch, id]);

  const jump = useSelector(
    (state) =>
      state.jumps.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function getPhotosCount() {
    if (jump && jump.photos) return jump.photos.length;
    else return 0;
  }

  function getPhotosTabTitle() {
    return (
      <>
        Photos &nbsp;
        <Badge bg="danger">{getPhotosCount()}</Badge>
      </>
    );
  }

  function renderHeaderButton() {
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="info"
          onClick={() => {
            navigate('/jumps');
          }}
        >
          Back to Jumps
        </Button>
      </Stack>
    );
  }

  return (
    <Container fluid>
      <HelmetTracker title="Jump Management" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant="danger">
            <h3>
              <Easel /> &nbsp; &nbsp;Jump Management Console
            </h3>
            <h4>
              {jump ? jump.description : ''}&nbsp; -&nbsp; (Id:
              {jump ? jump.id : ''})
            </h4>
            {renderHeaderButton()}
          </Alert>
        </Col>
      </Row>
      <Row className="show-grid">
        <Card border="danger">
          <Card.Body>
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <JumpDetailsPanel jump={jump} />
              </Tab>
              <Tab eventKey="photos" title={getPhotosTabTitle()}>
                <JumpPhotoManagementPanel jump={jump} auth={auth} />
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default JumpManagement;
