import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalTextArea,
  finalObjSelector,
} from '../../../core/FinalCoreForms';
import {
  createPermission,
  updatePermission,
} from '../../../../redux/actions/admin/permission.actions';
import { useDispatch } from 'react-redux';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function PermissionForm({ permission, onOkClick }) {
  const dispatch = useDispatch();

  const initialValues = {
    id: permission && permission.id ? permission.id : null,
    permission:
      permission && permission.permission ? permission.permission : '',
    dataStatus: permission && permission.dataStatus ? permission.dataStatus : 0,
  };

  function submitPermission(permission) {
    if (permission && permission.id) {
      return dispatch(updatePermission(permission)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createPermission(permission)).then(() => {
        //   navigateBack();
      });
    }
  }

  const typeOptionsList = [
    { key: 'Client', value: '0' },
    { key: 'Server', value: '1' },
  ];

  const onSubmit = async (values) => {
    await sleep(300);
    if (permission && submitPermission) {
      Object.assign(permission, values);
      submitPermission(permission);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitPermission(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      {renderFormTitle(permission, 'Permissions')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('permission', 'Permission')}
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalTextArea('notes', 'Notes')}
            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
}
