import React, { useState } from 'react';
import { Button, Paper, Box, Typography, useTheme } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; // ✅ MUI Calendar Icon
import CalendarTable from '../core/CalendarTable';
import HelmetTracker from '../../core/HelmetTracker';

export default function CalendarPage() {
  const theme = useTheme();
  const [showPriorEvents, setShowPriorEvents] = useState(false);

  function getButtonTitle() {
    return showPriorEvents ? 'Show Upcoming Events' : 'Show Prior Events';
  }

  function changeCalendarDisplay() {
    setShowPriorEvents((prev) => !prev);
  }

  return (
    <>
      <HelmetTracker title="Calendar" />

      {/* ✅ Calendar Title Panel */}
      <Paper
        sx={{
          p: 2,
          mb: 1,
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
        }}
      >
        <Box display="flex" alignItems="center">
          <CalendarMonthIcon sx={{ fontSize: 40, mr: 2 }} />{' '}
          {/* ✅ Larger Icon */}
          <Typography variant="h3" fontWeight="bold">
            Calendar
          </Typography>
        </Box>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Have a look and see what we have going on!
        </Typography>
      </Paper>

      {/* ✅ Toggle Button */}
      <Button
        variant="contained"
        sx={{
          bgcolor: theme.palette.secondary.main, // ✅ Higher contrast background
          color: theme.palette.secondary.contrastText, // ✅ Ensures text is readable
          '&:hover': {
            bgcolor: theme.palette.secondary.dark, // ✅ Slightly darker on hover
          },
          fontSize: '1.25rem', // ✅ Bigger text for readability
          fontWeight: 'bold',
          px: 3, // ✅ Padding for better button feel
          py: 1.5,
          borderRadius: '8px', // ✅ Rounded for modern look
        }}
        onClick={changeCalendarDisplay}
      >
        {getButtonTitle()}
      </Button>

      {/* ✅ Calendar Table */}
      <CalendarTable showPrior={showPriorEvents} />
    </>
  );
}
