import React, { useEffect } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import S3VideoPlayer from './S3VideoPlayer';

/**
 * Component to display all S3 videos of a horse using Material-UI.
 * @param {Object} horse - Horse object containing `s3Videos` (Amazon S3).
 */
const HorseS3Videos = ({ horse }) => {
  // Debugging: Log videos
  useEffect(() => {
    console.log("Horse Videos Debug:", horse?.s3videos);
  }, [horse]);

  if (!horse || !horse.s3videos?.length) {
    return <Typography color="textSecondary">No videos available for this horse.</Typography>;
  }

  return (
    <Box sx={{ maxWidth: '1000px', margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Horse Videos
      </Typography>

      {/* ✅ Use S3VideoPlayer for Each Video */}
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Cloud Videos (Amazon S3)
        </Typography>
        <Grid container spacing={2}>
          {horse.s3videos.map((video, index) => (
            <Grid item xs={12} sm={6} key={`${video.id}-${index}`}>
              <S3VideoPlayer s3Video={video} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default HorseS3Videos;
