import React from 'react';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { addVideo } from '../../../../redux/slices/horses/horses.slice';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';

const AddVideo = ({ horseId }) => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.horses);

  const onSubmit = async (values) => {
    const title = values.title ? values.title.trim() : 'Untitled Video';
    const description = values.description ? values.description.trim() : 'No description provided';

    if (!values.video) {
      return { video: 'A video file is required' };
    }

    dispatch(addVideo({
      horseId,
      title,
      description,
      videoFile: values.video,
    }));
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        mx: 'auto',
        p: 4,
        bgcolor: 'rgba(30, 30, 30, 0.9)',
        borderRadius: 2,
        boxShadow: 5,
        color: 'white',
        minHeight: 400,
        overflow: 'hidden',
      }}
    >
      <Typography variant="h5" gutterBottom color="white">
        Upload a Video
      </Typography>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, submitError }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <Field name="title">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Title (Optional)"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{ style: { color: 'white' } }}
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error ? meta.error?.toString() || 'Invalid input' : 'Defaults to "Untitled Video" if left blank'}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'white' },
                        '&:hover fieldset': { borderColor: 'gray' },
                        '&.Mui-focused fieldset': { borderColor: 'blue' },
                      },
                    }}
                  />
                )}
              </Field>
            </Box>

            <Box sx={{ mb: 2 }}>
              <Field name="description">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Description (Optional)"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{ style: { color: 'white' } }}
                    error={meta.touched && meta.error}
                    helperText={meta.touched && meta.error ? meta.error?.toString() || 'Invalid input' : 'Defaults to "No description provided" if left blank'}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': { borderColor: 'white' },
                        '&:hover fieldset': { borderColor: 'gray' },
                        '&.Mui-focused fieldset': { borderColor: 'blue' },
                      },
                    }}
                  />
                )}
              </Field>
            </Box>

            <Box sx={{ mb: 2, minHeight: 50, display: 'flex', alignItems: 'center' }}>
              <Field name="video">
                {({ input, meta }) => (
                  <>
                    <input
                      type="file"
                      accept="video/mp4,video/quicktime"
                      onChange={(e) => input.onChange(e.target.files[0])}
                      style={{
                        display: 'block',
                        color: 'white',
                        marginBottom: '8px',
                        minWidth: '100%',
                      }}
                    />
                    {meta.touched && meta.error && (
                      <Typography color="error" variant="caption">
                        {meta.error?.toString() || 'Invalid input'}
                      </Typography>
                    )}
                  </>
                )}
              </Field>
            </Box>

            {submitError && typeof submitError === 'string' && (
              <Typography color="error" sx={{ mb: 2 }}>
                {submitError}
              </Typography>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={submitting || status === 'loading'}
              sx={{
                py: 1.5,
                bgcolor: 'blue',
                '&:hover': { bgcolor: 'darkblue' },
              }}
            >
              {submitting || status === 'loading' ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Upload Video'
              )}
            </Button>

            {status === 'failed' && error && typeof error === 'string' && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </form>
        )}
      />
    </Box>
  );
};

export default AddVideo;