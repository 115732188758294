/* AUTO GEN */
/* mediasReducer.js */
import { SET_MEDIAS, MEDIA_FETCHED } from '../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MEDIA_FETCHED:
      return { ...state, [action.media.id]: action.media };
    case SET_MEDIAS:
      return action.medias;
    default:
      return state;
  }
}
