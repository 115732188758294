/* AUTO GEN */
/* SjCourse.js */
export class SjCourse {
  constructor(
    id,
    eventId,
    name, 
    level,
    lengthMeters,
    optimumSpeed,
    optimumTime,
    maximumTime,
    dataStatus
  ) {
    this.id = id;
    this.eventId = eventId;
    this.name = name;
    this.level = level;
    this.lengthMeters = lengthMeters;
    this.optimumSpeed = optimumSpeed;
    this.optimumTime = optimumTime;
    this.maximumTime = maximumTime;
    this.dataStatus = dataStatus;
  }
}

export function sjCourseToClass(sjCourse) {
  if (sjCourse) {
    return new SjCourse(
      sjCourse.id,
      sjCourse.eventId,
      sjCourse.name, 
      sjCourse.level,
      sjCourse.lengthMeters,
      sjCourse.optimumSpeed,
      sjCourse.optimumTime,
      sjCourse.maximumTime,
      sjCourse.dataStatus
    );
  } else {
    return null;
  }
}

export function sjCoursesToClassArray(sjCourses) {
  if (sjCourses) {
    const sjCoursesArray = sjCourses.map((item) => {
      return sjCourseToClass(item);
    });
    return sjCoursesArray;
  }
}
