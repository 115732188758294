import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, CssBaseline, Paper as MuiPaper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';

import GlobalStyle from './core/GlobalStyle';
import Navbar from './core/navbar/Navbar';
import dashboardItems from './core/sidebar/dashboardItems';
import Sidebar from './core/sidebar/Sidebar';
import Footer from './core/Footer';

const _ = require('lodash');

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const DashboardLayout = ({ children }) => {
  const auth = useSelector((state) => state.auth);

  const router = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);

  function authorizedItems() {
    if (auth && auth.isAdmin()) {
      return dashboardItems;
    } else {
      const addHorses = auth && auth.checkHasPermission('ADD_HORSES');
      var filtered_array = _.filter(dashboardItems, function (o) {
        return !o.admin;
      });
      if (!addHorses) {
        filtered_array = _.filter(filtered_array, function (o) {
          return !o.addHorses;
        });
      }
      if (!auth) {
        let auth_filtered = _.filter(filtered_array, function (o) {
          return !o.requireLogin;
        });
        return auth_filtered;
      }
      return filtered_array;
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Close mobile menu when navigation occurs
  useEffect(() => {
    setMobileOpen(false);
  }, [router.pathname]);

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={authorizedItems()}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={authorizedItems()}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isLgUp ? 2 : 1}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
    </Root>
  );
};

export default DashboardLayout;
