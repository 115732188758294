import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useClickOutside } from './useClickOutside';

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);

  const presetColors = [
    '#FF0000', '#0000FF', '#008000', '#FFFF00', '#00FF00',
    '#00FFFF', '#808080', '#C0C0C0', '#FFFFFF', '#000000'
  ];

  function handleColorChange(selectedColor) {
    onChange(selectedColor);
    close(); // ✅ Close picker after selecting a color
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          backgroundColor: color,
          width: '28px',
          height: '28px',
          borderRadius: '8px',
          border: '3px solid #fff',
          cursor: 'pointer',
          boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)'
        }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div ref={popover}>
          <HexColorPicker color={color} onChange={handleColorChange} />
          <div>
            {presetColors.map((presetColor) => (
              <button
                key={presetColor}
                style={{
                  width: '24px',
                  height: '24px',
                  margin: '4px',
                  borderRadius: '4px',
                  border: 'none',
                  cursor: 'pointer',
                  backgroundColor: presetColor
                }}
                onClick={() => handleColorChange(presetColor)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
