import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchSaleHorseById } from '../../redux/slices/horses/horses.slice';
import HorsePedigree from '../customers/horses/core/HorsePedigree';
import SaleVideoCard from '../core/SaleVideoCard';
import HelmetTracker from '../core/HelmetTracker';
import 'lightgallery/css/lightgallery.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  IconButton,
  Typography,
  List,
  ListItem,
  Divider,
  useTheme,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ImageIcon from '@mui/icons-material/Image';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

export function SaleHorseListingPage({ auth }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [videosExpanded, setVideosExpanded] = useState(true);
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(fetchSaleHorseById(id));
    }
  }, [dispatch, id]);

  const salehorse = useSelector(
    (state) => state.horses.saleList.find((m) => m.id === parseInt(id)),
    shallowEqual
  );

  useEffect(() => {
    if (salehorse?.photos?.length) {
      setSelectedImage(salehorse.photos[0].amazons3URL);
    }
  }, [salehorse]);

  return (
    <Box
      sx={{ width: '100%', p: 4, bgcolor: theme.palette.background.default }}
    >
      {/* ✅ HelmetTracker for Page Tracking */}
      {salehorse && (
        <HelmetTracker
          title={salehorse.name || 'Sale Horse'}
          objId={salehorse.id}
          userId={auth ? auth.id : null}
        />
      )}

      {/* ✅ Page Title with Back Button */}
      <Paper
        sx={{
          p: 4,
          mb: 4,
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            color="inherit"
            onClick={() => navigate(-1)}
            sx={{ mr: 2 }}
          >
            <ArrowBackIosNewIcon fontSize="large" />
          </IconButton>
          <Typography variant="h2" fontWeight="bold">
            {salehorse?.name || 'Sale Horse'}
          </Typography>
        </Box>
      </Paper>

      <Grid container spacing={4}>
        {/* ✅ Description Section */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Description"
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent>
              <Typography
                variant="h6"
                sx={{ fontSize: '1.5rem', lineHeight: 1.5 }}
              >
                {salehorse?.description || 'No description available.'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* ✅ General Information Section */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="General Information"
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent>
              <List sx={{ fontSize: '1.25rem' }}>
                {' '}
                {/* ✅ Increased text size */}
                <ListItem>
                  <strong>Price:&nbsp;</strong> {salehorse?.getPrice()}
                </ListItem>
                <ListItem>
                  <strong>Breed:&nbsp;</strong> {salehorse?.breed}
                </ListItem>
                <ListItem>
                  <strong>Gender:&nbsp;</strong> {salehorse?.getGender()}
                </ListItem>
                <ListItem>
                  <strong>Color:&nbsp;</strong> {salehorse?.color}
                </ListItem>
                <ListItem>
                  <strong>Height:&nbsp;</strong> {salehorse?.height}
                </ListItem>
                <ListItem>
                  <strong>Birth Year:&nbsp;</strong> {salehorse?.birthYear}
                </ListItem>
                <ListItem>
                  <strong>Level Competing:&nbsp;</strong>{' '}
                  {salehorse?.levelCompeting}
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* ✅ Pedigree Section */}
        {salehorse?.pedigree && !salehorse.pedigreeEmptyOrPartial() && (
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="Pedigree"
                sx={{
                  bgcolor: theme.palette.secondary.main,
                  color: theme.palette.secondary.contrastText,
                  p: 3, // ✅ Proper padding for spacing
                }}
                titleTypographyProps={{
                  variant: 'h4', // ✅ Controls font size inside CardHeader
                  fontWeight: 'bold', // ✅ Ensures it stands out
                }}
              />
              <Divider />
              <CardContent>
                <HorsePedigree horse={salehorse} />
              </CardContent>
            </Card>
          </Grid>
        )}

        {/* ✅ Photos Section */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Photos"
              avatar={<ImageIcon />}
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent>
              {salehorse?.photos && (
                <PhotoGallery
                  photos={salehorse.photos}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* ✅ S3 Videos Section */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="S3 Videos"
              avatar={<VideoLibraryIcon />}
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent sx={{ fontSize: '1.25rem' }}>
              {renderS3Videos(salehorse)}
            </CardContent>
          </Card>
        </Grid>

        {/* ✅ Improved Videos Section */}
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="YouTube Videos"
              avatar={<VideoLibraryIcon />}
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
              action={
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setVideosExpanded(!videosExpanded)}
                >
                  {videosExpanded ? 'Hide Videos' : 'Show Videos'}
                </Button>
              }
            />
            <Divider />
            {videosExpanded && (
              <CardContent>{renderVideos(salehorse)}</CardContent>
            )}
          </Card>
        </Grid>

        {/* ✅ Contact Info Section */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader
              title="Cell Phone"
              avatar={<PhoneIcon />}
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent sx={{ fontSize: '1.25rem' }}>
              {salehorse?.id === 268
                ? 'Brian Kilgo Kelly - (646) 416-1001'
                : 'Lara Anderson - (803) 215-6590'}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardHeader
              title="Email"
              avatar={<EmailIcon />}
              sx={{
                bgcolor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
                p: 3, // ✅ Proper padding for spacing
              }}
              titleTypographyProps={{
                variant: 'h4', // ✅ Controls font size inside CardHeader
                fontWeight: 'bold', // ✅ Ensures it stands out
              }}
            />
            <Divider />
            <CardContent sx={{ fontSize: '1.25rem' }}>
              {salehorse?.id === 268
                ? 'Brian Kilgo Kelly - bkkeventing@gmail.com'
                : 'Lara Anderson - fullgallopfarm@gmail.com'}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

/* ✅ Updated Photo Gallery with Swiping & Arrows */
function PhotoGallery({ photos, selectedImage, setSelectedImage }) {
  return (
    <Carousel showThumbs={true} showArrows={true} swipeable>
      {photos.map((photo) => {
        const aspectRatio = photo.fullWidth / photo.fullHeight; // ✅ Calculate Aspect Ratio
        return (
          <div
            key={photo.id}
            onClick={() => setSelectedImage(photo.amazons3URL)}
          >
            <img
              src={photo.amazons3URL}
              alt="Horse"
              style={{
                borderRadius: '8px',
                width: '100%',
                maxHeight: '600px', // ✅ Prevent overly tall images
                aspectRatio: aspectRatio, // ✅ Maintain original aspect ratio
                objectFit: 'contain', // ✅ Prevent cropping
              }}
            />
          </div>
        );
      })}
    </Carousel>
  );
}

/* ✅ Restored Video Section */
function renderVideos(salehorse) {
  if (!salehorse?.videos?.length)
    return <Typography>No Videos Available</Typography>;

  return (
    <List>
      {salehorse.videos.map((video) => (
        <ListItem key={video.id}>
          <SaleVideoCard video={video} />
        </ListItem>
      ))}
    </List>
  );
}

/* ✅ New S3 Videos Section */
function renderS3Videos(salehorse) {
  if (!salehorse?.s3videos?.length)
    return <Typography>No S3 Videos Available</Typography>;

  return (
    <Grid container spacing={2} justifyContent="center">
      {salehorse.s3videos.map((video) => (
        <Grid item xs={12} key={video.id}>
          {' '}
          {/* ✅ Takes full width */}
          <Card sx={{ my: 2, p: 2 }}>
            {' '}
            {/* ✅ Adds spacing around videos */}
            <CardHeader
              title={video.title || 'Untitled Video'}
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            />
            <Divider />
            <CardContent>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <video
                  controls
                  style={{
                    width: '100%', // ✅ Full width of the panel
                    maxWidth: '900px', // ✅ Avoids stretching too wide on large screens
                    borderRadius: '8px',
                  }}
                >
                  <source src={video.amazons3URL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 2, textAlign: 'center' }}
              >
                {video.description || 'No description available.'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default SaleHorseListingPage;
