/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { fetchHorse, updateFeed } from '../../../redux/actions/horse.actions';
import {
  Col,
  Form,
  Card,
  Button,
  Container,
  Row,
  ButtonToolbar,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { textArea, dateField } from '../../core/CoreForms';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

class HorseManageFeed extends Component {
  state = {
    id: this.props.horse ? this.props.horse.id : null,
    name: this.props.horse ? this.props.horse.name : '',
    feed: this.props.horse ? this.props.horse.feed : '',
    errors: '',
    loading: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchHorse(this.props.match.params.id);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.horse) {
      this.setState({
        id: nextProps.horse.id ? nextProps.horse.id : null,
        name: nextProps.horse.name,
        feed: nextProps.horse.feed,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { id, feed } = this.state;
    this.setState({ loading: true });
    this.props
      .updateFeed({
        id,
        feed,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  renderFormTitle() {
    if (this.state.id && this.state.id.length > 3) {
      return (
        <Card.Header>
          <h1>Manage Horse</h1>
          <h2>{this.props.name}</h2>
        </Card.Header>
      );
    }
  }

  getName() {
    if (this.props.horse) {
      return this.props.horse.name;
    }
    return '';
  }

  /* eslint-disable max-len */
  render() {
    const feedForm = (
      <Card variant="danger">
        <Card.Header>
          <h2>Feed - {this.getName()}</h2>
        </Card.Header>
        <Form
          className={classnames('ui', 'form', { loading: this.state.loading })}
          onSubmit={this.handleSubmit}
        >
          {textArea('feed', '', this)}
          <ButtonToolbar>
            <LinkContainer to={'/horses/feed'}>
              <Button>Return to Feed</Button>
            </LinkContainer>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </ButtonToolbar>
        </Form>
      </Card>
    );
    return (
      <Container fluid>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            {feedForm}
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  horse: state.horses.find((item) => item._id === props.match.params._id),
      horse: state.horses[props.match.params.id],
      barns: state.barns,
    };
  }

  return {
    horse: null,
    barns: state.barns,
  };
}

export default connect(mapStateToProps, {
  fetchHorse,
  updateFeed,
})(HorseManageFeed);
