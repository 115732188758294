import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { mediaToClass, mediasToClassArray } from '../../classes/media/Media.class';

const API_URL = '/api/medias';

// Async Thunks
export const fetchMediaFeed = createAsyncThunk('media/fetchFeed', async () => {
  const response = await axios.get(`${API_URL}/feed/read`);
  return mediasToClassArray(response.data);
});

export const fetchMediaById = createAsyncThunk('media/fetchById', async (id) => {
  const response = await axios.get(`${API_URL}/read/${id}`);
  return mediaToClass(response.data);
});

export const createMedia = createAsyncThunk('media/create', async (values) => {
  const response = await axios.post(`${API_URL}/create`, values);
  return mediaToClass(response.data);
});

export const updateMedia = createAsyncThunk('media/update', async (values) => {
  const response = await axios.post(`${API_URL}/update`, values);
  return mediasToClassArray(response.data);
});

export const deleteMedia = createAsyncThunk('media/delete', async (values) => {
  const response = await axios.post(`${API_URL}/delete`, values);
  return mediasToClassArray(response.data);
});

export const addMediaWithPhotos = createAsyncThunk('media/addWithPhotos', async (values) => {
  const response = await axios.post(`${API_URL}/photos/new/multi`, values);
  return mediasToClassArray(response.data);
});

// Slice
const mediaSlice = createSlice({
  name: 'media',
  initialState: {
    feed: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMediaFeed.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMediaFeed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.feed = action.payload;
      })
      .addCase(fetchMediaFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchMediaById.fulfilled, (state, action) => {
        const mediaIndex = state.feed.findIndex((item) => item.id === action.payload.id);
        if (mediaIndex !== -1) {
          state.feed[mediaIndex] = action.payload;
        } else {
          state.feed.push(action.payload);
        }
      })
      .addCase(createMedia.fulfilled, (state, action) => {
        state.feed.unshift(action.payload);
      })
      .addCase(updateMedia.fulfilled, (state, action) => {
        state.feed = action.payload;
      })
      .addCase(deleteMedia.fulfilled, (state, action) => {
        state.feed = action.payload;
      })
      .addCase(addMediaWithPhotos.fulfilled, (state, action) => {
        state.feed = action.payload;
      });
  },
});

export default mediaSlice.reducer;
