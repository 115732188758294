import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HorseLogForm from './HorseLogFormMUI';
import {
  Card,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from '@mui/material';
import { AddCircleOutline, CancelOutlined } from '@mui/icons-material';

export default function HorseLogsMUI({ horse }) {
  const [selected, setSelected] = useState(-1);
  const [creating, setCreating] = useState(false);
  const auth = useSelector((state) => state.auth);
  const logs = horse?.horseLogs || [];

  const logTypeChip = (type) => (
    <Chip
      label={type === 0 ? 'System' : 'User'}
      color={type === 0 ? 'error' : 'primary'}
    />
  );

  const handleClick = (id) => {
    setSelected(id);
    setCreating(false);
  };

  const handleFormOkClick = () => {
    setCreating(false);
    setSelected(-1);
  };

  const handleAddCancelClick = () => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  return (
    <Card elevation={3}>
      <CardHeader
        action={
          <IconButton onClick={handleAddCancelClick}>
            {creating || selected > 0 ? (
              <CancelOutlined />
            ) : (
              <AddCircleOutline />
            )}
          </IconButton>
        }
        title={horse ? `Logs for ${horse.name}` : 'Logs for (Loading)'}
      />
      {creating && (
        <HorseLogForm horse={horse} auth={auth} onOkClick={handleFormOkClick} />
      )}
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Log Id</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Log</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log) => (
              <TableRow
                key={log.id}
                selected={selected === log.id}
                onClick={() => handleClick(log.id)}
              >
                <TableCell>{log.id}</TableCell>
                <TableCell>
                  <Chip
                    label={log.userName}
                    color={log.userId === auth.id ? 'primary' : 'secondary'}
                  />
                </TableCell>
                <TableCell>{log.log}</TableCell>
                <TableCell>{logTypeChip(log.type)}</TableCell>
                <TableCell>{log.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
