import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import HorseStatsDisplay from '../../components/customers/horses/core/HorseStatsDisplay';
import {
  Box,
  Button,
  Grid,
  Stack,
  Paper,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { horsesToClassArray } from '../../redux/classes/Horse.class'; // Ensure correct import
import FavoriteHorses from '../users/core/FavoriteHorses';
import HelmetTracker from '../core/HelmetTracker';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function SalesHorseList({ auth }) {
  const saleshorses = useSelector((state) => state.horses.saleList);
  const favoriteHorses = useSelector((state) =>
    state.horses.saleList.filter((x) => auth && auth.isFavorite(x.id))
  );

  const [priceOrder, setPriceOrder] = useState(0);
  const [pedigreeFilterText, setPedigreeFilterText] = useState('');
  const [nameFilterText, setNameFilterText] = useState('');
  const [sortFavorites, setSortFavorites] = useState(false);
  const [value, setValue] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [value]);

  function handleFilterChange(setState) {
    return (e) => setState(e.target.value);
  }

  function handleFavoritesToggle() {
    setSortFavorites(!sortFavorites);
  }

  function changePriceSort() {
    setPriceOrder((prev) => (prev + 1) % 3);
  }

  function priceOrderToText() {
    return ['No Sort', 'Ascending', 'Descending'][priceOrder];
  }

  function filterHorses() {
    let horseList = horsesToClassArray(JSON.parse(JSON.stringify(saleshorses))); // ✅ Ensure class conversion

    if (pedigreeFilterText.length > 0) {
      horseList = _.filter(horseList, (horse) =>
        horse.pedigree?.toLowerCase().includes(pedigreeFilterText.toLowerCase())
      );
    }

    if (nameFilterText.length > 0) {
      horseList = _.filter(horseList, (horse) =>
        horse.name?.toLowerCase().includes(nameFilterText.toLowerCase())
      );
    }

    if (sortFavorites) {
      horseList = _.sortBy(horseList, (horse) =>
        auth?.isFavorite(horse.id) ? -1 : 1
      );
    }

    return horseList;
  }

  function renderHorses(order) {
    let horseList = filterHorses();
    let sortOrders = [['price'], ['price'], ['price']];
    let sortDirections = [['asc'], ['asc'], ['desc']];

    if (order !== 0) {
      horseList = _.orderBy(
        horseList,
        sortOrders[order],
        sortDirections[order]
      );
    }

    return (
      <Grid container spacing={2} px={2}>
        {/* ✅ Adds left/right padding to avoid edge collision */}
        {horseList.map((horse) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={horse.id}
            sx={{ display: 'flex', justifyContent: 'center' }} // ✅ Center content in column
          >
            <Box sx={{ mb: 3 }}>
              <HorseStatsDisplay horse={horse} auth={auth} />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  }

  const userId = auth ? auth.id : null;

  return (
    <Box ref={ref}>
      <HelmetTracker title={'Sales'} userId={userId} />
      <Paper sx={{ p: 3, mb: 3, boxShadow: 3 }}>
        <Typography variant="h4" display="flex" alignItems="center" gap={1}>
          <ShoppingCartIcon />
          Sales
        </Typography>
      </Paper>

      {value === 0 && (
        <Stack spacing={2} direction="column" sx={{ mb: 3 }}>
          <TextField
            label="Horse Name"
            variant="outlined"
            fullWidth
            value={nameFilterText}
            onChange={handleFilterChange(setNameFilterText)}
          />
          <TextField
            label="Pedigree Includes"
            variant="outlined"
            fullWidth
            value={pedigreeFilterText}
            onChange={handleFilterChange(setPedigreeFilterText)}
          />
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>Price Sort:</Typography>
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              onClick={changePriceSort}
            >
              {priceOrderToText()}
            </Button>
          </Stack>
          <FormControlLabel
            control={
              <Switch
                checked={sortFavorites}
                onChange={handleFavoritesToggle}
              />
            }
            label="Sort Favorites"
          />
        </Stack>
      )}

      <Grid>
        {value === 0 ? renderHorses(priceOrder) : null}
        {value === 1 && (
          <FavoriteHorses horses={favoriteHorses} sale={true} auth={auth} />
        )}
      </Grid>

      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <BottomNavigationAction label="All" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
