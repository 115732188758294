import React from 'react';
import { Image, Button as BootButton } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import { red } from '@mui/material/colors';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';

export default function HorsePhotoCard({
  horse,
  photo,
  onDelete,
  onMakeMainPhoto,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderPhoto() {
    if (
      photo &&
      photo.amazons3URLHalf &&
      photo.amazons3URLHalf.length > 0
    ) {
      return photo.amazons3URLHalf;
    }
    if (
      photo &&
      photo.amazons3URL &&
      photo.amazons3URL.length > 0
    ) {
      return photo.amazons3URL;
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getUploadDate() {
    if (photo && photo.createdAt) {
      return dateToText(photo.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    if (auth && auth.checkHasPermission('ADD_HORSES')) {
      const horsePhotoId = photo.linkPhotoId;
      onDelete(horsePhotoId);
    }
  }

  function handleUp(e) {
    if (e) {
      e.preventDefault();
    }
    if (horse) {
      horse.movePhotoUp(dispatch, photo.id);
    }
  }

  function handleDown(e) {
    if (e) {
      e.preventDefault();
    }
    if (horse) {
      horse.movePhotoDown(dispatch, photo.id);
    }
  }

  function handleMakeMainPhoto(e) {
    if (e) {
      e.preventDefault();
    }
    const photoId = photo.id;
    const horseId = horse.id;
    onMakeMainPhoto({ horseId, photoId });
  }

  function isMainPhoto() {
    if (horse && horse.photo) {
      if (photo) {
        if (horse.photo.id === photo.id) return true;
      }
    }
    return false;
  }

  function getMakeMainPhotoButton() {
    if (onMakeMainPhoto && !isMainPhoto()) {
      return (
        <BootButton
          color="primary"
          onClick={handleMakeMainPhoto}
          variant="primary"
        >
          Make Main Photo
        </BootButton>
      );
    } else if (isMainPhoto()) {
      return (
        <BootButton color="success" variant="success">
          Main Photo
        </BootButton>
      );
    }
  }

  function getHeaderText() {
    if (onMakeMainPhoto && !isMainPhoto()) {
      return horse.name + " - LinkId: " + photo.linkPhotoId;
    } else if (isMainPhoto()) {
      return horse.name + ' - Main';
    }
  }

  return (
    <div>
      <Card
        raised
        sx={{
          margin: '0 auto',
          padding: '0.1em',
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {photo.id}
            </Avatar>
          }
          title={getHeaderText()}
          subheader={getUploadDate()}
        />
        <CardMedia
          component="img"
          image={renderPhoto()}
          // image={renderPhoto()}
          alt="--"
          height="400px"
          sx={{ objectFit: 'scale-down' }}
        />
        {auth && auth.checkHasPermission('HORSE_ADMIN') ? (
          <CardActions>
            <Stack
              spacing={2}
              justifyContent="space-between"
              direction="row"
              width="100%"
            >
              <Stack direction="row">
                <IconButton onClick={handleUp} aria-label="add to favorites">
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton onClick={handleDown} aria-label="share">
                  <ArrowDownwardIcon />
                </IconButton>
              </Stack>
              {getMakeMainPhotoButton()}
              <IconButton onClick={handleDelete} aria-label="delete">
                <ClearIcon />
              </IconButton>
            </Stack>
          </CardActions>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
}
