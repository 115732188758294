// /components/calendar/core/PhaseChip.js
import React from 'react';
import { Chip as MuiChip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const StyledChip = styled(MuiChip)(({ theme, phase }) => {
    const phaseColors = {
      D: {
        bg: '#b71c1c', // Deep red like Prelim
        color: '#fff',
        border: '2px solid white',
      },
      S: {
        bg: '#ffea00', // Brighter yellow to match MapTools "starter"
        color: '#000',
        border: '2px solid #ffea00', // Outer yellow
        boxShadow: 'inset 0 0 0 2px black', // Inner black border
      },
      X: {
        bg: '#2e7d32', // Deep green
        color: '#fff',
        border: '2px solid white',
      },
    };
  
    const { bg, color, border, boxShadow } = phaseColors[phase] || {
      bg: theme.palette.grey[500],
      color: theme.palette.common.white,
      border: '2px solid white',
    };
  
    return {
      height: 36,
      padding: '4px 12px',
      fontSize: '1rem',
      fontWeight: 600,
      fontFamily: 'Arial, sans-serif',
      letterSpacing: '0.3px',
      backgroundColor: bg,
      color: color,
      border: border,
      boxShadow: boxShadow || 'none',
    };
  });

/**
 * PhaseChip - used to display D (Dressage), S (Stadium), X (XC) times
 * @param {string} phase - One of 'D', 'S', 'X'
 * @param {string} label - Time or value to display
 */
export default function PhaseChip({ phase, label }) {
  const theme = useTheme();
  return <StyledChip label={label} phase={phase} theme={theme} />;
}
