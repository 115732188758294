import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  fetchMedia,
  createMedia,
  updateMedia,
} from '../../../../redux/actions/media.actions';
import MediaInnerForm from './core/MediaInnerForm.form';
import { useNavigate, useParams } from 'react-router-dom';
import HelmetTracker from '../../../core/HelmetTracker';

export default function MediaForm({ auth }) {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      document.title = 'Edit Media';
      dispatch(fetchMedia(id));
    } else {
      document.title = 'Add Media';
    }
  }, [dispatch, id]);

  const media = useSelector(
    (state) =>
      state.medias.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );

  function navigateBack() {
    if (id) navigate('/medias/manage/' + id);
    else navigate('/medias');
  }

  function saveMedia(media) {
    if (media && media.id) {
      return dispatch(updateMedia(media)).then(() => {
        navigateBack();
      });
    } else {
      return dispatch(createMedia(media)).then(() => {
        navigateBack();
      });
    }
  }

  const userId = auth ? auth.id : null;
  const title = media ? 'Edit Media' : 'Add Media';
  const objId = media ? media.id : null;

  return (
    <>
      <HelmetTracker title={title} objId={objId} userId={userId} />
      <MediaInnerForm
        media={media}
        submitMedia={saveMedia}
        auth={auth}
      />
    </>
  );
}
