/* (( *** AUTO GEN *** )) */
/* productActions.js */
import axios from 'axios';
import { SET_PRODUCTS, PRODUCT_FETCHED } from '../types';
import { productToClass, productsToClassArray } from '../../classes/Product.class';

export function productFetched(product) {
  return {
    type: PRODUCT_FETCHED,
    product,
  };
}

export function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products,
  };
}

export const fetchProduct = (id) => async (dispatch) => {
  const res = await axios.get('/api/products/read/' + id);
  dispatch(productFetched(productToClass(res.data)));
};
export const fetchProducts = () => async (dispatch) => {
  const res = await axios.get('/api/products/read');
  dispatch(setProducts(productsToClassArray(res.data)));
};

export const fetchProductsById = (id) => async (dispatch) => {
  const res = await axios.get('/api/products/read/' + id);
  dispatch(setProducts(res.data));
};

export const fetchProductsByCategoryId = (categoryId) => async (dispatch) => {
  const res = await axios.get('/api/products/read/categoryId/' + categoryId);
  dispatch(setProducts(productsToClassArray(res.data)));
};

export const createProduct = (values) => async (dispatch) => {
  const res = await axios.post('/api/products', values);
  dispatch(createStripeProduct(productToClass(res.data)));
};

export const createStripeProduct = (values) => async (dispatch) => {
  const res = await axios.post('/api/stripe/products', values);
  dispatch(productFetched(productToClass(res.data)));
};

// export const createProduct = (values) => async (dispatch) => {
//   const res = await axios.post('/api/products/create', values);
//   dispatch(productFetched(productToClass(res.data)));
// };

export const updateProduct = (values) => async (dispatch) => {
  const res = await axios.post('/api/products/update', values);
  dispatch(productFetched(productToClass(res.data)));
};

export const deleteProduct = (values) => async (dispatch) => {
  const res = await axios.post('/api/products/update', values);
  dispatch(setProducts(productsToClassArray(res.data)));
};
