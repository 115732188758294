import React from 'react';
import { Image, Button as BootButton } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import { red } from '@mui/material/colors';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import {
  // addPhotoToJump,
  deleteJumpPhoto,
  // setMainPhotoForJump,
} from '../../../../redux/actions/jump.actions';
import _ from 'lodash';

export default function JumpPhotoCard({
  jump,
  jumpPhoto,
  onMakeMainPhoto,
}) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderPhoto() {
    if (
      jumpPhoto &&
      jumpPhoto.amazons3URLHalf &&
      jumpPhoto.amazons3URLHalf.length > 0
    ) {
      return jumpPhoto.amazons3URLHalf;
    }
    if (
      jumpPhoto &&
      jumpPhoto.amazons3URL &&
      jumpPhoto.amazons3URL.length > 0
    ) {
      return jumpPhoto.amazons3URL;
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getUploadDate() {
    if (jumpPhoto && jumpPhoto.createdAt) {
      return dateToText(jumpPhoto.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    if (auth && auth.checkHasPermission('JUMP_ADMIN')) {
      const jumpPhotoId = jumpPhoto.linkPhotoId;
      const jumpId = jump.id;
      var jumpProperties = jump.properties;
      _.remove(jumpProperties.photoOrder, function (n) {
        return n === jumpPhoto.id;
      });

      var deleteObj = { jumpId, jumpPhotoId, jumpProperties };
      dispatch(deleteJumpPhoto(deleteObj));
    }
  }

  function handleUp(e) {
    if (e) {
      e.preventDefault();
    }
    if (jump) {
      jump.movePhotoUp(dispatch, jumpPhoto.id);
    }
  }

  function handleDown(e) {
    if (e) {
      e.preventDefault();
    }
    if (jump) {
      jump.movePhotoDown(dispatch, jumpPhoto.id);
    }
  }

  function handleMakeMainPhoto(e) {
    if (e) {
      e.preventDefault();
    }
    const photoId = jumpPhoto.id;
    const jumpId = jump.id;
    onMakeMainPhoto({ jumpId, photoId });
  }

  function isMainPhoto() {
    if (jump && jump.photo) {
      if (jumpPhoto) {
        if (jump.photo.id === jumpPhoto.id) return true;
      }
    }
    return false;
  }

  function getMakeMainPhotoButton() {
    if (onMakeMainPhoto && !isMainPhoto()) {
      return (
        <BootButton
          color="primary"
          onClick={handleMakeMainPhoto}
          variant="primary"
        >
          Make Main Photo
        </BootButton>
      );
    } else if (isMainPhoto()) {
      return (
        <BootButton color="success" variant="success">
          Main Photo
        </BootButton>
      );
    }
  }

  function getHeaderText() {
    if (onMakeMainPhoto && !isMainPhoto()) {
      return jump.description;
    } else if (isMainPhoto()) {
      return jump.description + ' - Main';
    }
  }

  return (
    <div>
      <Card
        raised
        sx={{
          margin: '0 auto',
          padding: '0.1em',
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {jumpPhoto.id}
            </Avatar>
          }
          title={getHeaderText()}
          subheader={getUploadDate()}
        />
        <CardMedia
          component="img"
          image={renderPhoto()}
          // image={renderPhoto()}
          alt="--"
          sx={{ objectFit: 'contain' }}
        />
        {auth ? (
          <CardActions>
            <Stack
              spacing={2}
              justifyContent="space-between"
              direction="row"
              width="100%"
            >
              <Stack direction="row">
                <IconButton onClick={handleUp} aria-label="add to favorites">
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton onClick={handleDown} aria-label="share">
                  <ArrowDownwardIcon />
                </IconButton>
              </Stack>
              {getMakeMainPhotoButton()}

              {auth && auth.checkHasPermission('JUMP_ADMIN') ? (
                <IconButton onClick={handleDelete} aria-label="delete">
                  <ClearIcon />
                </IconButton>
              ) : (
                ''
              )}
            </Stack>
          </CardActions>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
}
