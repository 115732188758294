import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPhotosToHorse,
  deleteHorsePhoto,
  setMainPhotoForHorse,
} from '../../../../../redux/slices/horses/horses.slice';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
} from '@mui/material';
import HorsePhotosGroupMUI from './HorsePhotosGroupMUI';
import { v4 as uuidv4 } from 'uuid';

export default function HorsePhotoManagementPanelMUI({ horse }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [adding, setAdding] = useState(false);
  const [photoDescription, setPhotoDescription] = useState('');

  const handleSubmitPhoto = (e) => {
    e.preventDefault();
    if (!horse?.id) return;

    const data = new FormData();
    data.append('description', photoDescription);
    data.append('title', '');
    data.append('userId', auth.id);
    data.append('horseId', horse.id);
    data.append('uuid', uuidv4());

    for (let i = 0; i < e.target.photos.files.length; i++) {
      data.append('photos', e.target.photos.files[i]);
    }

    setPhotoDescription('');
    e.target.photos.value = null;
    dispatch(addPhotosToHorse(data));
  };

  return (
    <Card elevation={3}>
      <CardHeader
        title="Photos"
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdding(!adding)}
          >
            Add Photo
          </Button>
        }
      />
      <CardContent>
        {adding && (
          <Box
            component="form"
            onSubmit={handleSubmitPhoto}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <TextField
              label="Description"
              value={photoDescription}
              onChange={(e) => setPhotoDescription(e.target.value)}
              fullWidth
            />
            <input type="file" name="photos" multiple accept="image/*" />
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
          </Box>
        )}
        <HorsePhotosGroupMUI
          horse={horse}
          onMakeMainPhoto={(data) => dispatch(setMainPhotoForHorse(data))}
          onDelete={(id) => dispatch(deleteHorsePhoto(id))}
        />
      </CardContent>
    </Card>
  );
}
