import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteHorseVideoLink } from '../../../../../redux/slices/horses/horses.slice'; // ✅ Correct thunk import
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Stack,
} from '@mui/material';
import ReactPlayer from 'react-player';
import moment from 'moment';

export default function HorseVideoCardMUI({ horseId, horseVideo }) {
  const dispatch = useDispatch();

  const getUploadDate = () =>
    horseVideo?.createdAt
      ? moment(horseVideo.createdAt).format('M/D/YY - hh:mm a')
      : 'Unknown Date';

  // ✅ Correctly dispatch thunk to delete video link
  const handleDeleteVideo = () => {
    dispatch(
      deleteHorseVideoLink({
        horseId,
        horseVideoId: horseVideo.horseVideoId,
      })
    );
  };

  return (
    <Card elevation={3} sx={{ maxWidth: 400, p: 2, mb: 2 }}>
      <CardMedia>
        {horseVideo?.amazons3URL ? (
          <ReactPlayer
            url={horseVideo.amazons3URL}
            width="100%"
            height="200px"
          />
        ) : (
          <Typography color="error">No video available</Typography>
        )}
      </CardMedia>
      <CardContent>
        <Typography variant="h6">Video: {horseVideo?.id || 'N/A'}</Typography>
        <Typography variant="body2" color="textSecondary">
          {getUploadDate()}
        </Typography>
      </CardContent>
      <Stack direction="row" justifyContent="center" sx={{ p: 1 }}>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteVideo}
        >
          Delete
        </Button>
      </Stack>
    </Card>
  );
}
