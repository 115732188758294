import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';

import {
  fetchPeople,
  //   updatePropertiesEventDivision,
} from '../../../redux/actions/users/people.actions';
import { useDispatch, useSelector } from 'react-redux';
import EventPersonForm from '../forms/EventPersonForm';

import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function EventPeople() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  //   const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPeople());
  }, [dispatch]);

  const eventPeople = useSelector((state) => state.people);
  const selectedPerson = useSelector((state) =>
    state.people.find((x) => x.id === selected)
  );
  const user = useSelector((state) => state.auth);

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleClick = (event, id) => {
    // if (!addingTest) {
    setSelected(id);
    // }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const isSelected = (id) => selected === id;

  function mapBody(eventPeople) {
    if (eventPeople && eventPeople.length > 0) {
      const tableBody = eventPeople.map((person) => {
        const isItemSelected = isSelected(person.id);
        return (
          <Fragment key={person.id}>
            <TableRow
              key={person.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, person.id)}
              selected={isItemSelected}
            >
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open && isItemSelected ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {person.id}
              </TableCell>
              <TableCell>{person.firstName}</TableCell>
              <TableCell>{person.lastName}</TableCell>
              <TableCell>{person.email}</TableCell>
              <TableCell>
                <Stack>
                  <Button
                    aria-label="addTest"
                    onClick={(event) => handleEditClick(event, person.id)}
                    endIcon={<Edit />}
                    variant="outlined"
                    color="success"
                  >
                    Edit
                  </Button>
                </Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse
                  in={open && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Phone</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Zip</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={person.id}>
                          <TableCell>{person.phone}</TableCell>
                          <TableCell>{person.address}</TableCell>
                          <TableCell>{person.city}</TableCell>
                          <TableCell>{person.state}</TableCell>
                          <TableCell>{person.zip}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showEventPersonForm() {
    if (selected && selected > 0) {
      if (selectedPerson && editing) {
        return (
          <EventPersonForm
            user={user}
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            person={selectedPerson}
          />
        );
      }
    } else if (creating) {
      return (
        <EventPersonForm
          user={user}
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
        />
      );
    }
  }

  return (
    <>
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title="People"
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Id</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Properties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(eventPeople)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showEventPersonForm()}
      </Card>
    </>
  );
}

export default EventPeople;
