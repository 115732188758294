import React from 'react';
import { Form } from 'react-final-form';
import {
    finalTextField,
    finalBoolField,
  finalObjSelector,
  renderFormTitle,
  okCancel,
  createOptionsList,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createProductItem,
  updateProductItem,
} from '../../../redux/actions/store/productItems.actions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ProductItemForm = ({ productItem, products, onOkClick, onCancelClick }) => {
  const dispatch = useDispatch();

  function submitProductItem(productItem) {
    if (productItem && productItem.id) {
      return dispatch(updateProductItem(productItem)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createProductItem(productItem)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: productItem && productItem.id ? productItem.id : null,
    productId: productItem && productItem.productId ? productItem.productId : 0,
    sku: productItem && productItem.sku ? productItem.sku : '',
    quantity: productItem && productItem.quantity ? productItem.quantity : 0,
    productImage: productItem && productItem.productImage ? productItem.productImage : '',
    price: productItem && productItem.price ? productItem.price : 0.0,
    unitCost: productItem && productItem.unitCost ? productItem.unitCost : 0.0,
    isService: productItem && productItem.isService ? productItem.isService > 0 : false,
    dataStatus: productItem && productItem.dataStatus ? productItem.dataStatus : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (productItem) {
      Object.assign(productItem, values);
      submitProductItem(productItem);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitProductItem(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      {renderFormTitle(productItem, 'ProductItem')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalObjSelector('productId', 'Product', createOptionsList(products))}
            {finalTextField('sku', 'Sku')}
            {finalTextField('quantity', 'Quantity')}
            {finalTextField('productImage', 'Product Image')}
            {finalTextField('price', 'Price')}
            {finalTextField('unitCost', 'Unit Cost')}
            {finalBoolField('isService', 'Is Service')}

            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
};

export default ProductItemForm;
