import React from "react";
import MediaItem from "./MediaItem";
import { Box } from "@mui/material";

export default function MediaItemGroup({ mediaFeed, auth }) {
  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", mt: 2 }}>
      {mediaFeed?.map((media, index) => (
        <MediaItem key={index} media={media} auth={auth} />
      ))}
    </Box>
  );
}
