import React from 'react';
import DivisionAdmin from './tabs/DivisionAdmin';
import TestsAdmin from './tabs/TestsAdmin';
// import EventLevelAdmin from './tabs/EventLevelAdmin';
// import DivisionTestsAdmin from './tabs/DivisionTestsAdmin';
import { Container, Tabs, Tab } from '@mui/material';

const EventSettingsPage = () => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
      <Container>
        <h1>Admin Panel</h1>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="Divisions" />
          <Tab label="Tests" />
          <Tab label="Event Levels" />
          <Tab label="Division Tests" />
        </Tabs>
        {tab === 0 && <DivisionAdmin />}
        {tab === 1 && <TestsAdmin />}
        {/* {tab === 2 && <EventLevelAdmin />}
        {tab === 3 && <DivisionTestsAdmin />} */}
      </Container>
  );
};

export default EventSettingsPage;
