/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addPhotoToJump,
  deleteJumpPhoto,
  setMainPhotoForJump,
} from '../../../../redux/actions/jump.actions';
import { Form, Card, Button } from 'react-bootstrap';
import { textField, photoUploader } from '../../../core/CoreForms';
import JumpPhotosGroup from './JumpPhotosGroup';
import { PlusCircle } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';

class JumpPhotoManagementPanel extends Component {
  constructor(props, context) {
    super(props, context);
    // this.handleDeleteJumpPhoto = this.handleDeleteJumpPhoto.bind(this);
    this.handleMakeMainPhoto = this.handleMakeMainPhoto.bind(this);
    this.handleSubmitPhoto = this.handleSubmitPhoto.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.state = {
      id: this.props.jump ? this.props.jump.id : null,
      userId: this.props.jump ? this.props.jump.userId : null,
      photoDescription: '',
      photoURL: this.props.jump
        ? this.props.jump.photoURL
        : '/images/jumps/jump_avatar.jpg',
      errors: '',
      loading: false,
      adding: false,
    };
    // this.handleMakeMainPhoto = this.handleMakeMainPhoto.bind(this);
  }

  handleClick = (e) => {
    this.setState({
      adding: !this.state.adding,
    });
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmitPhoto = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { photoDescription } = this.state;
    if (!(this.props.jump && this.props.jump.id)) {
      return;
    }
    const data = new FormData();
    data.append('description', photoDescription);
    data.append('title', '');
    data.append('userId', this.props.auth.id);
    data.append('jumpId', this.props.jump.id);
    data.append('uuid', uuidv4());

    for (
      let i = 0, numFiles = e.target.photos.files.length;
      i < numFiles;
      i++
    ) {
      let file = e.target.photos.files[i];
      data.append('photos', file, file.name);
    }

    this.setState({ photoDescription: '', loading: true });
    e.target.photos.value = null; // blanks the file
    this.props
      .addPhotoToJump(data)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleMakeMainPhoto = ({ jumpId, photoId }) => {
    // id = jumpId
    //const { id } = this.state;
    this.setState({ loading: true });
    this.props
      .setMainPhotoForJump({
        jumpId,
        photoId,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  //   handleDeleteJumpPhoto = ({ jumpId, jumpPhotoId }) => {
  //     // id = jumpId
  //     //const { id } = this.state;
  //     this.setState({ loading: true });
  //     this.props
  //       .deleteJumpPhoto({
  //         jumpId,
  //         jumpPhotoId,
  //       })
  //       .catch((err) =>
  //         err.response
  //           .json()
  //           .then(({ errors }) => this.setState({ errors, loading: false }))
  //       );
  //   };

  getForm() {
    if (this.state.adding) {
      return (
        <div>
          <h3>Add Photo</h3>
          <Form onSubmit={this.handleSubmitPhoto}>
            {textField('photoDescription', 'Description', this)}
            {photoUploader()}
            <Button type="submit" variant="info">
              Submit
            </Button>
          </Form>
        </div>
      );
    }
  }

  /* eslint-disable max-len */
  render() {
    var photosPanel;

    if (this.props.jump) {
      photosPanel = (
        <JumpPhotosGroup
          jump={this.props.jump}
          onMakeMainPhoto={this.handleMakeMainPhoto}
          onDelete={this.handleDeleteJumpPhoto}
          auth={this.props.auth}
        />
      );
    } else {
      photosPanel = <label>DocPanelLabel</label>;
    }

    return (
      <Card bg="danger">
        <Card.Header>
          <h2>
            Photos&nbsp;&nbsp;
            <Button variant="danger" onClick={this.handleClick}>
              <PlusCircle />
            </Button>
          </h2>
        </Card.Header>
        <Card.Body>{this.getForm()}</Card.Body>
        <Card.Footer>{photosPanel}</Card.Footer>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  addPhotoToJump,
  deleteJumpPhoto,
  setMainPhotoForJump,
})(JumpPhotoManagementPanel);
