/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  saveBarn,
  fetchBarn,
  updateBarn,
} from '../../../redux/actions/users/barn.actions';
import { redirect } from 'react-router';
import BarnForm from './core/BarnForm';
/* eslint-enable no-unused-vars */

class BarnUsers extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    if (this.props.match.params._id) {
      this.props.fetchBarn(this.props.match.params._id);
    }
  };

  saveBarn = ({
    _id,
    name,
    owner,
    breed,
    gender,
    color,
    birthYear,
    highestLevelCompeted,
    specialInformation,
  }) => {
    if (_id) {
      return this.props
        .updateBarn({
          _id,
          name,
          owner,
          breed,
          gender,
          color,
          birthYear,
          highestLevelCompeted,
          specialInformation,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    } else {
      return this.props
        .saveBarn({
          name,
          owner,
          breed,
          gender,
          color,
          birthYear,
          highestLevelCompeted,
          specialInformation,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    return (
      <div>
        {this.state.redirect ? (
          redirect("/barns")
        ) : (
          <BarnForm barn={this.props.barn} saveBarn={this.saveBarn} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params._id) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  barn: state.barns.find((item) => item._id === props.match.params._id),
      barn: state.barns[props.match.params._id],
    };
  }

  return { barn: null };
}

export default connect(
  mapStateToProps,
  { saveBarn, fetchBarn, updateBarn }
)(BarnUsers);
