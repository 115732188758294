import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle } from 'react-feather';

// import {
//   fetchEventDivisions,
//   updatePropertiesEventDivision,
// } from '../../../redux/actions/Events/entry.actions';
import {
  fetchEventDivisionsByEventId,
  // updateEventDivision,
} from '../../../redux/slices/events/eventDivisions.slice';
import { useDispatch, useSelector } from 'react-redux';
import EventDivisionForm from '../forms/EventDivisionForm';
import EventDivisionTableRow from './tabs/components/EventDivisionTableRow';
import SelectTestForm from '../forms/SelectTestForm';
// import EventTest from '../core/EventTest';

// import ColorProperties from '../core/ColorProperties';

import {
  // Button,
  Card as MuiCard,
  CardHeader,
  IconButton,
  // Chip as MuiChip,
  Paper as MuiPaper,
  // Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

// const Chip = styled(MuiChip)`
//   height: 20px;
//   padding: 4px 0;
//   font-size: 90%;
//   background-color: ${(props) =>
//     props.theme.palette[props.color ? props.color : 'primary'].light};
//   color: ${(props) => props.theme.palette.common.white};
// `;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function EventDivisionsPage() {
  const eventId = 181;

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventDivisionsByEventId(eventId));
    }
  }, [dispatch, eventId]);

  const eventDivisions = useSelector(
    (state) => state.eventDivisions.eventData[eventId]
  );

  const eventDivision = eventDivisions
    ? eventDivisions.find((x) => x.id === selected)
    : {};

  const selectedEvent = useSelector((state) => state.events[115]);

  // function typeToChip(type) {
  //   if (type === 0) {
  //     return <Chip label="Unassigned" color="error" />;
  //   } else if (type === 1) {
  //     return <Chip label="Horse Trials" color="success" />;
  //   } else if (type === 2) {
  //     return <Chip label="Combined Test" color="info" />;
  //   } else if (type === 3) {
  //     return <Chip label="Dressage Only" color="warning" />;
  //   } else {
  //     return '';
  //   }
  // }

  const handleAddTestClick = (event, id) => {
    setSelected(id);
    setAddingTest(!addingTest);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleClick = (event, id) => {
    if (!addingTest) {
      setSelected(id);
      setCreating(false);
    }
  };

  const handleAddCancelClick = (event) => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setSelected(-1);
  };

  const isSelected = (id) => selected === id;

  // function eventTestsToUI(tests) {
  //   if (tests) {
  //     return (
  //       <>
  //         {tests.map(function (test) {
  //           return <EventTest testData={test} key={test.eventTestId} />;
  //         })}
  //       </>
  //     );
  //   }
  // }  

  function mapBody(eventDivisions) {
    if (eventDivisions && eventDivisions.length > 0) {
      return eventDivisions.map((eventDivision) => {
        const isItemSelected = isSelected(eventDivision.id);
  
        return (
          <EventDivisionTableRow
            key={eventDivision.id}
            eventDivision={eventDivision}
            isSelected={isItemSelected}
            handleClick={handleClick}
            handleAddTestClick={handleAddTestClick}
            addTestForm={addTestForm}
          />
        );
      });
    }
  }
  

  function showEventDivisionForm() {
    if (selected && selected > 0) {
      if (eventDivision && !addingTest) {
        return (
          <EventDivisionForm
            onOkClick={handleFormOkClick}
            eventDivision={eventDivision}
          />
        );
      }
    } else if (creating) {
      return <EventDivisionForm onOkClick={handleFormOkClick} />;
    }
  }

  function addTestForm(isSelected) {
    if (isSelected) {
      if (addingTest) {
        return (
          <SelectTestForm
            onOkClick={handleFormOkClick}
            eventDivisionId={selected}
          />
        );
      }
    }
  }

  const title = selectedEvent
    ? 'Divisions for ' + selectedEvent.title
    : 'Divisions for';

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddCancelClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title={title}
      />
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Tests</TableCell>
                <TableCell>Properties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(eventDivisions)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      {/* {showAddTestForm()} */}
      {showEventDivisionForm()}
    </Card>
  );
}

export default EventDivisionsPage;
