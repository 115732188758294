import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle, Edit } from 'react-feather';

import { useSelector, useDispatch } from 'react-redux';
import UserHorseForm from '../forms/UserHorseForm';
import HorsePedigree from '../../customers/horses/core/HorsePedigree';
import HorsePictures from '../../customers/horses/core/HorsePictures';
import HorseVideos from '../../customers/horses/core/HorseS3Videos';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import { createOculusEvent } from '../../../redux/actions/oculus/oculusEvent.actions';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function FavoriteHorses({ horses, barnId, sale, auth }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPedigree, setOpenPedigree] = React.useState(false);
  const [openPictures, setOpenPictures] = React.useState(false);
  const [openVideos, setOpenVideos] = React.useState(false);
  
  const selectedHorse = useSelector((state) =>
    state.horses.find((x) => x.id === selected)
  );
  const barns = useSelector((state) => state.barns);

  function pedigreeEmptyOrPartial(horse) {
    return JSON.stringify(horse.pedigree).toLowerCase().includes('unknown');
  }

  function getPedigreeBadge(horse) {
    if (horse && horse.pedigree && !pedigreeEmptyOrPartial(horse)) {
      return (
        <Chip label="Pedigree" color="info" 
        onClick={(event) => handleShowPedigreeClick(event, horse.id)}
         />
      );
    }
    return '';
  }

  function getPhotoLabel(horse) {
    if (horse && horse.photos) {
      return horse.photos.length + ' Pictures';
    } else {
      return '';
    }
  }

  function getVideosLabel(horse) {
    if (horse && horse.videos) {
      return horse.videos.length + ' Videos';
    } else {
      return '';
    }
  }

  function getDocumentsLabel(horse) {
    if (horse && horse.documents) {
      return horse.documents.length + ' Documents';
    } else {
      return '';
    }
  }

  function getPhotosVideosBadges(horse) {
    if (horse) {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {horse.photos ? (
            <Chip
              label={getPhotoLabel(horse)}
              color="error"
              onClick={(event) => handleShowPicturesClick(event, horse.id)}
            />
          ) : (
            ''
          )}
          {horse.videos ? (
            <Chip
              label={getVideosLabel(horse)}
              color="warning"
              onClick={(event) => handleShowVideosClick(event, horse.id)}
            />
          ) : (
            ''
          )}
          {horse.documents ? (
            <Chip label={getDocumentsLabel(horse)} color="info" />
          ) : (
            ''
          )}
        </Stack>
      );
    }
    return '';
  }

  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return <Box component="img" src={photoURL} alt="Avatar" />;
    } else {
      return (
        <Box component="img" src="/images/photos/blank.png" alt="Avatar" />
      );
    }
  }

  function getHorsePhoto(horse) {
    if (horse && horse.hasPhoto()) {
      return renderImgTag(horse.getMainPhotoURLThumb());
    }
    return (
      <div>
        <div>
          <Box
            component="img"
            src="/images/horses/horse_avatar_thumb.jpg"
            alt="Avatar"
          />
        </div>
      </div>
    );
  }

  function getIdChip(horse) {
    if (horse.isSaleHorse) {
      return <Chip label={horse.id} color="success" />;
    } else {
      return <Chip label={horse.id} color="error" />;
    }
  }

  function genderToChip(type) {
    if (type === 0) {
      return <Chip label="Unassigned" color="warning" />;
    } else if (type === 1) {
      return <Chip label="Gelding" color="info" />;
    } else if (type === 2) {
      return <Chip label="Mare" color="error" />;
    } else if (type === 3) {
      return <Chip label="Stallion" color="success" />;
    } else {
      return '';
    }
  }

  const handleClick = (event, id) => {
    setSelected(id);
    setOpen(!open);
    setOpenPedigree(false);
    setOpenPictures(false);
    setOpenVideos(false);
  };

  const handleAddClick = (event) => {
    if (!sale) {
      setSelected(-1);
      setEditing(false);
      setCreating(!creating);
    }
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleShowPedigreeClick = (event, id) => {
    event.stopPropagation();
    setOpenPedigree(!openPedigree);
    setSelected(id);
    setOpen(false);
    setOpenPictures(false);
    setOpenVideos(false);
  };

  const handleShowPicturesClick = (event, id) => {
    setOpenPictures(!openPictures);
    setSelected(id);
    setOpen(false);
    setOpenPedigree(false);
    setOpenVideos(false);
    event.stopPropagation(); /// commenting this sets id
  };

  const handleShowVideosClick = (event, id) => {
    setOpenVideos(!openVideos);
    setSelected(id);
    setOpenPictures(false);
    setOpen(false);
    setOpenPedigree(false);
    event.stopPropagation(); /// commenting this sets id
  };

  function oculusData(horse) {
    return {
      dataType: 'horse',
      horseId: horse.id,
      name: horse.name,
    };
  }

  function oculusEvent(sender, type, horse) {
    return {
      userId: auth ? auth.id : null,
      sender,
      session: window.sessionStorage.getItem('SID'),
      type,
      data: oculusData(horse),
    };
  }

  const handleEditClick = (event, horse) => {
    setSelected(horse.id);
    dispatch(createOculusEvent(oculusEvent('Favorites.Manage', 4, horse)));
    if (horse.id) navigate('/horses/manage/' + horse.id);
  };

  const handleViewSaleClick = (event, horse) => {
    setSelected(horse.id);
    dispatch(createOculusEvent(oculusEvent('Favorites.ViewSale', 4, horse)));

    if (horse.id) navigate('/sales/horse/' + horse.id);
  };

  function getActionButton(horse) {
    if (!sale) {
      return (
        <Button
          aria-label="addTest"
          onClick={(event) => handleEditClick(event, horse)}
          endIcon={<Edit />}
          variant="outlined"
          color="success"
        >
          Manage
        </Button>
      );
    } else {
      return (
        <Button
          aria-label="viewSale"
          onClick={(event) => handleViewSaleClick(event, horse)}
          endIcon={<Edit />}
          variant="outlined"
          color="success"
        >
          Sale Page
        </Button>
      );
    }
  }

  const isSelected = (id) => selected === id;

  function mapBody(horses) {
    if (horses && horses.length > 0) {
      const tableBody = horses.map((horse) => {
        const isItemSelected = isSelected(horse.id);
        return (
          <Fragment key={horse.id}>
            <TableRow
              key={horse.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, horse.id)}
              selected={isItemSelected}
            >
              <TableCell>{getHorsePhoto(horse)}</TableCell>
              <TableCell component="th" scope="row">
                {getIdChip(horse)}
              </TableCell>
              <TableCell>{horse.name}</TableCell>
              <TableCell>{getPhotosVideosBadges(horse)}</TableCell>
              <TableCell>{getPedigreeBadge(horse)}</TableCell>
              <TableCell>{genderToChip(horse.gender)}</TableCell>
              <TableCell>
                <Stack>{getActionButton(horse)}</Stack>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse
                  in={open && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Details
                    </Typography>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Breed</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Level</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={horse.id}>
                          <TableCell>{horse.breed}</TableCell>
                          <TableCell>{horse.color}</TableCell>
                          <TableCell>{horse.levelCompeting}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse
                  in={openPedigree && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <HorsePedigree horse={horse} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse
                  in={openPictures && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <HorsePictures horse={horse} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={8}
              >
                <Collapse
                  in={openVideos && isItemSelected}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ margin: 1 }}>
                    <HorseVideos horse={horse} />
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showHorseForm() {
    if (selected && selected > 0) {
      if (selectedHorse && editing) {
        return (
          <UserHorseForm
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            horse={selectedHorse}
            barns={barns}
            barnId={barnId}
          />
        );
      }
    } else if (creating) {
      return (
        <UserHorseForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
          barns={barns}
          barnId={barnId}
        />
      );
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title="Horses"
      />
      {showHorseForm()}
      <Paper>
        <TableWrapper>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Photo</TableCell>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                {!sale ? <TableCell>Barn</TableCell> : null}
                <TableCell>Media</TableCell>
                <TableCell>Pedigree</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>Properties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(horses)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default FavoriteHorses;
