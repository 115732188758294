import { Person } from './Person.class';
import _ from 'lodash';

export default class User {
  constructor(
    id,
    email,
    firstName,
    lastName,
    displayName,
    cell,
    authorized,
    verified,
    perms,
    person,
    favorites
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.displayName = displayName;
    this.cell = cell;
    this.authorized = authorized;
    this.verified = verified;
    this.perms = perms;
    if (person && person[0]) {
      this.person = new Person(
        person[0].id,
        person[0].userId,
        person[0].email,
        person[0].firstName,
        person[0].lastName,
        person[0].phone,
        person[0].address,
        person[0].city,
        person[0].state,
        person[0].zipCode,
        person[0].gender,
        person[0].personType,
        person[0].useaNumber,
        person[0].usefNumber,
        person[0].highestLevelCompeted,
        person[0].dataStatus
      );
    } else {
      this.person = null;
    }
    this.favorites = favorites;
  }

  display() {
    return this.firstName + ' ' + this.lastName;
  }

  displayComma() {
    return this.lastName + ', ' + this.firstName;
  }

  displayPerson() {
    if (this.person) return this.person.display();
    return '';
  }

  checkHasPermission(permission) {
    if (this.perms) {
      return this.perms.findIndex((x) => x.permission === permission) > -1;
    }
    return false;
  }

  isAdmin() {
    return this.checkHasPermission('AEGON');
  }

  isFavorite(id) {
    if(this.favorites) {
      return _.find(this.favorites, function (o) {
        return o.horseId === id;
      });
    } else return false;
  }
}
