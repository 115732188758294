import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEvents } from '../../../../../redux/actions/Events/event.actions';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const EventSelect = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events || []);

  useEffect(() => {
    console.log('event select');
    dispatch(fetchEvents());
  }, [dispatch]);

  // Get the threshold date (two days ago)
  const twoDaysAgo = dayjs().subtract(2, 'days').startOf('day');

  // Filter events newer than two days ago
  const filteredEvents = events.filter((event) => {
    const eventDate = dayjs.utc(event.startDate).startOf('day');
    return eventDate.isAfter(twoDaysAgo);
  });

  function formatId(event) {
    return '[' + event.id + '] ';
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Select Event</InputLabel>
      <Select value={value} onChange={onChange} displayEmpty>
        <MenuItem value="" disabled>
          Select Event
        </MenuItem>
        {filteredEvents.map((event) => (
          <MenuItem key={event.id} value={event.id}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography noWrap sx={{ maxWidth: '10%' }}>
                {formatId(event)}
              </Typography>
              <Typography noWrap sx={{ maxWidth: '60%' }}>
                {event.title.length > 50
                  ? `${event.title.substring(0, 50)}...`
                  : event.title}
              </Typography>
              <Typography sx={{ color: 'gray' }}>
                {dayjs(event.startDate).format('MMM DD, YYYY')}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EventSelect;
