import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { deleteHorseS3VideoLink } from '../../../../redux/slices/horses/horses.slice'; // ✅ Correct thunk import
import { Card, CardContent, Typography, Box, Button } from '@mui/material';

/**
 * Component to display an S3 video using Material-UI.
 * @param {Object} s3Video - An instance of the S3Video class containing video metadata.
 * @param {number} horseId - ID of the horse associated with the video.
 */
const S3VideoPlayer = ({ s3Video, horseId }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      console.log(`Loading video: ${s3Video.amazons3URL}`);
      videoRef.current.load(); // ✅ Force video reload
    }
  }, [s3Video.amazons3URL]);

  if (!s3Video || !s3Video.amazons3URL) {
    return <Typography color="error">No video available.</Typography>;
  }

  // ✅ Correct delete function calling `deleteHorseS3VideoLink`
  const handleDeleteVideo = () => {
    dispatch(
      deleteHorseS3VideoLink({
        horseId,
        horseS3VideoId: s3Video.horseS3VideoId,
      })
    );
  };

  return (
    <Card sx={{ maxWidth: 800, mx: 'auto', p: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {s3Video.title || 'Untitled Video'}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
          {s3Video.description || 'No description available.'}
        </Typography>

        <Box sx={{ position: 'relative', borderRadius: 2, overflow: 'hidden' }}>
          <video
            ref={videoRef}
            key={s3Video.amazons3URL} // ✅ Forces React to reload video when URL changes
            controls
            crossOrigin="anonymous"
            preload="auto"
            playsInline
            referrerPolicy="no-referrer"
            style={{
              width: '100%',
              borderRadius: '8px',
              backgroundColor: 'black',
            }}
          >
            <source
              src={s3Video.amazons3URL}
              type="video/mp4"
              crossOrigin="anonymous"
            />
            Your browser does not support the video tag.
          </video>
        </Box>

        <Typography
          variant="caption"
          color="textSecondary"
          sx={{ mt: 1, display: 'block' }}
        >
          Uploaded by User ID: {s3Video.userId} | Aspect Ratio:{' '}
          {s3Video.aspectRatio}
        </Typography>

        {/* ✅ Delete Button - Calls correct thunk */}
        <Button
          variant="contained"
          color="error"
          sx={{ mt: 2 }}
          onClick={handleDeleteVideo}
        >
          Delete Video
        </Button>
      </CardContent>
    </Card>
  );
};

export default S3VideoPlayer;
