import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDocToHorse } from '../../../../../redux/actions/horse.actions';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  MenuItem,
} from '@mui/material';
import HorseDocumentGroupMUI from './HorseDocumentGroupMUI';

const DOCUMENT_CATEGORIES = [
  { label: 'General', value: 0 },
  { label: 'Coggins', value: 1 },
  { label: 'Vet Document', value: 2 },
  { label: 'Health Cert', value: 3 },
  { label: 'Pedigree', value: 4 },
  { label: 'Contract', value: 5 },
];

export default function HorseDocumentManagementPanelMUI({ horse }) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [adding, setAdding] = useState(false);
  const [docTitle, setDocTitle] = useState('');
  const [docDescription, setDocDescription] = useState('');
  const [docType, setDocType] = useState(0);

  const handleSubmitDocument = (e) => {
    e.preventDefault();
    if (!horse?.id) return;

    const data = new FormData();
    data.append('description', docDescription);
    data.append('title', docTitle);
    data.append('category', docType);
    data.append('userId', auth.id);
    data.append('horseId', horse.id);

    for (let i = 0; i < e.target.docs.files.length; i++) {
      data.append('docs', e.target.docs.files[i]);
    }

    setDocTitle('');
    setDocDescription('');
    setDocType(0);
    setAdding(false);
    dispatch(addDocToHorse(data));
  };

  return (
    <Card elevation={3}>
      <CardHeader
        title="Documents"
        action={
          <Button
            variant="contained"
            color="primary"
            onClick={() => setAdding(!adding)}
          >
            Add Document
          </Button>
        }
      />
      <CardContent>
        {adding && (
          <Box
            component="form"
            onSubmit={handleSubmitDocument}
            sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          >
            <TextField
              label="Title"
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={docDescription}
              onChange={(e) => setDocDescription(e.target.value)}
              fullWidth
            />
            <TextField
              select
              label="Document Type"
              value={docType}
              onChange={(e) => setDocType(e.target.value)}
              fullWidth
            >
              {DOCUMENT_CATEGORIES.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <input
              type="file"
              name="docs"
              multiple
              accept="application/pdf,application/msword,image/*"
            />
            <Button type="submit" variant="contained" color="success">
              Submit
            </Button>
          </Box>
        )}
        <HorseDocumentGroupMUI horse={horse} />
      </CardContent>
    </Card>
  );
}
