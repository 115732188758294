import React from 'react';

interface UserProfileProps {
  firstName: string;
  lastName: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ firstName, lastName }) => {
  return (
    <div>
      <h1>User Profile</h1>
      <p>First Name: {firstName}</p>
      <p>Last Name: {lastName}</p>
    </div>
  );
};

export default UserProfile;
