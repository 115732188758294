import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = '/api/posts';
const CREATE_POST_API_URL = '/api/media/posts/upload';

// Async Thunks
export const fetchPosts = createAsyncThunk('posts/fetchAll', async () => {
  const response = await axios.get(API_URL);
  return response.data;
});

export const createPost = createAsyncThunk('posts/create', async (values) => {
  // values is expected to be a FormData instance containing media and other post fields
  const response = await axios.post(CREATE_POST_API_URL, values, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response.data;
});

const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    feed: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.feed = action.payload;
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.feed.unshift(action.payload); // Add new post at the top
      });
  },
});

export default postsSlice.reducer;
