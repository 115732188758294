import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunks
export const fetchDivisions = createAsyncThunk('divisions/fetch', async () => {
  const response = await axios.get('/api/divisions');
  return response.data;
});

export const addDivision = createAsyncThunk(
  'divisions/add',
  async (division) => {
    const response = await axios.post('/api/divisions', division);
    return response.data;
  }
);

export const updateDivision = createAsyncThunk(
  'divisions/update',
  async (division) => {
    const { id, ...data } = division;
    await axios.put(`/api/divisions/${id}`, data);
    return { id, ...data };
  }
);

export const deleteDivision = createAsyncThunk(
  'divisions/delete',
  async (id) => {
    await axios.delete(`/api/divisions/${id}`);
    return id;
  }
);

// Handle adding/removing tests and returning updated division
export const addTestToDivision = createAsyncThunk(
  'divisions/addTest',
  async ({ divisionId, testId }) => {
    const response = await axios.post('/api/divisionTests', {
      divisionId,
      testId,
    });
    return response.data; // Returns updated division object
  }
);

export const removeTestFromDivision = createAsyncThunk(
  'divisions/removeTest',
  async ({ id, divisionId }) => {
    const response = await axios.delete(
      `/api/divisionTests/${id}/${divisionId}`
    );
    return response.data; // Returns updated division object
  }
);

// Slice
const divisionsSlice = createSlice({
  name: 'divisions',
  initialState: { data: [], status: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDivisions.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
      })
      .addCase(addDivision.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(updateDivision.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (division) => division.id === action.payload.id
        );
        if (index !== -1) state.data[index] = action.payload;
      })
      .addCase(deleteDivision.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (division) => division.id !== action.payload
        );
      })
      .addCase(addTestToDivision.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (division) => division.id === action.payload.id
        );
        if (index !== -1) state.data[index] = action.payload;
      })
      .addCase(removeTestFromDivision.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (division) => division.id === action.payload.id
        );
        if (index !== -1) state.data[index] = action.payload;
      });
  },
});

export default divisionsSlice.reducer;
