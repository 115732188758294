import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalTextArea,
  finalDateField,
  finalObjSelector,
  renderFormTitle,
  okCancel,
} from '../../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createTask,
  updateTask,
} from '../../../../redux/actions/task.actions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const TaskForm = ({ task, auth, onOkClick }) => {
  const dispatch = useDispatch();

  function submitTask(task) {
    if (task && task.id) {
      return dispatch(updateTask(task)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createTask(task)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: task && task.id ? task.id : null,
    userId: auth && auth.id ? auth.id : null,
    title: task && task.title ? task.title : '',
    type: task && task.type ? task.type : 0,
    priority: task && task.priority ? task.priority : 0,
    notes: task && task.notes ? task.notes : '',
    status: task && task.status ? task.status : 0,
    due: task && task.due ? Date.parse(task.due) : new Date(),
    dataStatus: task && task.dataStatus ? task.dataStatus : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (task) {
      Object.assign(task, values);
      submitTask(task);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitTask(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  const typeOptionsList = [
    { key: 'General', value: '0' },
    { key: 'Billing', value: '1' },
    { key: 'Ride', value: '2' },
    { key: 'Farrior', value: '3' },
    { key: 'Vet', value: '4' },
    { key: 'Event', value: '5' },
  ];

  const priorityOptionsList = [
    { key: 'None', value: '0' },
    { key: 'High', value: '1' },
    { key: 'Medium', value: '2' },
    { key: 'Low', value: '3' },
  ];

  const statusOptionsList = [
    { key: 'New', value: '0' },
    { key: 'Acknowledged', value: '1' },
    { key: 'In Progress', value: '2' },
    { key: 'Completed', value: '3' },
    { key: 'Cancelled', value: '4' },
  ];

  return (
    <div>
      {renderFormTitle(task, 'Task')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {/* {finalTextField('userId', 'User Id')} */}
            {finalTextField('title', 'Title')}
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalObjSelector('priority', 'Priority', priorityOptionsList)}
            {finalTextArea('notes', 'Notes')}
            {finalObjSelector('status', 'Status', statusOptionsList)}
            {finalDateField('due', 'Due')}

            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
};

export default TaskForm;
