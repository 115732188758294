import axios from 'axios';
import {
  DIVISIONS_FETCHED,
  DIVISION_CREATED,
  DIVISION_UPDATED,
  EVENT_LEVELS_FETCHED,
  EVENT_DIVISIONS_FETCHED,
  EVENT_DIVISION_CREATED,
  EVENT_DIVISION_UPDATED,
  EVENT_TESTS_FETCHED,
  EVENT_TEST_CREATED,
  EVENT_TEST_UPDATED,
  ENTRIES_FETCHED,
  ENTRY_CREATED,
  ENTRY_UPDATED,
  USER_ENTRY_FETCHED,
  USER_ENTRIES_FETCHED,
} from '../types';
import { entriesToClassArray } from '../../classes/Entry.class';

export function blocksFetched(blocks) {
  return {
    type: 'BLOCKS_FETCHED',
    blocks,
  };
}

export function blocksFetchedForRing(blocks) {
  return {
    type: 'BLOCKS_FETCHED_FOR_RING',
    blocks,
  };
}

export function divisionsFetched(divisions) {
  return {
    type: DIVISIONS_FETCHED,
    divisions,
  };
}

// export function divisionFetched(division) {
//   return {
//     type: DIVISION_FETCHED,
//     division,
//   };
// }

export function divisionCreated(division) {
  return {
    type: DIVISION_CREATED,
    division,
  };
}

export function divisionUpdated(division) {
  return {
    type: DIVISION_UPDATED,
    division,
  };
}

export function eventDivisionsFetched(eventDivisions) {
  return {
    type: EVENT_DIVISIONS_FETCHED,
    eventDivisions,
  };
}

export function eventDivisionCreated(eventDivision) {
  return {
    type: EVENT_DIVISION_CREATED,
    eventDivision,
  };
}

export function eventDivisionUpdated(eventDivision) {
  return {
    type: EVENT_DIVISION_UPDATED,
    eventDivision,
  };
}

export function eventTestsFetched(eventTests) {
  return {
    type: EVENT_TESTS_FETCHED,
    eventTests,
  };
}

export function eventTestCreated(eventTest) {
  return {
    type: EVENT_TEST_CREATED,
    eventTest,
  };
}

export function eventTestUpdated(eventTest) {
  return {
    type: EVENT_TEST_UPDATED,
    eventTest,
  };
}

export function eventLevelsFetched(eventLevels) {
  return {
    type: EVENT_LEVELS_FETCHED,
    eventLevels,
  };
}

// Entries

export function entriesFetched(entries) {
  return {
    type: ENTRIES_FETCHED,
    entries: entriesToClassArray(entries),
  };
}

export function entryCreated(entry) {
  return {
    type: ENTRY_CREATED,
    entry,
  };
}

export function entryUpdated(entry) {
  return {
    type: ENTRY_UPDATED,
    entry,
  };
}

// UserEntries

export function userEntriesFetched(userEntries) {
  return {
    type: USER_ENTRIES_FETCHED,
    userEntries,
  };
}

export function userEntryCreated(userEntry) {
  return {
    type: USER_ENTRY_FETCHED,
    userEntry,
  };
}

export function userEntryUpdated(userEntry) {
  return {
    type: USER_ENTRY_FETCHED,
    userEntry,
  };
}

export const fetchBlocks = (eventId) => async (dispatch) => {
  const res = await axios.get('/api/blocks/read/grouped/event/' + eventId);
  // const res = await axios.get('/api/blocks/read/event/' + eventId);
  dispatch(blocksFetched(res.data));
};

export const updateSchedule = (values) => async (dispatch) => {
  await axios.post('/api/schedule/update', values);
  // dispatch(jumpFetched(res.data));
};

export const createScheduleBlock = (values) => async (dispatch) => {
  await axios.post('/api/schedule/update', values);
  // dispatch(jumpFetched(res.data));
};

export const updateBlocks = (values) => async (dispatch) => {
  const res = await axios.post('/api/blocks/updateArray', values);
  dispatch(blocksFetched(res.data));
};

export const fetchDivisions = () => async (dispatch) => {
  const res = await axios.get('/api/divisions');
  dispatch(divisionsFetched(res.data));
};

export const createDivision = (values) => async (dispatch) => {
  const res = await axios.post('/api/divisions', values);
  dispatch(divisionCreated(res.data));
};

export const updateDivision = (values) => async (dispatch) => {
  const res = await axios.put('/api/divisions', values);
  dispatch(divisionUpdated(res.data));
};

/* EVENT DIVISIONS */

// export const fetchEventDivisions = () => async (dispatch) => {
//   const res = await axios.get('/api/eventDivisions/event/115');
//   dispatch(eventDivisionsFetched(res.data));
// };

// export const createEventDivision = (values) => async (dispatch) => {
//   const res = await axios.post('/api/eventDivisions/create', values);
//   dispatch(eventDivisionCreated(res.data));
// };

// export const updateEventDivision = (values) => async (dispatch) => {
//   const res = await axios.post('/api/eventDivisions/update', values);
//   dispatch(eventDivisionUpdated(res.data));
// };

// export const addTestToEventDivision = (values) => async (dispatch) => {
//   const res = await axios.post('/api/eventDivisions/addTest', values);
//   dispatch(eventDivisionUpdated(res.data));
// };

// export const updatePropertiesEventDivision = (values) => async (dispatch) => {
//   const res = await axios.post('/api/eventDivisions/update/properties', values);
//   dispatch(eventDivisionUpdated(res.data));
// };

/* EVENT TESTS */

export const fetchEventTests = () => async (dispatch) => {
  const res = await axios.get('/api/eventTests/read/');
  dispatch(eventTestsFetched(res.data));
};

export const createEventTest = (values) => async (dispatch) => {
  const res = await axios.post('/api/eventTests/create', values);
  dispatch(eventTestCreated(res.data));
};

export const updateEventTest = (values) => async (dispatch) => {
  const res = await axios.post('/api/eventTests/update', values);
  dispatch(eventTestUpdated(res.data));
};

export const fetchEventLevels = () => async (dispatch) => {
  const res = await axios.get('/api/eventLevels/read');
  dispatch(eventLevelsFetched(res.data));
};

/* USER ENTRIES */

export const fetchUserEntries = () => async (dispatch) => {
  const res = await axios.get('/api/entries/user/read/');
  dispatch(userEntriesFetched(res.data));
};

export const createUserEntry = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/user/create', values);
  dispatch(userEntryCreated(res.data));
};

export const updateUserEntry = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/user/update', values);
  dispatch(userEntryUpdated(res.data));
};

/* ENTRIES */

export const fetchEntries = () => async (dispatch) => {
  const res = await axios.get('/api/entries/read/event/115');
  dispatch(entriesFetched(res.data));
};

export const createEntry = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/create', values);
  dispatch(entryCreated(res.data));
};

export const updateEntry = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/update', values);
  dispatch(entryUpdated(res.data));
};

export const updateEntryTimes = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/update/time', values);
  // dispatch(entryUpdated(res.data));
  // console.log(res.data);
  dispatch(entriesFetched(res.data));
};

export const updateEntryTimesArray = (values) => async (dispatch) => {
  const res = await axios.post('/api/entries/times/updateArray', values);
  // console.log('aray returned');
  // console.log(res.data);
  dispatch(entriesFetched(res.data));
};
