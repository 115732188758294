import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

export default function HorsePedigreeMUI({ horse }) {
  if (!horse?.pedigree) {
    return <Typography variant="h6">Pedigree not entered.</Typography>;
  }

  const renderRow = (label, value, rowSpan = 1) => (
    <TableRow>
      <TableCell
        rowSpan={rowSpan}
        align="center"
        sx={{ verticalAlign: 'middle' }}
      >
        {value || '-'}
      </TableCell>
    </TableRow>
  );

  return (
    <Box sx={{ p: 2 }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {renderRow('Horse', horse.name, 16)}
            {renderRow('Sire', horse.pedigree.sire?.name, 8)}
            {renderRow('Sire’s Sire', horse.pedigree.siresire?.name, 4)}
            {renderRow(
              'Sire’s Sire’s Sire',
              horse.pedigree.siresiresire?.name,
              2
            )}
            {renderRow(
              'Sire’s Sire’s Sire’s Sire',
              horse.pedigree.siresiresiresire?.name
            )}
            {renderRow(
              'Sire’s Sire’s Sire’s Dam',
              horse.pedigree.siresiresiredam?.name
            )}
            {renderRow(
              'Sire’s Sire’s Dam',
              horse.pedigree.siresiredam?.name,
              2
            )}
            {renderRow(
              'Sire’s Sire’s Dam’s Sire',
              horse.pedigree.siresiredamsire?.name
            )}
            {renderRow(
              'Sire’s Sire’s Dam’s Dam',
              horse.pedigree.siresiredamdam?.name
            )}
            {renderRow('Dam', horse.pedigree.dam?.name, 8)}
            {renderRow('Dam’s Sire', horse.pedigree.damsire?.name, 4)}
            {renderRow(
              'Dam’s Sire’s Sire',
              horse.pedigree.damsiresire?.name,
              2
            )}
            {renderRow(
              'Dam’s Sire’s Sire’s Sire',
              horse.pedigree.damsiresiresire?.name
            )}
            {renderRow(
              'Dam’s Sire’s Sire’s Dam',
              horse.pedigree.damsiresiredam?.name
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
