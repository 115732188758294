import React, { useState, useEffect } from 'react';
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Table,
  TableHead,
  TableBody,
  Button,
  Stack,
  Badge,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import ColorProperties from '../../../core/ColorProperties';
import { fetchTests } from '../../../../../redux/slices/events/tests.slice';
import {
  addTestToDivision,
  removeTestFromDivision,
  updateDivision,
} from '../../../../../redux/slices/events/divisions.slice';
import { createEventDivisionFromDivisionObject } from '../../../../../redux/slices/events/eventDivisions.slice';

import AddTestToDivisionDialog from './AddTestToDivisionDialog';
import { DIVISION_TYPES } from '../../../../../constants/divisionTypes';
import { EVENT_LEVELS } from '../../../../../constants/eventLevels';
import { TEST_TYPES } from '../../../../../constants/testTypes';

const DivisionTableRow = ({ division, onEdit, onDelete, eventId }) => {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.tests.data);
  const [open, setOpen] = useState(false);
  const [testDialogOpen, setTestDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchTests());
  }, [dispatch]);

  const handleAddTest = (testId) => {
    dispatch(addTestToDivision({ divisionId: division.id, testId }));
    setTestDialogOpen(false);
  };

  const handleRemoveTest = (testId) => {
    dispatch(removeTestFromDivision({ id: testId, divisionId: division.id }));
  };

  const onAddDivisionToEvent = (division, eventId) => {
    console.log('eventId1', eventId);
    console.log('division1', division);
    dispatch(createEventDivisionFromDivisionObject({ eventId, division }));
  };

  const handleBGColorChange = (color) => {
    console.log('handleBGColorChange');
    const updatedDivision = {
      ...division,
      properties: { ...(division.properties || {}), bgColor: color }, // ✅ Create a new properties object
    };

    dispatch(updateDivision(updatedDivision));
  };

  const handleFontColorChange = (color) => {
    console.log('handleFontColorChange');
    const updatedDivision = {
      ...division,
      properties: { ...(division.properties || {}), fontColor: color }, // ✅ Create a new properties object
    };

    dispatch(updateDivision(updatedDivision));
  };

  return (
    <>
      <TableRow>
        <TableCell>{division.name}</TableCell>
        <TableCell>
          {DIVISION_TYPES.find(
            (option) => option.value === division.divisionType
          )?.key || 'Unknown'}
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: division.properties?.bgColor || '#FFFFFF',
            color: division.properties?.fontColor || '#000000',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {EVENT_LEVELS.find((option) => option.id === division.eventLevelId)
            ?.name || 'Unknown'}
        </TableCell>
        <TableCell align="center">
          <Badge
            onClick={() => setOpen(!open)}
            badgeContent={division.divisionTests?.length || 0}
            color={division.divisionTests?.length ? 'primary' : 'error'}
            showZero
          />
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography>BG Color</Typography>
              <ColorProperties
                pickColor={division.properties.bgColor}
                onColorChange={handleBGColorChange}
              />
            </Stack>
            <Stack direction="column" spacing={1} alignItems="center">
              <Typography>Font Color</Typography>
              <ColorProperties
                pickColor={division.properties.fontColor}
                onColorChange={handleFontColorChange}
              />
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
          <IconButton onClick={() => onEdit(division)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(division.id)}>
            <DeleteIcon />
          </IconButton>
          {eventId ? (
            <IconButton onClick={() => onAddDivisionToEvent(division, eventId)}>
              <AddIcon />
            </IconButton>
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>

      {/* Collapsible Row for Tests */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <h4>Tests for {division.name}</h4>
              {division.divisionTests && division.divisionTests.length > 0 ? (
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Test Name</TableCell>
                      <TableCell>Test Type</TableCell>
                      <TableCell>Minutes</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {division.divisionTests.map((divisionTest) => (
                      <TableRow key={divisionTest.id}>
                        <TableCell>{divisionTest.name}</TableCell>
                        <TableCell>
                          {TEST_TYPES.find(
                            (option) => option.value === divisionTest.testType
                          )?.key || 'Unknown'}
                        </TableCell>
                        <TableCell>{divisionTest.minutes}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              handleRemoveTest(divisionTest.divisionTestId)
                            }
                            color="error"
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <p>No tests assigned.</p>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => setTestDialogOpen(true)}
              >
                Add Test
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* Add Test Dialog */}
      <AddTestToDivisionDialog
        open={testDialogOpen}
        onClose={() => setTestDialogOpen(false)}
        onSubmit={handleAddTest}
        division={division}
        tests={tests}
      />
    </>
  );
};

export default DivisionTableRow;
