// src/constants/divisionTypes.js

export const DIVISION_TYPES = [
    { key: 'None', value: 0 },
    { key: 'Horse Trials', value: 1 },
    { key: 'Combined Test', value: 2 },
    { key: 'Dressage Only', value: 3 },
    { key: 'Jumpers', value: 4 },
    { key: 'Hunters', value: 5 },
    { key: 'Clinic', value: 6 },
    { key: 'XC Schooling', value: 7 },
    { key: 'In Hand', value: 8 },
    { key: 'Other', value: 9 },
  ];

