import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'; // MUI Icon
import moment from 'moment';
import Linkify from 'react-linkify';
import { Link } from 'react-router-dom'; // Use React Router
import Gallery from './Gallery'; // Assuming Gallery is already MUI-compatible
import { useTheme } from '@mui/material/styles'; // ✅ Use MUI Theme

export default function MediaItem({ media, auth }) {
  const theme = useTheme(); // ✅ Get the active theme

  function dateToText(date) {
    return moment(date).format('M/D/YY - hh:mm');
  }

  function getEditButton(media) {
    if (auth && media.userId === auth.id) {
      return (
        <Button
          component={Link}
          to={`/media/edit/${media.id}`}
          variant="outlined"
          color="info"
          startIcon={<EditIcon />}
          sx={{ mt: 1 }}
        >
          Edit
        </Button>
      );
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mb: 3,
        width: '100%',
      }}
    >
      <Card
        sx={{
          width: '90%', // ✅ Responsive width
          maxWidth: '1000px', // ✅ Wider for better readability
          bgcolor: theme.palette.background.paper, // ✅ Adaptive background
          border: `4px solid ${
            theme.palette.mode === 'dark'
              ? 'rgba(255,255,255,0.5)'
              : 'rgba(0,0,0,0.4)'
          }`, // ✅ Thicker border
          boxShadow:
            theme.palette.mode === 'dark'
              ? '6px 6px 15px rgba(0,0,0,0.7)' // ✅ Stronger shadow in dark mode
              : '6px 6px 15px rgba(0,0,0,0.2)', // ✅ Softer shadow in light mode
          p: 1, // ✅ More padding for readability
          borderRadius: '12px', // ✅ Rounded corners for a modern look
        }}
      >
        {/* ✅ Header Section (Background Slightly Darker for Contrast) */}
        <CardHeader
          title={media.authorName}
          titleTypographyProps={{
            sx: {
              color: theme.palette.text.primary,
              fontWeight: 'bold',
            },
          }}
          sx={{
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            p: 2,
            borderRadius: '8px 8px 0 0', // ✅ Keep header slightly rounded
          }}
        />

        {/* ✅ Divider Between Sections */}
        <Divider sx={{ bgcolor: theme.palette.divider }} />

        {/* ✅ Main Content Section */}
        <CardContent
          sx={{
            p: 3,
            bgcolor: theme.palette.background.default, // ✅ Ensures consistent background theme
          }}
        >
          <Linkify>
            <h5
              style={{
                bgcolor: theme.palette.background.default, // ✅ Ensures consistent background theme
                color: theme.palette.text.primary, // ✅ Ensures text contrasts well
                marginBottom: '10px',
              }}
            >
              {media.content}
            </h5>
          </Linkify>
          {media.photos?.length > 0 && <Gallery photos={media.photos} />}
          {getEditButton(media)}
        </CardContent>

        {/* ✅ Divider Between Sections */}
        <Divider sx={{ bgcolor: theme.palette.divider }} />

        {/* ✅ Footer Section (Subtle Contrast & Date Info) */}
        <Box
          sx={{
            p: 2,
            textAlign: 'right',
            bgcolor:
              theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,0.1)'
                : 'rgba(0,0,0,0.05)', // ✅ Subtle background for footer
            borderRadius: '0 0 8px 8px', // ✅ Keep footer slightly rounded
          }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {dateToText(media.createdAt)}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}
