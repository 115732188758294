import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardHeader,
  CardMedia,
  CardActions,
  IconButton,
  Avatar,
  Stack,
  Button,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClearIcon from '@mui/icons-material/Clear';
import { red } from '@mui/material/colors';
import moment from 'moment';

export default function HorsePhotoCardMUI({
  horse,
  photo,
  onDelete,
  onMakeMainPhoto,
}) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const getUploadDate = () =>
    photo?.createdAt
      ? moment(photo.createdAt).format('M/D/YY - hh:mm a')
      : 'Unknown Date';
  const isMainPhoto = horse?.photo?.id === photo?.id;

  const renderPhoto = () =>
    photo?.amazons3URLHalf || photo?.amazons3URL || '/images/photos/blank.png';

  return (
    <Card raised sx={{ margin: '0 auto', padding: '0.5em', maxWidth: 400 }}>
      <CardHeader
        avatar={<Avatar sx={{ bgcolor: red[500] }}>{photo.id}</Avatar>}
        title={`${horse.name} ${isMainPhoto ? '- Main' : ''}`}
        subheader={getUploadDate()}
      />
      <CardMedia
        component="img"
        image={renderPhoto()}
        alt="Horse Photo"
        sx={{ height: 200, objectFit: 'cover' }}
      />
      {auth?.checkHasPermission('HORSE_ADMIN') && (
        <CardActions>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Stack direction="row">
              <IconButton onClick={() => horse.movePhotoUp(dispatch, photo.id)}>
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => horse.movePhotoDown(dispatch, photo.id)}
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Stack>
            {!isMainPhoto && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  onMakeMainPhoto({ horseId: horse.id, photoId: photo.id })
                }
              >
                Make Main Photo
              </Button>
            )}
            <IconButton onClick={() => onDelete(photo.linkPhotoId)}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </CardActions>
      )}
    </Card>
  );
}
