import React from 'react';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { createHorseLogItem } from '../../../../../redux/actions/users/horseLog.actions';
import { Box, Button, TextField, Stack } from '@mui/material';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function HorseLogFormMUI({ horse, auth, onOkClick }) {
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    await sleep(300);
    const newData = { ...values, userId: auth.id, horseId: horse.id };
    dispatch(createHorseLogItem(newData));
    if (onOkClick) onOkClick();
  };

  return (
    <Box sx={{ p: 2 }}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ log: '' }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <TextField name="log" label="Log" multiline rows={3} fullWidth />
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button type="button" variant="outlined" onClick={onOkClick}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </form>
        )}
      />
    </Box>
  );
}
