// /store/horsesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { horseToClass, horsesToClassArray } from '../../classes/Horse.class';
import { barnsToClassArray } from '../../classes/Barn.class';

const API_URL = '/api/horses';
const BARN_API_URL = '/api/barns';
const SALE_HORSE_API_URL = '/api/horses/sale';

// Async Thunks
export const fetchHorses = createAsyncThunk('horses/fetchAll', async () => {
  const response = await axios.get(API_URL);
  return horsesToClassArray(response.data);
});

export const fetchHorseById = createAsyncThunk(
  'horses/fetchById',
  async (id) => {
    const response = await axios.get(`${API_URL}/${id}`);
    return horseToClass(response.data);
  }
);

export const fetchSaleHorses = createAsyncThunk(
  'horses/fetchSaleHorses',
  async () => {
    const response = await axios.get(SALE_HORSE_API_URL);
    return horsesToClassArray(response.data);
  }
);

export const fetchSaleHorseById = createAsyncThunk(
  'horses/fetchSaleHorseById',
  async (id) => {
    const response = await axios.get(`${SALE_HORSE_API_URL}/${id}`);
    return horseToClass(response.data);
  }
);

export const createHorse = createAsyncThunk('horses/create', async (values) => {
  const response = await axios.post(`${API_URL}/create`, values);
  return horseToClass(response.data);
});

export const updateHorse = createAsyncThunk('horses/update', async (values) => {
  const response = await axios.put(`${API_URL}/${values.id}`, values);
  return horseToClass(response.data);
});

export const deleteHorse = createAsyncThunk('horses/delete', async (id) => {
  await axios.delete(`${API_URL}/${id}`);
  return id;
});

export const fetchBarns = createAsyncThunk(
  'horses/fetchBarns',
  async (userId) => {
    const response = await axios.get(BARN_API_URL);
    return response.data;
  }
);

export const addBarnUser = createAsyncThunk(
  'horses/addBarnUser',
  async ({ barnId, userId }) => {
    const response = await axios.post(`${BARN_API_URL}/${barnId}/users`, {
      userId,
    });
    return response.data;
  }
);

export const removeBarnUser = createAsyncThunk(
  'horses/removeBarnUser',
  async ({ barnId, userId }) => {
    await axios.delete(`${BARN_API_URL}/${barnId}/users`, { data: { userId } });
    return { barnId, userId };
  }
);

export const addPhotosToHorse = createAsyncThunk(
  'horses/addPhotosToHorse',
  async (values) => {
    const response = await axios.post(`${API_URL}/photos/upload`, values);
    return horseToClass(response.data);
  }
);

export const deleteHorsePhoto = createAsyncThunk(
  'horses/deleteHorsePhoto',
  async (linkPhotoId, { dispatch }) => {
    const response = await axios.delete(`/api/horses/photos/${linkPhotoId}`);
    return horseToClass(response.data);
  }
);

export const setMainPhotoForHorse = createAsyncThunk(
  'horses/setMainPhotoForHorse',
  async ({ horseId, photoId }) => {
    const response = await axios.put('/api/horses/setMainPhoto', {
      horseId,
      photoId,
    });
    return horseToClass(response.data);
  }
);

export const deleteHorseS3VideoLink = createAsyncThunk(
  'horses/deleteHorseS3VideoLink',
  async ({ horseId, horseS3VideoId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/horses/s3Videos/delete/S3/video`,
        {
          data: { horseId, horseS3VideoId }, // ✅ Sending both IDs in request body
        }
      );

      return horseToClass(response.data); // ✅ Expecting a NEW horse object from API
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'Failed to delete video link'
      );
    }
  }
);

export const deleteHorseVideoLink = createAsyncThunk(
  'horses/deleteHorseVideoLink',
  async ({ horseId, horseVideoId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/api/horses/videos/delete/video`, {
        headers: { 'Content-Type': 'application/json' }, // ✅ Ensure JSON format
        data: { horseId, horseVideoId }, // ✅ Sending both IDs in request body
      });

      return horseToClass(response.data); // ✅ Expecting a NEW horse object from API
    } catch (error) {
      return rejectWithValue(
        error.response?.data || 'Failed to delete video link'
      );
    }
  }
);

// ✅ Async Thunk to Upload Video
export const addVideo = createAsyncThunk(
  'horses/addVideo',
  async ({ horseId, title, description, videoFile }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('horseId', horseId);
      formData.append('title', title);
      formData.append('description', description);
      formData.append('video', videoFile);

      const response = await axios.post('/api/media/videos/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data; // ✅ Return the uploaded video data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addVideoToHorse = createAsyncThunk(
  'horses/addVideoToHorse',
  async ({ horseId, title, description, URL, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/horses/videos/addVideoToHorse', {
        horseId,
        title,
        description,
        URL,
        userId,
      });

      return horseToClass(response.data); // Return the updated horse object
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add video');
    }
  }
);

const horsesSlice = createSlice({
  name: 'horses',
  initialState: {
    list: [],
    saleList: [],
    barns: [],
    status: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHorses.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(fetchHorseById.fulfilled, (state, action) => {
        const fetchedHorse = action.payload;
        const updatedData = state.list.map((item) =>
          item.id === fetchedHorse.id ? { ...item, ...fetchedHorse } : item
        );
        state.list = horsesToClassArray(updatedData);
      })
      .addCase(fetchSaleHorses.fulfilled, (state, action) => {
        state.saleList = action.payload;
      })
      .addCase(fetchSaleHorseById.fulfilled, (state, action) => {
        const updatedData = state.saleList.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        );
        state.saleList = horsesToClassArray(updatedData);
      })
      .addCase(createHorse.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(updateHorse.fulfilled, (state, action) => {
        const updatedHorse = action.payload;
        const index = state.list.findIndex(
          (item) => item.id === updatedHorse.id
        );
        if (index !== -1) state.list[index] = updatedHorse;
      })
      .addCase(deleteHorse.fulfilled, (state, action) => {
        state.list = state.list.filter((item) => item.id !== action.payload);
      })
      .addCase(fetchBarns.fulfilled, (state, action) => {
        state.barns = barnsToClassArray(action.payload);
      })
      .addCase(addBarnUser.fulfilled, (state, action) => {
        const barn = state.barns.find((b) => b.id === action.payload.barnId);
        if (barn) barn.barnUsers.push(action.payload);
      })
      .addCase(removeBarnUser.fulfilled, (state, action) => {
        const barn = state.barns.find((b) => b.id === action.payload.barnId);
        if (barn) {
          barn.users = barn.barnUsers.filter(
            (user) => user.id !== action.payload.userId
          );
        }
      })
      .addCase(addPhotosToHorse.fulfilled, (state, action) => {
        const updatedHorse = action.payload;
        const index = state.list.findIndex(
          (item) => item.id === updatedHorse.id
        );
        if (index !== -1) state.list[index] = updatedHorse;
      })
      .addCase(deleteHorsePhoto.fulfilled, (state, action) => {
        const updatedHorse = action.payload;
        const index = state.list.findIndex((h) => h.id === updatedHorse.id);
        if (index !== -1) {
          state.list[index] = updatedHorse;
        }
      })
      .addCase(setMainPhotoForHorse.fulfilled, (state, action) => {
        const updatedHorse = action.payload;
        const index = state.list.findIndex((h) => h.id === updatedHorse.id);
        if (index !== -1) {
          state.list[index] = updatedHorse;
        }
      })
      .addCase(addVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addVideo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const fetchedHorse = action.payload;
        const updatedData = state.list.map((item) =>
          item.id === fetchedHorse.id ? { ...item, ...fetchedHorse } : item
        );
        state.list = horsesToClassArray(updatedData);
      })
      .addCase(addVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Failed to upload video';
      })
      .addCase(addVideoToHorse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addVideoToHorse.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const updatedHorse = action.payload;
        const index = state.list.findIndex(
          (item) => item.id === updatedHorse.id
        );
        if (index !== -1) {
          state.list[index] = updatedHorse;
        } else {
          state.list.push(updatedHorse);
        }
      })
      .addCase(addVideoToHorse.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteHorseS3VideoLink.fulfilled, (state, action) => {
        const updatedHorse = action.payload; // ✅ API returns the updated horse object

        if (!updatedHorse || !updatedHorse.id) {
          console.error('deleteHorseVideoLink API returned null or invalid horse:', updatedHorse);
          return; // ✅ Prevents updating state with null values
        }
        state.list = state.list.map((horse) =>
          horse.id === updatedHorse.id ? updatedHorse : horse
        );
        state.list = horsesToClassArray(state.list);
      })
      .addCase(deleteHorseVideoLink.fulfilled, (state, action) => {
        const updatedHorse = action.payload; // ✅ API returns the updated horse object

        if (!updatedHorse || !updatedHorse.id) {
          console.error('deleteHorseVideoLink API returned null or invalid horse:', updatedHorse);
          return; // ✅ Prevents updating state with null values
        }
        state.list = state.list.map((horse) =>
          horse.id === updatedHorse.id ? updatedHorse : horse
        );
        state.list = horsesToClassArray(state.list);
      });
  },
});

export default horsesSlice.reducer;
