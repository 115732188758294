import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DailyLineChart from '../../../../admin/reporting/core/DailyLineChart';
import SalesStats from '../../../../admin/reporting/core/SalesStats';

export default function HorseDetailsPanelMUI({ horse, horseViews }) {
  const navigate = useNavigate();

  const getWeeklyViews = () => {
    return horseViews
      ? horseViews.dayMinusSixRolling +
          horseViews.dayMinusFiveRolling +
          horseViews.dayMinusFourRolling +
          horseViews.dayMinusThreeRolling +
          horseViews.dayMinusTwoRolling +
          horseViews.dayMinusOneRolling +
          horseViews.dayRolling
      : 0;
  };

  const formatAsDollars = (value) =>
    value
      ? new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(value)
      : '';

  return (
    <Card
      elevation={3}
      sx={{ p: 2, border: '1px solid #ccc', borderRadius: 2 }}
    >
      <CardHeader
        title={
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold', fontSize: '2rem', color: 'primary.main' }}
          >
            Horse Details
          </Typography>
        }
        action={
          <Button
            variant="contained"
            onClick={() => navigate(`/horses/edit/${horse?.id}`)}
          >
            Edit
          </Button>
        }
      />
      <CardContent sx={{ borderRadius: 2, p: 3 }}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {/* Horse Image - Above Description */}
          {horse?.getMainPhotoURLHalf() && (
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'left', borderBottom: '1px solid #ccc' }}
            >
              <Box
                component="img"
                src={horse.getMainPhotoURLHalf()}
                alt="Horse"
                sx={{
                  maxWidth: '100%',
                  width: 200,
                  borderRadius: 2,
                  boxShadow: 2,
                  mb: 2, // Space between image and description
                }}
              />
            </Grid>
          )}

          {/* Description - Full Width */}
          <Grid item xs={12} sx={{ pb: 2, borderBottom: '1px solid #ccc' }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 'bold', textAlign: 'left' }}
            >
              Description:
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left' }}>
              {horse?.description || 'N/A'}
            </Typography>
          </Grid>

          {/* Two-Column Layout for Other Fields */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Price:
            </Typography>
            <Typography variant="body1">
              {formatAsDollars(horse?.price)}
            </Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              Breed:
            </Typography>
            <Typography variant="body1">{horse?.breed}</Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              Gender:
            </Typography>
            <Typography variant="body1">{horse?.gender}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Color:
            </Typography>
            <Typography variant="body1">{horse?.color}</Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              Height:
            </Typography>
            <Typography variant="body1">{horse?.height}</Typography>

            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
              Birth Year:
            </Typography>
            <Typography variant="body1">{horse?.birthYear}</Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        {horseViews && (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <DailyLineChart
                title="Page Views"
                dailyData={[horseViews]}
                dataLabels={['Views']}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SalesStats
                title="Weekly Views"
                amount={getWeeklyViews()}
                chip="Weekly"
              />
              <SalesStats
                title="24hr Views"
                amount={horseViews.dayRolling}
                chip="24HRs"
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
