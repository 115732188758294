import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async Thunks
export const fetchTests = createAsyncThunk('tests/fetch', async () => {
  const response = await axios.get('/api/tests');
  return response.data;
});

export const addTest = createAsyncThunk('tests/add', async (test) => {
  const response = await axios.post('/api/tests', test);
  return response.data;
});

export const updateTest = createAsyncThunk('tests/update', async (test) => {
  const { id, ...data } = test;
  await axios.put(`/api/tests/${id}`, data);
  return { id, ...data };
});

export const deleteTest = createAsyncThunk('tests/delete', async (id) => {
  await axios.delete(`/api/tests/${id}`);
  return id;
});

// Slice
const testsSlice = createSlice({
  name: 'tests',
  initialState: { data: [], status: 'idle' },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTests.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = 'succeeded';
      })
      .addCase(addTest.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(updateTest.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (test) => test.id === action.payload.id
        );
        if (index !== -1) state.data[index] = action.payload;
      })
      .addCase(deleteTest.fulfilled, (state, action) => {
        state.data = state.data.filter((test) => test.id !== action.payload);
      });
  },
});

export default testsSlice.reducer;
