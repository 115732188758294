/* (( *** AUTO GEN *** )) */
/* productCategoryActions.js */
import axios from 'axios';
import { SET_PRODUCT_CATEGORIES, PRODUCT_CATEGORY_FETCHED } from '../types';
import {
  productCategoryToClass,
  productCategoriesToClassArray,
} from '../../classes/ProductCategory.class';

export function productCategoryFetched(productCategory) {
  return {
    type: PRODUCT_CATEGORY_FETCHED,
    productCategory: productCategoryToClass(productCategory),
  };
}

export function setProductCategories(productCategories) {
  return {
    type: SET_PRODUCT_CATEGORIES,
    productCategories: productCategoriesToClassArray(productCategories),
  };
}

export const fetchProductCategory = (id) => async (dispatch) => {
  const res = await axios.get('/api/productcategories/read/' + id);
  dispatch(productCategoryFetched(res.data));
};

export const fetchProductCategories = () => async (dispatch) => {
  const res = await axios.get('/api/productcategories/read');
  if (res.data) {
    dispatch(setProductCategories(res.data));
  }
};

export const fetchProductCategoriesById = (id) => async (dispatch) => {
  const res = await axios.get('/api/productcategories/read/' + id);
  dispatch(setProductCategories(res.data));
};

export const fetchProductCategoriesByParentCategoryId =
  (parentCategoryId) => async (dispatch) => {
    const res = await axios.get(
      '/api/productcategories/read/parentCategoryId/' + parentCategoryId
    );
    dispatch(setProductCategories(res.data));
  };

export const createProductCategory = (values) => async (dispatch) => {
  const res = await axios.post('/api/productcategories/create', values);
  dispatch(productCategoryFetched(res.data));
};

export const updateProductCategory = (values) => async (dispatch) => {
  const res = await axios.post('/api/productcategories/update', values);
  dispatch(productCategoryFetched(res.data));
};

export const deleteProductCategory = (values) => async (dispatch) => {
  const res = await axios.post('/api/productcategories/update', values);
  dispatch(setProductCategories(res.data));
};
