import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  okCancel,
  renderFormTitle,
  maxLength,
} from '../../../core/FinalCoreForms';
import {
  createSjCourse,
  updateSjCourse,
} from '../../../../redux/actions/Events/auxiliary/sjCourses.actions';
import { useDispatch } from 'react-redux';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Select from '@mui/material/Select';
import { levelChip } from '../../../core/levelsTools';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SJCoursesForm = ({ sjCourse, onOkClick, eventData }) => {
  const dispatch = useDispatch();
  // const [selectedCategory, setSelectedCategory] = React.useState(0);
  const [selectedLevel, setSelectedLevel] = React.useState(
    sjCourse ? sjCourse.level : 0
  );

  function submitSjCourse(sjCourse) {
    if (sjCourse && sjCourse.id) {
      return dispatch(updateSjCourse(sjCourse)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createSjCourse(sjCourse)).then(() => {
        //   navigateBack();
      });
    }
  }

  const onSubmit = async (values) => {
    await sleep(300);
    if (sjCourse && submitSjCourse) {
      Object.assign(sjCourse, values);
      sjCourse.level = selectedLevel;
      submitSjCourse(sjCourse);
    } else {
      let newData = {};
      Object.assign(newData, values);
      newData.eventId = eventData.id;
      newData.level = selectedLevel;
      submitSjCourse(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  function getLevelSelector() {
    return (
      <Box width="100%">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedLevel}
            label="Level"
            onChange={handleLevelChange}
          >
            <MenuItem value={0}>All</MenuItem>
            <MenuItem value={2}>{levelChip(2)}</MenuItem>
            <MenuItem value={3}>{levelChip(3)}</MenuItem>
            <MenuItem value={4}>{levelChip(4)}</MenuItem>
            <MenuItem value={5}>{levelChip(5)}</MenuItem>
            <MenuItem value={6}>{levelChip(6)}</MenuItem>
            <MenuItem value={7}>{levelChip(7)}</MenuItem>
            <MenuItem value={8}>{levelChip(8)}</MenuItem>
            <MenuItem value={9}>{levelChip(9)}</MenuItem>
            {/* <MenuItem value={9}>{levelChip(9)}</MenuItem> //Multiple */}
          </Select>
        </FormControl>
      </Box>
    );
  }

  const initialValues = {
    name: sjCourse ? sjCourse.name : '',
    level: sjCourse ? sjCourse.level : 0,
    maximumTime: sjCourse ? sjCourse.maximumTime : '',
    optimumTime: sjCourse ? sjCourse.optimumTime : '',
    lengthMeters: sjCourse ? sjCourse.lengthMeters : 0,
    optimumSpeed: sjCourse ? sjCourse.optimumSpeed : 0,
    dataStatus: sjCourse ? sjCourse.dataStatus : 0,
  };

  return (
    <div>
      {renderFormTitle(sjCourse, 'SjCourses')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Name', maxLength(150))}
            {getLevelSelector()}
            {finalTextField('maximumTime', 'MaximumTime')}
            {finalTextField('lengthMeters', 'LengthMeters')}
            {finalTextField('optimumSpeed', 'OptimumSpeed')}
            {finalTextField('optimumTime', 'OptimumTime')}
            {okCancel(onOkClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default SJCoursesForm;
