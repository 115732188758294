import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle } from 'react-feather';
import HelmetTracker from '../../core/HelmetTracker';

import { fetchProductItems } from '../../../redux/actions/store/productItems.actions';
import { fetchProducts } from '../../../redux/actions/store/products.actions';
import { useDispatch, useSelector } from 'react-redux';
import ProductItemForm from '../forms/ProductItemForm';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function ProductItemsPage() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProductItems());
    dispatch(fetchProducts());
  }, [dispatch]);

  const auth = useSelector((state) => state.auth);
  const productItems = useSelector((state) => state.productItems);
  const productItem = useSelector((state) =>
    state.productItems.find((x) => x.id === selected)
  );

  const products = useSelector((state) => state.products);

  function typeToChip(isService) {
    if (isService) {
      return <Chip label="Service" color="success" />;
    } else if (!isService) {
      return <Chip label="Inventoried" color="danger" />;
    } else {
      return '';
    }
  }

  const handleClick = (event, id) => {
    if (auth && auth.isAdmin() && !addingTest) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setAddingTest(false);
    setSelected(-1);
  };

  const isSelected = (id) => selected === id;

  function mapBody(productItems) {
    if (productItems && productItems.length > 0) {
      const tableBody = productItems.map((productItem) => {
        const isItemSelected = isSelected(productItem.id);
        return (
          <TableRow
            key={productItem.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, productItem.id)}
            selected={isItemSelected}
          >
            <TableCell component="th" scope="row">
              {productItem.id}
            </TableCell>
            <TableCell>{productItem.product.name}</TableCell>
            <TableCell>{typeToChip(productItem.isService)}</TableCell>
            <TableCell>{productItem.product.category.categoryName}</TableCell>
            <TableCell>{productItem.product.sku()}</TableCell>
            <TableCell>{productItem.product.fullSku()}</TableCell>
            <TableCell>{productItem.getPrice()}</TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function showProductItemForm() {
    if (selected && selected > 0) {
      if (productItem && !addingTest) {
        return (
          <ProductItemForm
            onOkClick={handleFormOkClick}
            productItem={productItem}
            products={products}
          />
        );
      }
    } else if (creating) {
      return (
        <ProductItemForm onOkClick={handleFormOkClick} products={products} />
      );
    }
  }

  const title = 'Product Items';

  return (
    <>
      <HelmetTracker title="Product Items" />
      <Card mb={6}>
        {auth && auth.isAdmin() && (
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                size="large"
                onClick={(event) => handleAddClick(event)}
              >
                <PlusCircle />
              </IconButton>
            }
            title={title}
          />
        )}
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Full SKU</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(productItems)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showProductItemForm()}
      </Card>
    </>
  );
}
