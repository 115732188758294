/* AUTO GEN */
/* ProductItem.js */
import { productToClass } from './Product.class';

export class ProductItem {
    constructor(
      id, 
      productId, 
      sku, 
      quantity, 
      productImage, 
      price, 
      unitCost, 
      isService, 
      dataStatus,
      product
    ) {
      this.id = id;
      this.productId = productId;
      this.sku = sku;
      this.quantity = quantity;
      this.productImage = productImage;
      this.price = price;
      this.unitCost = unitCost;
      this.isService = isService;
      this.dataStatus = dataStatus;
      this.product = product;
    }

    getPrice() {
      return "$" + this.price; 
    }
  }
  
  export function productItemToClass(productItem) {
    if (productItem) {
      var product = productToClass(productItem);
      product.id = productItem.productId;
      product.dataStatus = productItem.productDataStatus;
      return new ProductItem(
        productItem.id, 
        productItem.productId, 
        productItem.sku, 
        productItem.quantity, 
        productItem.productImage, 
        productItem.price, 
        productItem.unitCost, 
        productItem.isService, 
        productItem.dataStatus,
        product
      );
    } else {
      return null;
    }
  }
  
  export function productItemsToClassArray(productItems) {
    if (productItems) {
      const productItemsArray = productItems.map((item) => {
        return productItemToClass(item);
      });
      return productItemsArray;
    }
  }
  
  