import React from 'react';
import {
  prelimMapButton,
  modifiedMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
} from '../../core/MapTools';
import {
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';

export default function XCCourseInfoTableRow(props) {
  function getCourseMapButton(levelIndex) {
    if (levelIndex === 0) return prelimMapButton();
    if (levelIndex === 1) return trainingMapButton();
    if (levelIndex === 2) return noviceMapButton();
    if (levelIndex === 3) return begNoviceMapButton();
    if (levelIndex === 4) return starterMapButton();
    if (levelIndex === 5) return modifiedMapButton();
    return '';
  }

  return (
    <TableRow>
      <TableCell>
        <Stack spacing={2}>{getCourseMapButton(props.xcCourse.level)}</Stack>
      </TableCell>
      <TableCell>{props.xcCourse.lengthMeters}</TableCell>
      <TableCell>{props.xcCourse.optimumSpeed}</TableCell>
      <TableCell>{props.xcCourse.optimumTime.replaceAll('"', '')}</TableCell>
      <TableCell>{props.xcCourse.faultTime.replaceAll('"', '')}</TableCell>
    </TableRow>
  );
}
