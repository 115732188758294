/* (( *** AUTO GEN *** )) */
/* sjCourseActions.js */
import axios from 'axios';
import { SET_SJ_COURSES, SJ_COURSE_FETCHED } from '../../types';
import { fetchEvent } from '../event.actions';

export function sjCourseFetched(sjCourse) {
  return {
    type: SJ_COURSE_FETCHED,
    sjCourse,
  };
}

export function setSjCourses(sjCourses) {
  return {
    type: SET_SJ_COURSES,
    sjCourses,
  };
}

export const fetchSjCourses = () => async (dispatch) => {
  const res = await axios.get('/api/sjCourses/');
  dispatch(sjCourseFetched(res.data));
};
export const fetchSjCourse = (id) => async (dispatch) => {
  const res = await axios.get('/api/sjCourses/' + id);
  dispatch(setSjCourses(res.data));
};

// export const fetchSjCoursesByEventId = (eventId) => async (dispatch) => {
//   const res = await axios.get('/api/sjcourses/read/eventId/' + eventId);
//   dispatch(setSjCourses(res.data));
// };

export const createSjCourse = (values) => async (dispatch) => {
  await axios.post('/api/sjCourses/', values);
  dispatch(fetchEvent(values.eventId));
};

export const updateSjCourse = (values) => async (dispatch) => {
  await axios.put('/api/sjCourses/' + values.id, values);
  dispatch(fetchEvent(values.eventId));
};

export const deleteSjCourse = (values) => async (dispatch) => {
  await axios.delete('/api/sjCourses/' + values.id, values);
  dispatch(fetchEvent(values.eventId));
};
