import React from 'react';
import HorseVideoCardMUI from './HorseVideoCardMUI';
import { Box, Grid } from '@mui/material';

export default function HorseVideosGroupMUI({ horse }) {
  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        {horse?.videos?.map((horseVideo) => (
          <Grid item key={horseVideo.id} xs={12} sm={6} md={4}>
            <HorseVideoCardMUI horseId={horse.id} horseVideo={horseVideo} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
