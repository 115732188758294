import React from 'react';
import HorsePhotoCardMUI from './HorsePhotoCardMUI';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';

const reorder = (list, startIndex, endIndex, horse, dispatch) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  if (horse) {
    horse.onPhotoMoved(dispatch, result);
  }
};

export default function HorsePhotosGroupMUI({
  horse,
  onDelete,
  onMakeMainPhoto,
}) {
  const dispatch = useDispatch();

  const onDragEnd = (result) => {
    if (!result.destination) return;
    reorder(
      horse.properties.photoOrder,
      result.source.index,
      result.destination.index,
      horse,
      dispatch
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="horsePhotos">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, p: 2 }}
          >
            {horse?.photos?.map((photo, index) => (
              <Draggable
                key={photo.id}
                draggableId={photo.id.toString()}
                index={index}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <HorsePhotoCardMUI
                      photo={photo}
                      horse={horse}
                      onDelete={onDelete}
                      onMakeMainPhoto={onMakeMainPhoto}
                    />
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}
