import React from 'react';
import { Button } from '@mui/material';

const sharedTextStyle = {
  fontWeight: 600,
  fontSize: '1.1rem',
  fontFamily: 'Arial, sans-serif',
  letterSpacing: '0.75px',
};

const roundCorners = { borderRadius: 28 };

const colorScheme = {
  prelim: {
    backgroundColor: 'darkgreen',
    color: 'white',
    border: '2px solid white',
    ...sharedTextStyle,
  },
  modified: {
    backgroundColor: 'darkblue',
    color: 'orange',
    border: '2px solid orange',
    ...sharedTextStyle,
  },
  training: {
    backgroundColor: 'black',
    color: 'white',
    border: '2px solid white',
    ...sharedTextStyle,
  },
  novice: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'inset 0 0 0 2px black',
    outline: '2px solid white',
    outlineOffset: '0px',
    ...sharedTextStyle,
  },
  beginnerNovice: {
    backgroundColor: 'yellow',
    color: 'black',
    boxShadow: 'inset 0 0 0 2px black',
    outline: '2px solid yellow',
    outlineOffset: '0px',
    ...sharedTextStyle,
  },
  starter: {
    backgroundColor: '#0074cc', // Darker blue for better contrast
    color: 'white',
    border: '2px solid white',
    ...sharedTextStyle,
  },
  preStarter: {
    backgroundColor: 'purple',
    color: 'white',
    border: '2px solid white',
    ...sharedTextStyle,
  },
};

// 🔁 Helper to reduce repeated code
const MapButton = (style, label, href) => (
  <Button style={style} href={href} sx={roundCorners}>
    {label}
  </Button>
);

export const prelimMapButton = () =>
  MapButton(colorScheme.prelim, 'Preliminary', '/maps/Prelim.jpg');

export const prelimOptimumButton = (time, href) =>
  MapButton(colorScheme.prelim, time, href);

export const modifiedMapButton = () =>
  MapButton(colorScheme.modified, 'Modified', '/maps/Modified.jpg');

export const modifiedOptimumButton = (time, href) =>
  MapButton(colorScheme.modified, time, href);

export const trainingMapButton = () =>
  MapButton(colorScheme.training, 'Training', '/maps/Training.jpg');

export const trainingOptimumButton = (time, href) =>
  MapButton(colorScheme.training, time, href);

export const noviceMapButton = () =>
  MapButton(colorScheme.novice, 'Novice', '/maps/Novice.jpg');

export const noviceOptimumButton = (time, href) =>
  MapButton(colorScheme.novice, time, href);

export const begNoviceMapButton = () =>
  MapButton(colorScheme.beginnerNovice, 'Beg Novice', '/maps/BegNovice.jpg');

export const begNoviceOptimumButton = (time, href) =>
  MapButton(colorScheme.beginnerNovice, time, href);

export const starterMapButton = () =>
  MapButton(colorScheme.starter, 'Starter', '/maps/Starter.jpg');

export const starterOptimumButton = (time, href) =>
  MapButton(colorScheme.starter, time, href);

export const preStarterMapButton = () =>
  MapButton(colorScheme.preStarter, 'Pre Starter', '/maps/Starter.jpg');

export const preStarterOptimumButton = (href) =>
  MapButton(colorScheme.preStarter, 'N/A', href);
