/* eslint-disable no-unused-vars */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
// import thunk from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';
import App from './components/App';
import { ThemeProvider } from './contexts/ThemeContext';
// import reducers from './redux/reducers';
import { HelmetProvider } from 'react-helmet-async';
import { store } from './redux/store';
import '@fontsource/inter';

import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

Sentry.init({
  dsn: 'https://fbc5bb2df3139ceed0c44f068412a46f@o4507011981443072.ingest.us.sentry.io/4507018524164096',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const container = document.getElementById('root');
const root = createRoot(container);

// const store = createStore(
//   reducers,
//   composeWithDevTools(applyMiddleware(thunk))
// );

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </ThemeProvider>
);
