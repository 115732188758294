export const TEST_TYPES = [
  { key: 'None', value: 0 },
  { key: 'Dressage', value: 1 },
  { key: 'Stadium', value: 2 },
  { key: 'XC', value: 3 },
  { key: 'Jumping', value: 4 },
  { key: 'Hunter', value: 5 },
  { key: 'In Hand', value: 6 },
  { key: 'XC Schooling', value: 7 },
  { key: 'Other', value: 8 },
];
