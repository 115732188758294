/* AUTO GEN */
/* Video.js */

import _ from 'lodash';

export class S3Video {
  constructor(
    id,
    userId,
    description,
    title,
    horseS3VideoId,
    amazons3URL,
    type,
    width,
    height,
    aspectRatio,
    dataStatus,
    createdAt
  ) {
    this.id = id;
    this.userId = userId;
    this.description = description;
    this.title = title;
    this.horseS3VideoId = horseS3VideoId;
    this.amazons3URL = amazons3URL;
    this.type = type;
    this.width = width;
    this.height = height;
    this.aspectRatio = aspectRatio;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
  }
}

export function s3VideoToClass(s3Video) {
  if (s3Video) {
    return new S3Video(
      s3Video.id,
      s3Video.userId,
      s3Video.description,
      s3Video.title,
      s3Video.horseS3VideoId,
      s3Video.amazons3URL,
      s3Video.type,
      s3Video.width,
      s3Video.height,
      s3Video.aspectRatio,
      s3Video.dataStatus,
      s3Video.createdAt
    );
  } else {
    return null;
  }
}

export function s3VideosToClassArray(s3Videos) {
  if (s3Videos) {
    const videosArray = s3Videos.map((item) => {
      return s3VideoToClass(item);
    });
    return videosArray;
  }
}

export function s3VideosToOrderedArray(s3Videos, order) {
  if (s3Videos) {
    const videosArray = s3VideosToClassArray(s3Videos);
    if (videosArray) {
      if (order && videosArray.length === order.length) {
        var orderedArray = [];
        order.forEach((s3VideoId) => {
          var video = _.find(videosArray, { id: s3VideoId });
          if (video) {
            orderedArray.push(video);
          }
        });
        return orderedArray;
      } else {
        return videosArray;
      }
    }
  }
}
