/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
/* eslint-enable no-unused-vars */

export default function VideoCard({ video }) {

  const link = video.amazons3URL.includes("/watch?v=")
  ? video.amazons3URL.replace("/watch?v=", "/embed/")
  : video.amazons3URL;

  function renderPlayer() {
    if (video && video.amazons3URL && video.amazons3URL.length > 0) {
      return (
        <div
          style={{
            position: 'relative',
            paddingTop: '56.25%' // 16:9 aspect ratio
          }}
        >
          <ReactPlayer
            url={link}
            // url={video.amazons3URL}
            width="100%"
            height="100%"
            style={{
              position: 'absolute',
              top: 0,
              left: 0
            }}
          />
        </div>
      );
    } else {
      return (
        <Image
          src="/images/videos/blank.png"
          alt="Avatar"
          rounded
          responsive
        />
      );
    }
  }

  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
      <div className="card">
        {renderPlayer()}
      </div>
    </Col>
  );
}

VideoCard.propTypes = {
  video: PropTypes.object.isRequired,
};
