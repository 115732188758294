import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Paper } from '@mui/material';
import CalendarEntryStatusTable from '../core/CalendarEntryStatusTable';
import { PatchQuestion } from 'react-bootstrap-icons';

export default function CalendarEntryStatus() {
  const theme = useTheme(); // ✅ Get theme for colors

  return (
    <Box>
      <Paper
        sx={{
          p: 3,
          bgcolor: theme.palette.primary.main, // ✅ Set background to primary
          color: theme.palette.primary.contrastText, // ✅ Ensure readable text
          boxShadow: 3,
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          <PatchQuestion /> Entry Status
        </Typography>
        <Typography variant="h6">You are welcome here at Full Gallop</Typography>
      </Paper>
      <CalendarEntryStatusTable />
    </Box>
  );
}
