import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  DialogActions,
  Button,
} from '@mui/material';
import { DIVISION_TYPES } from '../../../../../constants/divisionTypes';
import { EVENT_LEVELS } from '../../../../../constants/eventLevels';

const AddDivisionPanel = ({
  open,
  onClose,
  onSubmit,
  newDivision,
  setNewDivision,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Division</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          value={newDivision.name}
          onChange={(e) =>
            setNewDivision({ ...newDivision, name: e.target.value })
          }
          style={{ marginBottom: '16px' }}
        />

        <FormControl fullWidth style={{ marginBottom: '16px' }}>
          <InputLabel>Division Type</InputLabel>
          <Select
            value={newDivision.divisionType}
            onChange={(e) =>
              setNewDivision({ ...newDivision, divisionType: e.target.value })
            }
          >
            {DIVISION_TYPES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Event Level</InputLabel>
          <Select
            value={newDivision.eventLevelId}
            onChange={(e) =>
              setNewDivision({
                ...newDivision,
                eventLevelId: parseInt(e.target.value, 10),
              })
            }
          >
            {EVENT_LEVELS.map((level) => (
              <MenuItem key={level.id} value={level.id}>
                {level.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDivisionPanel;
