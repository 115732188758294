import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { PlusCircle, ListCheck } from 'react-bootstrap-icons';
import { SiReacthookform } from 'react-icons/si';
import HorseTable from './core/HorseTable';
import HorsesTable from '../../users/core/HorsesTable';
import { LinkContainer } from 'react-router-bootstrap';
import { Box, Typography, Stack, Button as MuiButton } from '@mui/material';
import { useSelector } from 'react-redux';
// import { updateProperties } from '../../../redux/actions/horseActions';
import _ from 'lodash';
import HelmetTracker from '../../core/HelmetTracker';

export default function HorsePage() {
  // const dispatch = useDispatch();
  const [showAll, setShowAll] = React.useState(false);
  const [newForm, setNewForm] = React.useState(false);
  const [filterText, setFilterText] = React.useState('');
  const horses = useSelector((state) => state.horses.list);

  function filterArchivedHorses(filterArchived) {
    if (filterArchived) {
      let filteredHorses = _.filter(horses, function (o) {
        return !o.dataStatus;
      });
      return filteredHorses;
    } else return horses;
  }

  function filterHorses(filterArchived) {
    var horseList = filterArchivedHorses(filterArchived);
    if (filterText.length > 0) {
      let filter = filterText;
      let filteredTextHorses = _.filter(horseList, function (o) {
        return o.name.toLowerCase().includes(filter.toLowerCase());
      });
      return filteredTextHorses;
    } else return horseList;
  }

  const handleFilterTextChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleDisplayChange = (e) => {
    setShowAll(!showAll);
  };

  function getDisplayText() {
    if (showAll) {
      return <Typography>Hide Archived Horses</Typography>;
    }
    return <Typography>Show All Horses</Typography>;
  }

  function getUnarchivedHorseCountText() {
    let horses = filterHorses(true);
    let returnString = '';
    if (showAll)
      returnString = 'Unarchived Horses: (' + Object.keys(horses).length + ')';
    else
      returnString =
        '--> Unarchived Horses: (' + Object.keys(horses).length + ')';
    return <Typography>{returnString}</Typography>;
  }

  function getTotalHorseCountText() {
    let returnString = '';
    if (showAll)
      returnString = '--> Total Horses: (' + Object.keys(horses).length + ')';
    else returnString = 'Total Horses: (' + Object.keys(horses).length + ')';
    return <Typography>{returnString}</Typography>;
  }

  function renderDisplayAllButton() {
    return (
      <MuiButton
        variant="contained"
        color="info"
        startIcon={<ListCheck />}
        onClick={handleDisplayChange}
      >
        {getDisplayText()}
      </MuiButton>
    );
  }

  // function updateProps() {
  //   console.log('Horse Count');
  //   console.log(horses.length);
  //   if (horses) {
  //     horses.forEach((horse) => {
  //       // console.log(horse.id);
  //       // console.log(horse.properties);
  //       var updateObject = { id: horse.id, properties: horse.properties };
  //       dispatch(updateProperties(updateObject));
  //     });
  //   }
  // }

  return (
    <Row className="show-grid">
      <HelmetTracker title="Horses" />
      <Col xs={12} sm={12} md={12}>
        <Card variant="danger">
          <Card.Header>
            <Card.Title>
              <Stack direction="row" spacing={5}>
                <Box>Horses</Box>
                <MuiButton
                  variant="contained"
                  onClick={() => {
                    setNewForm(!newForm);
                  }}
                  startIcon={<SiReacthookform />}
                  color={newForm ? 'warning' : 'error'}
                  style={{ fontWeight: 700 }} // BOLD
                >
                  Toggle Form
                </MuiButton>
                {/* <MuiButton
                  variant="contained"
                  onClick={() => {
                    updateProps();
                  }}
                  startIcon={<SiReacthookform />}
                  color={newForm ? 'warning' : 'error'}
                  style={{ fontWeight: 700 }} // BOLD
                >
                  Update Properties
                </MuiButton> */}
              </Stack>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Stack direction="column" spacing={2}>
              <Stack direction="row" spacing={2}>
                <LinkContainer to={'/horses/new'}>
                  <MuiButton variant="contained" startIcon={<PlusCircle />}>
                    Add Horse
                  </MuiButton>
                </LinkContainer>
                <LinkContainer to={'/horses/feed'}>
                  <MuiButton variant="contained" startIcon={<ListCheck />}>
                    Feed List
                  </MuiButton>
                </LinkContainer>
                {renderDisplayAllButton()}
              </Stack>
              {getUnarchivedHorseCountText()}
              {getTotalHorseCountText()}
            </Stack>
          </Card.Body>
        </Card>
        <Card variant="danger">
          <Card.Header>
            <Card.Title>My Horses</Card.Title>
          </Card.Header>
          <Card.Body>
            <label>
              Search Name :
              <input
                type="text"
                value={filterText}
                onChange={handleFilterTextChange}
              />{' '}
            </label>
            {newForm ? (
              <HorsesTable horses={filterHorses(!showAll)} />
            ) : (
              <HorseTable horses={filterHorses(!showAll)} />
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
