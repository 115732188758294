import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalObjSelector,
  finalTextArea,
  finalBoolField,
  required,
  mustBeNumber,
  maxLength,
  createOptionsList,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createHorse,
  updateHorse,
} from '../../../redux/actions/horse.actions';

import { Stack } from '@mui/material';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function UserHorseForm({ horse, barns, onOkClick, onCancelClick, barnId }) {
  const dispatch = useDispatch();

  const initialValues = {
    id: horse && horse.id ? horse.id : null,
    barnId: horse && horse.barnId ? horse.barnId : (barnId ? barnId : 0),
    name: horse && horse.name ? horse.name : '',
    price: horse && horse.price ? horse.price : 0,
    owner: horse && horse.owner ? horse.owner : '',
    breed: horse && horse.breed ? horse.breed : '',
    feed: horse && horse.feed ? horse.feed : '',
    gender: horse && horse.gender ? horse.gender : 0,
    color: horse && horse.color ? horse.color : '',
    height: horse && horse.height ? horse.height : '',
    birthYear: horse && horse.birthYear ? horse.birthYear : '',
    description: horse && horse.description ? horse.description : '',
    levelCompeting: horse && horse.levelCompeting ? horse.levelCompeting : '',
    useaNumber: horse && horse.useaNumber ? horse.useaNumber : '',
    usefNumber: horse && horse.usefNumber ? horse.usefNumber : '',
    ushjaNumber: horse && horse.ushjaNumber ? horse.ushjaNumber : '',
    specialInformation: horse && horse.specialInformation ? horse.specialInformation : '',
    isSaleHorse: horse && horse.isSaleHorse ? horse.isSaleHorse > 0 : false,
    sold: horse && horse.sold ? horse.sold > 0 : false,
    authorized: horse && horse.authorized ? horse.authorized > 0 : false,
    international: horse && horse.international ? horse.international > 0 : false,
    raced: horse && horse.raced ? horse.raced : 0,
    dataStatus: horse && horse.dataStatus ? horse.dataStatus : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (horse) {
      Object.assign(horse, values);
      dispatch(updateHorse(horse));
    } else {
      let newData = {};
      Object.assign(newData, values);
      dispatch(createHorse(newData));
    }
    onOkClick();
  };

  const genderOptionsList = [
    { key: 'Not Selected', value: '0' },
    { key: 'Gelding', value: '1' },
    { key: 'Mare', value: '2' },
    { key: 'Stallion', value: '3' },
  ];


  const racedOptionsList = [
    { key: 'N/A', value: '0' },
    { key: 'Raced', value: '1' },
    { key: 'Unraced', value: '2' },
  ];


  const dataStatusOptionsList = [
    { key: 'Ok', value: '0' },
    { key: 'New', value: '1' },
    { key: 'Unknown', value: '2' },
    { key: 'Deleted', value: '3' },
    { key: 'Unknown', value: '4' },
    { key: 'Archived', value: '5' },
  ];

  return (
    <div>
      {renderFormTitle(horse, 'Horse')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={5} alignItems="center">
            {finalObjSelector('barnId', 'Barn', createOptionsList(barns))}
            {finalTextField('name', 'Name', required)}
            {finalTextField('price', 'Price', mustBeNumber)}
            {finalTextField('owner', 'Owner', maxLength(150))}
            {finalTextField('breed', 'Breed')}
            {finalObjSelector('gender', 'Gender', genderOptionsList)}
            {finalTextField('color', 'Color')}
            {finalTextField('height', 'Height')}
            {finalTextField('birthYear', 'Birth Year')}
            {finalTextArea('description', 'Description')}
            {finalTextField('levelCompeting', 'Level Competing')}
            {finalTextField('useaNumber', 'Usea Number')}
            {finalTextField('usefNumber', 'Usef Number')}
            {finalTextField('ushjaNumber', 'Ushja Number')}
            {finalTextArea('specialInformation', 'Special Information')}
            {finalBoolField('isSaleHorse', 'Is Sale Horse')}
            {finalBoolField('sold', 'Sold')}
            {finalBoolField('authorized', 'Authorized')}
            {finalBoolField('international', 'International')}
            {finalObjSelector('raced', 'Raced', racedOptionsList)}
            {finalObjSelector('dataStatus', 'Data Status', dataStatusOptionsList)}
              {okCancel(onCancelClick)}
            </Stack>
          </form>
        )}
      />
    </div>
  );
}
